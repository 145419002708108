import { Component, Input, OnInit } from '@angular/core';

type leaderboard_Person = {
  landSize:string,
  personImage_Path:string,
  leaderboard_place:string,
  landCoveringSize:string,
}

type leaderboard_Data = {
  leaderBoard_Title:string,
  leftPerson:leaderboard_Person,
  centerPerson:leaderboard_Person,
  rightPerson:leaderboard_Person
}

@Component({
  selector: 'app-leaderboard',
  templateUrl: './leaderboard.component.html',
  styleUrls: ['./leaderboard.component.scss']
})
export class LeaderboardComponent implements OnInit {

  @Input() leaderBoard_Data:leaderboard_Data;

  constructor() { }

  ngOnInit(): void {
  }

}
