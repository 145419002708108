import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PublicService } from './public.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(private router: Router,private _publicApi : PublicService, private toast : ToastrService
  ) {}


  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      let currentUser = localStorage.getItem('user');
      if (currentUser && this._publicApi.isTokenExpired()) {
          // authorised & valid
          let user = JSON.parse(currentUser);
          if (next.data.roles && next.data.roles.indexOf(user.role) === -1) {
              this.toast.warning("You are not allowed to access this resource.", 'Warning!', { closeButton : true, timeOut: 5000 });
              return false;
          }
          return true;
      }
      // not logged in so redirect to login page with the return url
      this.router.navigate(['/projects']);
      return false;
  }

}
