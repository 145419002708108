import { Component, OnInit,Input,Output, EventEmitter } from '@angular/core';
import { PublicService } from 'src/app/api/public.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


type component_data_type = {
	projectID:string,
	projectObject:{}

}

@Component({
  selector: 'app-view-details',
  templateUrl: './view-details.component.html',
  styleUrls: ['./view-details.component.scss']
})
export class ViewDetailsComponent implements OnInit {
	@Input() component_data:component_data_type;
	@Output() leaderboard_btn_event = new EventEmitter();
  @Output() back_btn_event = new EventEmitter();
  @Output() loadGeoJson = new EventEmitter();

	view_goals = false;
	investment_goals_component_data = {};
	myconservation_component_data = {};
	landcover_component_data = {};
	top_habitatowner_component_data:any;
  leaders:any;
  showReadMore = false;


  project_data = {
    projectId : "1",
    activities_ids: ["c070dcec-f5e5-11eb-9bcb-0021ccca0e55","26ff8b2a-f5e6-11eb-8582-0021ccca0e55"],
    city: "110878",
    country: "229",
    location : "Location",
    description: "<p>The project has already planted around 1.3 million indigenous trees over the past four years, of which 75% have grown successfully, across the 25km2 project site. This planting has taken place in the places where it is most urgently needed for the Chimpanzee population.&nbsp;</p>",
    establish_date: "2022-01-29",
    gallery: [],
    sites : [],
    flag : "",
    geojson:"",
    header_image: "projects/ec426686-80b6-11ec-8b30-0a492dd11e42/3m9QfToZ6tlcXmaj5QvThSQto6HrCRJmLjvsYaKw.jpg",
    name: "Uganda Reforestation",
    biome : "Forest",
    region: "372",
    status: "Active",
    summary: "The project has already planted around 1.3 million indigenous trees over the past four years, of which 75% have grown successfully, across the 25km2 project site. This planting has taken place in the places where it is most urgently needed for the Chimpanzee population.",
    allSpecies: "",
    sdgsList: "",
    allTransactions: "",
    total_tile : 0,
    total_area : 0,
    total_revenue: 0,
    total_revenue_current_month: 0,
    total_tiles_bought: 0,
    total_tiles_bought_current_month: 0,
    total_sites: 0,
  }
  /* -------------------------------------------------------------------------- */
  /* ------------------------ Project thumbnil Control ------------------------ */

 project_thumb = this.project_data.gallery[0];
 galleryThumbPrevIndex = 0;
 galleryThumbNextIndex = 0;
 galleryThumb = this.project_data.gallery[0];
 current_index = 0;
  total: number;
  user: any;
  blog:any;
 /* -------------------------------------------------------------------------- */

  constructor(public _publicService : PublicService, private loader : NgxSpinnerService, private modalService: NgbModal) {

  }

  ngOnInit(): void {
    this.top_habitatowner_component_data = {"projectId" : this.component_data.projectID};
    this.loader.show();
    this._publicService.getProjectDetail(this.component_data.projectID).subscribe(response => {
      this.loader.hide();
      this.user = JSON.parse(localStorage.getItem('user'));
      let project = response.project;
      this.loadGeoJson.emit(project);

      const jsonData = project.geojson;
      const data = JSON.parse(jsonData);
      const polygon = data.features[0].geometry.coordinates;

      this.project_data = {
        "activities_ids": this._publicService.getActivityName(project.activities),// "c070dcec-f5e5-11eb-9bcb-0021ccca0e55,26ff8b2a-f5e6-11eb-8582-0021ccca0e55",
        "city": "",
        "location" : this._publicService.getProjectLocation(project),
        "country": project.country_name ? project.country_name.name : "",
				"flag":  this._publicService.getCountryFlagImage(project.country_name),
        "description": project.description,
        "establish_date": project.establish_date,
        "gallery": this._publicService.getProjectGallery(project.gallery),
        "header_image": this._publicService.getProjectImage(project),
        "name": project.name,
        "region": project.province_name ? project.province_name.name : "",
        "status": "Active",
        "summary": response.project.summary,
        "allSpecies": project.allSpecies,
        "sdgsList": project.sdgsList,
        "sites" : project.sites,
        "biome" : project.biome,
				"geojson" : project.geojson,
        "allTransactions": project.allTransactions,
        "total_tile" : Number(project.total_tile),
        "total_area" : Number(project.total_area),
        "total_revenue": Number(project.totalRevenue),
        "total_revenue_current_month": Number(project.totalRevenueCurrentMonth),
        "total_tiles_bought": (project.totalTilesBought),
        "total_tiles_bought_current_month": (project.totalTilesBoughtCurrentMonth),
        "total_sites": Number(project.total_sites),
        "projectId" : this.component_data.projectID
      }
      this.project_thumb = this._publicService.getProjectImage(project);
      this.galleryThumb = this.project_thumb;
      this.getLatestUpdates();

      this.landcover_component_data = {
        "projectID" : this.component_data.projectID,
        "goalLandcover" : project.landcover_goal,
        "totalArea" : project.total_area,
        "polygon": polygon
      }
    })
  }

  change_image(index,call_method){

    /* FIXME----------------- automatice thumbnil change not enable yet ---------------- */
    if(call_method == 'interval' ){
      if(index == this.project_data.gallery.length-1){
        this.current_index = 0;
      }
      else(
        this.current_index = index +1
      )
    }
    /* ---------------------------------- on-click thumbnil change enabled ---------------------------------- */
    if(call_method == 'click'){
        this.current_index = index;
      }
      this.project_thumb = this.project_data.gallery[this.current_index].image;

  }
/* -------------------------------------------------------------------------- */
	changeVisibility(){
		this.back_btn_event.emit();
	}

	view_goal(){
		this.view_goals = !this.view_goals;
	}

	tophabitate_component_event_listener(event){
		this.leaderboard_btn_event.emit();

  }

  getProjectImage(project){
    if(project.header_image){
      return this._publicService.apiImageUrl+project.header_image;
    }
    return this._publicService.apiImageUrl+"projects/07980a42-e62b-11ec-baa1-0a56ba73e7b1/NbTC1ycHNBypbr8xg7Q0OraV2nz5SqUgr2xd4j7b.jpg";
  }

  getSpeciesKey(){
    return Object.keys(this.project_data.allSpecies);
  }

  getTotalAreaInHa(area){
    area = area/10000;
    return Math.round(area * 100) / 100;
  }

  convertArea(areaInHactare){
    if(areaInHactare){
      areaInHactare = areaInHactare/100;
      return (Math.round(areaInHactare * 100) / 100);
    }
    return 0;
  }
  
  getTotalBought(projects){
    if(projects.total_tiles_bought){
      return projects.total_tiles_bought;
    }
    return 0;
  }

  getBoughtPercentage(projects){
		return this._publicService.getBoughtPercentage(projects);
  }

  getTotalTileBoughtBy(projects){
    if(projects){
      this.total= 0;
      if(projects.allTransactions != null ){
        for (let transactions of projects.allTransactions) {
            this.total=transactions.quantity + this.total;
        }
      }
      return this.total;
    }
    return 0;
  }

  getInvestmentPercentage(projects){
    if(projects){
      return Math.round((this.getTotalTileBoughtBy(projects)/projects.total_tiles_bought)*100);
    }
    return 0;
  }

	uploadimagemodal(imageupload) {
			this.modalService.open(imageupload, {windowClass:'addarea', ariaLabelledBy: 'image-upload-form', animation : true, centered: true,  size: 'lg' as any}).result.then((result) => {
		});
  }
  
	imageuploadclose(){
		this.modalService.dismissAll();
	}

	changeGalleryThumb(index){
    if(this.project_data.gallery[index]){
      this.galleryThumb = this.project_data.gallery[index].image;
      this.galleryThumbPrevIndex = index-1;
      this.galleryThumbNextIndex = index+1;
    }else{
      this.galleryThumb = this.project_data.header_image;
    }
  }

  getLatestUpdates(){
    //this.loader.show();
    this._publicService.getPosts({"project_id":this.project_data.projectId, "limit":1}).subscribe(response => {
      //this.loader.hide();
      if(response.success){
        this.blog = response.posts[0];
      }
    }, error => {
      console.log(error);
      //this.loader.hide();
    })
  }

  goToSites(){
    this.loadGeoJson.emit(this.project_data);
  }

  smart_substr(str, len) {
      if(str){
        var temp = str.substr(0, len);
        if(temp.lastIndexOf('<') > temp.lastIndexOf('>')) {
            temp = str.substr(0, 1 + str.indexOf('>', temp.lastIndexOf('<')));
        }
        return temp;
      }
  }

  getDescriptionSummary(): string {
    if (!this.showReadMore) {
      const words = this.project_data.description.split(' ');
      return words.slice(0, 50).join(' ') + (words.length > 50 ? '...' : '');
    }
    return this.project_data.description;
  }

  toggleDescription(): void {
    this.showReadMore = !this.showReadMore;
  }

  shouldShowReadMoreButton(): boolean {
    const words = this.project_data.description.split(' ');
    return words.length > 50;
  }
}
