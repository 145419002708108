import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-technologies',
  templateUrl: './technologies.component.html',
  styleUrls: ['./technologies.component.scss']
})
export class TechnologiesComponent implements OnInit {

  short_blogs_data = [{
    blog_icon:'assets/newimages/location.png',
    blog_title:'Immutable Ledger Technology',
    blog_details:'We work with blockchain certification standards, which are used for incubation, validation and maintenance of nature-based environmental projects. We developed a blockchain-based ecological certification approach to ensure that the initiatives that implement biodiversity offsets and carbon offsets are environmentally sound and are truly beneficial for the ecosystem restoration.Our key strategy is to offer world leading verification, certification and trading of biodiversity offsets and carbon offsets - alongside an ability to offer customers a proven and highly transparent way of developing nature-based solutions effectively at scale.',
  },{
    blog_icon:'assets/newimages/blockchain.png',
    blog_title:'Blockchain Certification Standards',
    blog_details:'We work with blockchain certification standards, which are used for incubation, validation and maintenance of nature-based environmental projects. We developed a blockchain-based ecological certification approach to ensure that the initiatives that implement biodiversity offsets and carbon offsets are environmentally sound and are truly beneficial for the ecosystem restoration.Our key strategy is to offer world leading verification, certification and trading of biodiversity offsets and carbon offsets - alongside an ability to offer customers a proven and highly transparent way of developing nature-based solutions effectively at scale.',
  }]

  constructor() { }

  ngOnInit(): void {
  }

}
