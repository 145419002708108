import { Component, OnInit, Output, EventEmitter} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { PublicService } from 'src/app/api/public.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-myprojects',
  templateUrl: './myprojects.component.html',
  styleUrls: ['./myprojects.component.scss']
})
export class MyprojectsComponent implements OnInit {

@Output() project_clicked_event = new EventEmitter;
@Output() leaderboard_clicked_event = new EventEmitter;
@Output() project_data_to_parent = new EventEmitter;

projects:any;
biodiversity_component_data = [
	{
		species_catagory_name:'Plant Species',
		species_catagory_image_path:'fa fa-leaf',
		available_species_data_length:10,
		available_species_data:[
			{
				specie_name:'Fern',
				specie_image_path:'assets/newimages/fern.jpg'
			},{
				specie_name:'Fern',
				specie_image_path:'assets/newimages/fern.jpg'
			},{
				specie_name:'Fern',
				specie_image_path:'assets/newimages/fern.jpg'
			},{
				specie_name:'Fern',
				specie_image_path:'assets/newimages/fern.jpg'
			},{
				specie_name:'Fern',
				specie_image_path:'assets/newimages/fern.jpg'
			},{
				specie_name:'Fern',
				specie_image_path:'assets/newimages/fern.jpg'
			},{
				specie_name:'Fern',
				specie_image_path:'assets/newimages/fern.jpg'
			},{
				specie_name:'Fern',
				specie_image_path:'assets/newimages/fern.jpg'
			},{
				specie_name:'Fern',
				specie_image_path:'assets/newimages/fern.jpg'
			},{
				specie_name:'Fern',
				specie_image_path:'assets/newimages/fern.jpg'
			},
		],
	},
	{
		species_catagory_name:'Tree Species',
		species_catagory_image_path:'fa fa-tree',
		available_species_data_length:10,
		available_species_data:[
			{
				specie_name:'Acacia',
				specie_image_path:'assets/newimages/tree.jpeg'
			},{
				specie_name:'Acacia',
				specie_image_path:'assets/newimages/tree.jpeg'
			},{
				specie_name:'Acacia',
				specie_image_path:'assets/newimages/tree.jpeg'
			},{
				specie_name:'Acacia',
				specie_image_path:'assets/newimages/tree.jpeg'
			},{
				specie_name:'Acacia',
				specie_image_path:'assets/newimages/tree.jpeg'
			},{
				specie_name:'Acacia',
				specie_image_path:'assets/newimages/tree.jpeg'
			},{
				specie_name:'Acacia',
				specie_image_path:'assets/newimages/tree.jpeg'
			},{
				specie_name:'Acacia',
				specie_image_path:'assets/newimages/tree.jpeg'
			},{
				specie_name:'Acacia',
				specie_image_path:'assets/newimages/tree.jpeg'
			},{
				specie_name:'Acacia',
				specie_image_path:'assets/newimages/tree.jpeg'
			},
		],
	},
	{
		species_catagory_name:'Mammal Species',
		species_catagory_image_path:'far fa-elephant',
		available_species_data_length:10,
		available_species_data:[
			{
				specie_name:'Bear',
				specie_image_path:'assets/newimages/bear.jpg'
			},{
				specie_name:'Bear',
				specie_image_path:'assets/newimages/bear.jpg'
			},{
				specie_name:'Bear',
				specie_image_path:'assets/newimages/bear.jpg'
			},{
				specie_name:'Bear',
				specie_image_path:'assets/newimages/bear.jpg'
			},{
				specie_name:'Bear',
				specie_image_path:'assets/newimages/bear.jpg'
			},{
				specie_name:'Bear',
				specie_image_path:'assets/newimages/bear.jpg'
			},{
				specie_name:'Bear',
				specie_image_path:'assets/newimages/bear.jpg'
			},{
				specie_name:'Bear',
				specie_image_path:'assets/newimages/bear.jpg'
			},{
				specie_name:'Bear',
				specie_image_path:'assets/newimages/bear.jpg'
			},{
				specie_name:'Bear',
				specie_image_path:'assets/newimages/bear.jpg'
			},
		],
	}
]


header_component_data = {
	username:'Papasiddhi',
	userimage:'../../../../assets/img/brand/placeholder-profileimg.png',
	leaderboard_number:'4th',
	total_conservation:'200 tonnes',
	total_area:'1000',
	total_species:'122',
	total_trees:'100'
}

badge_component_data = [
	{
		badge_name:'Raptaile',
		badge_image_path:'assets/newimages/badgefish.png',
		badge_target_value:'200',
		badge_achived_value:'100',
		badge_achived_percenatge:'50',
		is_badge_achived:false,
	},{
		badge_name:'Raptaile',
		badge_image_path:'assets/newimages/badgefish.png',
		badge_target_value:'1k',
		badge_achived_value:'1k',
		badge_achived_percenatge:'100',
		is_badge_achived:true,
	},{
		badge_name:'Raptaile',
		badge_image_path:'assets/newimages/badgefish.png',
		badge_target_value:'500',
		badge_achived_value:'100',
		badge_achived_percenatge:'20',
		is_badge_achived:false,
	},{
		badge_name:'Raptaile',
		badge_image_path:'assets/newimages/badgefish.png',
		badge_target_value:'300',
		badge_achived_value:'300',
		badge_achived_percenatge:'100',
		is_badge_achived:true,
	},{
		badge_name:'Raptaile',
		badge_image_path:'assets/newimages/badgefish.png',
		badge_target_value:'300',
		badge_achived_value:'300',
		badge_achived_percenatge:'100',
		is_badge_achived:true,
	},{
		badge_name:'Raptaile',
		badge_image_path:'assets/newimages/badgefish.png',
		badge_target_value:'500',
		badge_achived_value:'100',
		badge_achived_percenatge:'20',
		is_badge_achived:false,
	},{
		badge_name:'Raptaile',
		badge_image_path:'assets/newimages/badgefish.png',
		badge_target_value:'1k',
		badge_achived_value:'250',
		badge_achived_percenatge:'25',
		is_badge_achived:false,
	},{
		badge_name:'Raptaile',
		badge_image_path:'assets/newimages/badgefish.png',
		badge_target_value:'300',
		badge_achived_percenatge:'100',
		badge_achived_value:'300',
		is_badge_achived:true,
	},{
		badge_name:'Raptaile',
		badge_image_path:'assets/newimages/badgefish.png',
		badge_target_value:'100',
		badge_achived_value:'55',
		badge_achived_percenatge:'55',
		is_badge_achived:false,
	},{
		badge_name:'Raptaile',
		badge_image_path:'assets/newimages/badgefish.png',
		badge_target_value:'300',
		badge_achived_value:'300',
		badge_achived_percenatge:'100',
		is_badge_achived:true,
	}
]


projects_Data = [
    {
      projectID:1,
      projectImage:'https://media.istockphoto.com/photos/man-sowing-the-land-at-a-farm-picture-id668076810?k=20&m=668076810&s=612x612&w=0&h=5q9Iqqa0nYhn-MJL80jETxeikjMLBR6PBQhEDUGD5WI=',
      projectName:'Restoration in Sirena Leone',
      projectLocation:'Tonkolili, Sirena Leone',
      projectareaSize:{length:30,width:60},
      projectCountry_image:'https://m.media-amazon.com/images/I/31hUa4vD7aL._AC_SX466_.jpg',
      projectProgress:80,
	  total_conservation_area:'800',
	  total_trees:'1.2k',
      projectSpecies:[{
          speciesType:'Tree',
          numberOFSpecies:10,
        },
        {
          speciesType:'Amphibians',
          numberOFSpecies:20,
        },
        {
          speciesType:'Plants',
          numberOFSpecies:42,
        },
        {
          speciesType:'Birds',
          numberOFSpecies:42,
        },
        {
          speciesType:'Fish',
          numberOFSpecies:42,
        }
      ]
    },
    {
      projectID:2,
      projectImage:'https://thumbs.dreamstime.com/b/group-forest-workers-sawing-logs-group-forest-workers-sawing-tree-trunks-wood-harvest-forest-170175413.jpg',
      projectName:'Restoration in Sirena Leone',
      projectLocation:'Tonkolili, Sirena Leone',
      projectareaSize:{length:80,width:60},
      projectCountry_image:'https://m.media-amazon.com/images/I/31hUa4vD7aL._AC_SX466_.jpg',
      projectProgress:50,
	  total_conservation_area:'500',
	  total_trees:'800',
      projectSpecies:[{
        speciesType:'Tree',
        numberOFSpecies:10,
      },
      {
        speciesType:'Amphibians',
        numberOFSpecies:20,
      },
      {
        speciesType:'Plants',
        numberOFSpecies:42,
      },
      {
        speciesType:'Birds',
        numberOFSpecies:42,
      },
      {
        speciesType:'Fish',
        numberOFSpecies:42,
      }
    ]
    },
    {
      projectID:3,
      projectImage:'https://www.dolphinposted.org/file/2020/06/forrest-worker001-1500x750.jpg',
      projectName:'Restoration in Sirena Leone',
      projectLocation:'Tonkolili, Sirena Leone',
      projectareaSize:{length:10,width:20},
      projectCountry_image:'https://m.media-amazon.com/images/I/31hUa4vD7aL._AC_SX466_.jpg',
      projectProgress:20,
	  total_conservation_area:'800',
	  total_trees:'3.2k',
      projectSpecies:[{
        speciesType:'Tree',
        numberOFSpecies:10,
      },
      {
        speciesType:'Amphibians',
        numberOFSpecies:20,
      },
      {
        speciesType:'Plants',
        numberOFSpecies:42,
      },
      {
        speciesType:'Birds',
        numberOFSpecies:42,
      },
      {
        speciesType:'Fish',
        numberOFSpecies:42,
      }]
    }
  ]
  constructor(private _publicApi : PublicService,
    public loader : NgxSpinnerService, public router : Router) { }

  ngOnInit(): void {
	let user = JSON.parse(localStorage.getItem('user'));
	let profile = JSON.parse(localStorage.getItem('profile'));
	let statistics = JSON.parse(localStorage.getItem('statistics'));
	//this.header_component_data.username = user.firstname;
	this.header_component_data.username = (profile && profile.name != null) ? profile.name : user.firstname;
	this.header_component_data.leaderboard_number = this.ordinal_suffix_of(statistics.rank);
	this.header_component_data.total_area = statistics.total_area;
	this.header_component_data.total_conservation = statistics.carbon;
	this.header_component_data.total_species = statistics.biodiversity_spices;
	this.header_component_data.total_trees = statistics.trees;
	this.header_component_data.userimage = this._publicApi.getUserImage(user.image);
  }

  	ordinal_suffix_of(i) {
		var j = i % 10,
			k = i % 100;
		if (j == 1 && k != 11) {
			return i + "st";
		}
		if (j == 2 && k != 12) {
			return i + "nd";
		}
		if (j == 3 && k != 13) {
			return i + "rd";
		}
		return i + "th";
	}
	
	project_card_event_receiver(project){
		this.router.navigate(['/organization/browseproject'], { state : {projectId : project.uid} });
		//this.project_clicked_event.emit(project);
	}
	
	header_component_event_receiver(event){
		this.leaderboard_clicked_event.emit();
	}

	project_biodiversity(biodiversity){
		this.biodiversity_component_data = biodiversity;
		console.log(this.biodiversity_component_data);
	}

	//receive project data from app-organization-project-card and propagate to app-organizationpage
	receiveProjectDataFromCard(data: string) {
		this.project_data_to_parent.emit(data);
	}
}
