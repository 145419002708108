import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PublicService } from 'src/app/api/public.service';
import { ToastrService, ToastContainerDirective } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ValidationService } from 'src/app/api/validation.service';
import { Router } from '@angular/router';
import { SocialAuthService, GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  loginForm: FormGroup;
  user:any; 
  profile : any;
  userRoles:any;
  userType:any;
  socialResponse:any;
  showRoles = false;
  show_button: Boolean = false;
  show_eye: Boolean = false;
  stateData:any;

  @ViewChild(ToastContainerDirective, { static: true }) toastContainer: ToastContainerDirective;
  
  constructor(private _formBuilder: FormBuilder, private _publicApi : PublicService, private toast : ToastrService,
    private loader : NgxSpinnerService, private router : Router, private authService: SocialAuthService) {
      this.toast.overlayContainer = this.toastContainer;
      this.stateData = router.getCurrentNavigation().extras.state;
    }

  ngOnInit() {
    if(this.stateData){
      localStorage.setItem("selectedTiles",JSON.stringify(this.stateData));
    }
    if(localStorage.getItem("selectedTiles")){
      console.log(localStorage.getItem("selectedTiles"));
    }else{
      localStorage.clear();
    }
    this.initializeForm();
    this.getUserTypes();
  }
  ngOnDestroy() {
  }

  getUserTypes(){
    this._publicApi.getUserTypes().subscribe((response:any) => {
      this.userRoles = response.roles;
    });
  }

  initializeForm() {
    this.loginForm = this._formBuilder.group({
      email: ['', [Validators.required, ValidationService.emailValidator]],
      password: ['', Validators.required],
      loginType : ['email'],
      userType : [ this.userType ]
    });
    //this.loginForm = new FormGroup(this.loginForm.controls, { updateOn: 'keypress' });
  }

  async login(){
    this.validate();
    if(this.loginForm.valid){
      this.loader.show();
      let data = {
        "email" : this.loginForm.value.email,
        "password" : this.loginForm.value.password,
        "login_type" : this.loginForm.value.loginType
      };
      this._publicApi.customLogin(data).then((response:any) => {
        this.loader.hide();
        this.afterLogin(response);
      }).catch( err => {
        this.loader.hide();
        this.toast.error('Invalid Email or Password.', 'Error!', { closeButton : true, timeOut: 5000 });  
      });
    }
    else{
      this.toast.error('Invalid Email or Password.', 'Error!', { closeButton : true, timeOut: 5000 });
    }
  }

  validate(){
    for(let prop in this.loginForm.controls){
      this.loginForm.controls[prop].markAsTouched();
    }
  }

  afterLogin(response){
    if(response.token){
      this.loader.show();
      localStorage.setItem('token',response.token);
      this._publicApi.getUserInfo().subscribe(response => {
        this.loader.hide();
        console.log(response.user)
        if(response.user.email_verified_at){

          response.user.firstname = response.user.firstname ? response.user.firstname : response.user.email.match(/^([^@]*)@/)[1];

          localStorage.setItem('user',JSON.stringify(response.user));
          localStorage.setItem('profile',JSON.stringify(response.profile));
          localStorage.setItem('statistics',JSON.stringify(response.statistics));
          if(response.organizations){
            localStorage.setItem('organizations',JSON.stringify(response.organizations));
          }
          this.user = response.user;
          this.profile = response.profile;
          this.gotoDashboard();
        }
        else{
          this.toast.error('Please verify your email before logging in.', 'Error!', { closeButton : true, timeOut: 5000 });        
        }
      })
    }
    else{
      localStorage.setItem('user',JSON.stringify(response.data));
      localStorage.setItem('profile',null);
      this.user = response.data;
      this.profile = null;
      this.router.navigate(['/register'])
    }
  }

  gotoDashboard(){
    let roles = this.userRoles?.filter(role => {
      return role.id == this.user.role_id;
    });
    if(roles && roles.length > 0){
      let role = roles[0];
      if(role.name == PublicService.userRoles.INDIVIDUAL){
        if(this.user && this.user.user_type == 'corporate'){
          this.checkCorporateProfileData();
        }
        else{
          if(localStorage.getItem("selectedTiles")){
            this.stateData = JSON.parse(localStorage.getItem("selectedTiles"));
            if(this.stateData && this.stateData.redirectProject){
              this.router.navigate(['individual/browseproject'], { state : {projectId : this.stateData.redirectProject, userSelectedGrid : this.stateData.userSelectedGrid} });
            }else{
              this.router.navigate(['individual']);
            }
          }else{
            this.router.navigate(['individual']);
          }
        }
      }
      else if(role.name == PublicService.userRoles.COLLEAGUE){
        this.router.navigate(['organization']);
      }
      else if(role.name == PublicService.userRoles.PROJECT_DEVELOPER){
        if(this.profile){
          this.checkProfileData();
        }
        else{
          this.profile = null;
          this.router.navigate(['/register'])
        }
      }
      else if(role.name == PublicService.userRoles.PROJECT_MANAGER){
        this.router.navigate(['/projects'])
      }else{
        this.router.navigate(['/sign-up-options'], { "state" : { "showRoles" : true } });
      }
    }
    else{
      this.toast.error('Invalid User.', 'Error!', { closeButton : true, timeOut: 5000 });
      this.router.navigate(['/login'])
    }
  }

  checkProfileData(){
    if(null != this.profile && null != this.profile.name
         && null != this.profile.businessType){
      this.router.navigate(['landowner']);
    }
    else{
      this.router.navigate(['register']);
    }
  }

  showPassword() {
    this.show_button = !this.show_button;
    this.show_eye = !this.show_eye;
  }

  checkCorporateProfileData(){
    if(null != this.profile && null != this.profile.businessId
         && null != this.profile.businessType && null != this.profile.financial_year){
      
      if(localStorage.getItem("selectedTiles")){
        this.stateData = JSON.parse(localStorage.getItem("selectedTiles"));
        if(this.stateData && this.stateData.redirectProject){
          this.router.navigate(['organization/browseproject'], { state : {projectId : this.stateData.redirectProject, userSelectedGrid : this.stateData.userSelectedGrid} });
        }else{
          this.router.navigate(['organization']);
        }
      }else{
        this.router.navigate(['organization']);
      }
    }
    else{
      this.router.navigate(['register-corporate']);
    }
  }

  changeUserType(type){
    this.userType = type;
  }

  getUserType(userType){
    let role = this.userRoles.filter((role)=>{
      if(userType == "corporate" && role.name == "user"){
        return true; 
      }
      else{
        return role.name == userType;
      }
    });
    if(role && role.length>0){
      return role[0].id;
    }
    return null;
  }

  signInWithGoogle(): void {
    const googleLoginOptions = {
      scope: 'profile email'
    };
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID, googleLoginOptions).then(response => {
      this.socialResponse = response;
      let data = response;
      this._publicApi.customLogin(data).then((response:any) => {
        if(response.hasOwnProperty('status') && response.status == false){
          // this.userType = "user";
          // this.continueSocialLogin()
          this.showRoles = true;
        }
        else{
          this.afterLogin(response);
        }
      }).catch( err => {
        console.log(err);
        this.toast.error('Something went wrong. Please try again', 'Error!', { closeButton : true, timeOut: 5000 });  
      });
    }).catch(err => {
      if(err.error == "popup_closed_by_user"){
      }
      else{
        this.toast.info('Please enable cookies from your browser setting and reload the page.', 'Info!', { closeButton : true, timeOut: 5000 });  
      }
    });
  }

  signInWithFB(): void {
    const fbLoginOptions = {
      scope: 'email',
      return_scopes: true,
      enable_profile_selector: true
    };
    this.authService.signIn(FacebookLoginProvider.PROVIDER_ID, fbLoginOptions).then(response => {
      this.socialResponse = response;
      let data = response;
      this._publicApi.customLogin(data).then((response:any) => {
        if(response.hasOwnProperty('status') && response.status == false){
          this.showRoles = true;
        }
        else{
          this.afterLogin(response);
        }
      }).catch( err => {
        console.log(err);
        this.toast.error('Something went wrong. Please try again', 'Error!', { closeButton : true, timeOut: 5000 });  
      });
    }).catch(err => {
      if(err.error == "popup_closed_by_user"){
      }
      else{
        this.toast.info('Please enable cookies from your browser setting and reload the page.', 'Info!', { closeButton : true, timeOut: 5000 });  
      }
    });
  }

  signOut(): void {
    this.authService.signOut();
  }

  continueSocialLogin(){
    if(this.userType){
      this.socialResponse['role'] = this.getUserType(this.userType);
      if(this.userType == 'corporate'){
        this.socialResponse['user_type'] = 'corporate';
      }
      this._publicApi.customLogin(this.socialResponse).then((response:any) => {
        this.afterLogin(response);
      }).catch( err => {
        console.log(err);
        this.toast.error('Something went wrong. Please try again', 'Error!', { closeButton : true, timeOut: 5000 });  
      });
    }
    else{
      this.toast.error('Please select a user type', 'Error!', { closeButton : true, timeOut: 5000 });  
    }
  }
  
  back(){
    this.socialResponse=undefined;
    this.userType=undefined;
    this.showRoles=false;
  }
}
