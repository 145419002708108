import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-project-latestupdates',
  templateUrl: './project-latestupdates.component.html',
  styleUrls: ['./project-latestupdates.component.scss']
})
export class ProjectLatestupdatesComponent implements OnInit {
  @Input("blog") blog;
  constructor() { }

  ngOnInit(): void {
    console.log(this.blog);
  }

  smart_substr(str, len) {
    if(str){
      var temp = str.substr(0, len);
      if(temp.lastIndexOf('<') > temp.lastIndexOf('>')) {
          temp = str.substr(0, 1 + str.indexOf('>', temp.lastIndexOf('<')));
      }
      return temp;
    }
  }

}
