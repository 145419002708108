import { Component, OnInit ,Input,Output,EventEmitter} from '@angular/core';

@Component({
  selector: 'app-project-biodiversity',
  templateUrl: './project-biodiversity.component.html',
  styleUrls: ['./project-biodiversity.component.scss']
})
export class ProjectBiodiversityComponent implements OnInit {

  @Input("allSpecies") allSpecies;
  constructor() { }

  ngOnInit(): void {
    console.log(this.allSpecies);
  }

}
