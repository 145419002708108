import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

type headerdata = {
	username:string,
	userimage:string,
	leaderboard_number:string,
	total_conservation:string,
	total_area:string,
	total_species:string,
	total_trees:string
}

@Component({
  selector: 'app-project-detail-header',
  templateUrl: './project-detail-header.component.html',
  styleUrls: ['./project-detail-header.component.scss']
})
export class ProjectDetailHeaderComponent implements OnInit {
@Input() headerdata:headerdata
@Output() leaderboard_btn_event = new EventEmitter
  constructor() { }

  ngOnInit(): void {
  }

  leaderboard_btn_clicked(){
	this.leaderboard_btn_event.emit()
  }

}
