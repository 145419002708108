import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NavbarComponent } from './navbar/navbar.component';
import { FooterComponent } from './footer/footer.component';
import { ControlMessageComponent } from './control-message/control-message.component';
import { RouterModule } from '@angular/router';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowseCatagoryComponent } from '../pages/project-components/browse-catagory/browse-catagory.component';
import { ProjectcardComponent } from './projectcard/projectcard.component';
import { BrowseProjectComponent } from '../pages/project-components/browse-project/browse-project.component';
import { ProjectcardtemplateComponent } from './projectcardtemplate/projectcardtemplate.component';
import { ViewDetailsComponent } from '../pages/project-components/view-details/view-details.component';
import { FilterOptionsComponent } from './filter-options/filter-options.component';
import { LeaderboardComponent } from './leaderboard/leaderboard.component';
import { ProjectDetailHeaderComponent } from './project-detail-header/project-detail-header.component';
import { NewprojectComponent } from './newproject/newproject.component';
import { MyprojectsComponent } from './myprojects/myprojects.component';
import { BadgesComponent } from './badges/badges.component';
import { LeaderboardPanalComponent } from './leaderboard-panal/leaderboard-panal.component';
import { BiodiversitySpeciesComponent } from './biodiversity-species/biodiversity-species.component';
import { OrganizationProjectCardComponent } from './organization-project-card/organization-project-card.component';
import { AdminProjectviewComponent } from './admin-projectview/admin-projectview.component';
import { AdminnewprojectComponent } from './adminnewproject/adminnewproject.component';
import { AdminprojectdetailsComponent } from './adminprojectdetails/adminprojectdetails.component';
import { ProjectInvestmentGoalsComponent } from './project-investment-goals/project-investment-goals.component';
import { ProjectMyconservationDetailsComponent } from './project-myconservation-details/project-myconservation-details.component';
import { ProjectLandcoverComponent } from './project-landcover/project-landcover.component';
import { ProjectBiodiversityComponent } from './project-biodiversity/project-biodiversity.component';
import { ProjectLatestupdatesComponent } from './project-latestupdates/project-latestupdates.component';
import { ProjectTophabitatOwnerComponent } from './project-tophabitat-owner/project-tophabitat-owner.component';
import { BuyHabitatComponent } from './buy-habitat/buy-habitat.component';
import { MambersPageComponent } from './mambers-page/mambers-page.component';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { AdminLandownerProjectviewComponent } from './admin-landowner-projectview/admin-landowner-projectview.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { NgxStripeModule } from 'ngx-stripe';
import { Ng2CompleterModule } from 'ng2-completer';


@NgModule({
  imports: [
    CommonModule,
    RouterModule,ReactiveFormsModule,
    NgbModule,FormsModule,
    NgxMapboxGLModule.withConfig({
      accessToken: 'pk.eyJ1IjoiZGV2c3RhdGl4IiwiYSI6ImNrb3kwajV2ejBkaDIyb21zdm1lZzZvc2gifQ.mX7_k8dkysYZVLiU5GmYwQ', // Optional, can also be set per map (accessToken input of mgl-map)
      //geocoderAccessToken: 'TOKEN' // Optional, specify if different from the map access token, can also be set per mgl-geocoder (accessToken input of mgl-geocoder)
    }),
    NgxStripeModule.forRoot('pk_test_51JLMTlBc0UqZAMvosljy2gIXYGKk37UXyfzQ0LeDoBjcBbxqXKARE4NfWTHmJYB1g3IyrhXg2jjlah3lioTRxJ5G00pakyNBl3'),
    Ng2CompleterModule,
  ],
  declarations: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ControlMessageComponent,
    BrowseCatagoryComponent,
    ProjectcardComponent,
    BrowseProjectComponent,
    ProjectcardtemplateComponent,
    ViewDetailsComponent,
    FilterOptionsComponent,
    ProjectDetailHeaderComponent,
    LeaderboardComponent,
    NewprojectComponent,
    MyprojectsComponent,
    BadgesComponent,
    LeaderboardPanalComponent,
    BiodiversitySpeciesComponent,
    OrganizationProjectCardComponent,
    AdminProjectviewComponent,
    AdminnewprojectComponent,
    AdminprojectdetailsComponent,
    ProjectInvestmentGoalsComponent,
    ProjectMyconservationDetailsComponent,
    ProjectLandcoverComponent,
    ProjectBiodiversityComponent,
    ProjectLatestupdatesComponent,
    ProjectTophabitatOwnerComponent,
    BuyHabitatComponent,
    MambersPageComponent,
    AdminLandownerProjectviewComponent
  ],
  exports: [
    FooterComponent,
    NavbarComponent,
    SidebarComponent,
    ControlMessageComponent,
    BrowseCatagoryComponent,
    ProjectcardComponent,
    BrowseProjectComponent,
    ProjectcardtemplateComponent,
    ViewDetailsComponent,
    FilterOptionsComponent,
    ProjectDetailHeaderComponent,
    LeaderboardComponent,
    NewprojectComponent,
	MyprojectsComponent,
	BadgesComponent, 
	LeaderboardPanalComponent,
	BiodiversitySpeciesComponent,
	OrganizationProjectCardComponent,
	AdminProjectviewComponent,
	AdminnewprojectComponent,
	AdminprojectdetailsComponent,
	ProjectInvestmentGoalsComponent,
    ProjectMyconservationDetailsComponent,
    ProjectLandcoverComponent,
    ProjectBiodiversityComponent,
    ProjectLatestupdatesComponent,
    ProjectTophabitatOwnerComponent,
	BuyHabitatComponent,
	MambersPageComponent,
  AdminLandownerProjectviewComponent
  ]
})
export class ComponentsModule { }
