import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PublicService } from 'src/app/api/public.service';
import { ValidationService } from 'src/app/api/validation.service';

@Component({
  selector: 'app-land-owner-signup',
  templateUrl: './land-owner-signup.component.html',
  styleUrls: ['./land-owner-signup.component.css']
})
export class LandOwnerSignupComponent implements OnInit {
  signupForm: FormGroup;
  userType:any;
  userEmail = "";
  userRoles:any;
  registrationDetails:any;
  showRoles = false;
  show_button: Boolean = false;
  show_eye: Boolean = false;
  show_pass: Boolean = false;
  show_eye_pass: Boolean = false;
  constructor(private _formBuilder: FormBuilder, private _publicApi : PublicService, private toast : ToastrService,
    private loader : NgxSpinnerService, private router : Router) {}

  ngOnInit(): void {
    if(localStorage.getItem("invited") == "true"){
      this.userEmail = localStorage.getItem("invitedEmail");
    }
    else{
      localStorage.clear();
    }
    this.initializeForm();
    this.getUserTypes();
  }
  ngOnDestroy() {
  }
  getUserTypes(){
    this._publicApi.getUserTypes().subscribe((response:any) => {
      this.userRoles = response.roles;
    });
  }

  changeUserType(type){
    this.userType = type;
  }
  initializeForm() {
    this.signupForm = this._formBuilder.group({
      //userType: [this.userType, [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      location: ['', [Validators.required]],
     // email: [this.userEmail, [Validators.required, ValidationService.emailValidator]],
      cellphone: ['', [Validators.required, ValidationService.mobileValidator]],
     // password: ['', [Validators.required, ValidationService.passwordValidator]],
      //confirmPassword: ['', [Validators.required, , ValidationService.confirmPasswordValidator('password')]]
    });
    this.signupForm = new FormGroup(this.signupForm.controls, { updateOn: 'blur' });
  }

  showPassword() {
    this.show_button = !this.show_button;
    this.show_eye = !this.show_eye;
  }
  showCPassword() {
    this.show_pass = !this.show_pass;
    this.show_eye_pass = !this.show_eye_pass;
  }
  async signup(){
    this.validate();
    if(this.signupForm.valid){
      this.registrationDetails = {
        "firstname" : this.signupForm.value.firstName,
        "lastname" : ".",
        "email" : this.signupForm.value.email,
        //"cellphone" : this.signupForm.value.cellphone,
        "password" : this.signupForm.value.password,
        "c_password" : this.signupForm.value.confirmPassword
      };
      if(localStorage.getItem("invited") == "true"){
        this.userType="project_manager";
        this.continueRegistration();
      }
      else{
        this.showRoles = true;
      }
    }
    else{
      this.toast.error('Please fill all the details.', 'Error!', { closeButton : true, timeOut: 5000 });
    }
  }

  getUserType(userType){
    let role = this.userRoles.filter((role)=>{
      if(userType == "corporate" && role.name == "user"){
        return true; 
      }
      else{
        return role.name == userType;
      }
    });
    if(role && role.length>0){
      return role[0].id;
    }
    return null;
  }

  validate(){
    for(let prop in this.signupForm.controls){
      this.signupForm.controls[prop].markAsTouched();
    }
  }

  continueRegistration(){
    if(this.userType){
      this.loader.show();
      this.registrationDetails['role'] = this.getUserType(this.userType);
      if(this.userType == 'corporate'){
        this.registrationDetails['user_type'] = 'corporate';
      }
      this._publicApi.customRegister(this.registrationDetails).then((response:any) => {
        this.loader.hide();
        if(response.success){
          this.toast.success("A verification email has been sent to you. Please verify your account", 'Great!', { closeButton : true, timeOut: 5000 }); 
          this.router.navigate(['/login'])
        }
      }).catch( err => {
        console.log(err);
        this.loader.hide();
        this.toast.error(err.error.error, 'Error!', { closeButton : true, timeOut: 5000 });  
      });
    }
    else{
      this.toast.error('Please select a user type', 'Error!', { closeButton : true, timeOut: 5000 });  
    }
  }

  back(){
    this.userType=undefined;
    this.showRoles=false;
  }
}
