import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PublicService } from 'src/app/api/public.service';
import { ToastrService } from 'ngx-toastr';
import { Router, ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ValidationService } from 'src/app/api/validation.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {
  email : any;
  token : any;
  resetPasswordForm : FormGroup;

  constructor(private _formBuilder: FormBuilder, private _publicApi : PublicService, private toast : ToastrService,
    private loader : NgxSpinnerService, private router : Router, private route : ActivatedRoute) {
      this.route.queryParams
      .subscribe(params => {
        this.email = params.email;
        this.token = params.token;
      }
    );
  }


  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.resetPasswordForm = this._formBuilder.group({
      password: ['', [Validators.required, ValidationService.passwordValidator]],
      confirmPassword: ['', [Validators.required, , ValidationService.confirmPasswordValidator('password')]]
    });
    this.resetPasswordForm = new FormGroup(this.resetPasswordForm.controls, { updateOn: 'blur' });
  }

  async submitForm(){
    this.validate();
    if(this.resetPasswordForm.valid){
      let data = {
        "password" : this.resetPasswordForm.value.password,
        "email" : this.email,
        "token" : this.token
      };
      this._publicApi.resetPassword(data).then((response:any) => {
        this.toast.success("Password reset successfull", 'Great!', { closeButton : true, timeOut: 5000 }); 
        this.router.navigate(['/login'])
      }).catch( err => {
        this.toast.error('Password reset failed. Invalid details provided.', 'Error!', { closeButton : true, timeOut: 5000 });  
      });
    }
    else{
      this.toast.error('Invalid Email.', 'Error!', { closeButton : true, timeOut: 5000 });
    }
  }

  validate(){
    for(let prop in this.resetPasswordForm.controls){
      this.resetPasswordForm.controls[prop].markAsTouched();
    }
  }

}
