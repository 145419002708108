import { Component, OnInit,Input,Output, EventEmitter } from '@angular/core';
import { PublicService } from 'src/app/api/public.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ActivatedRoute } from '@angular/router';
import { CompleterService, CompleterData } from 'ng2-completer';


@Component({
  selector: 'app-browse-project',
  templateUrl: './browse-project.component.html',
  styleUrls: ['./browse-project.component.scss']
})
export class BrowseProjectComponent implements OnInit {

	@Input() component_data;
  @Output() project_selected_event = new EventEmitter();
  @Output() loadProjects = new EventEmitter<{ projectsData: any, location: boolean }>();
  filterData={};
  filter_option_data : any;
  is_filter_open:boolean = false;
  totalProjects: number;
  currentPage: Number = 1;
  totalPages: Number;
  page: Number = 1;
  perPage = 10;
  lastPage: number;
  /* -------------------------------------------------------------------------- */
  /*         data for project related to searchable data (name and type)        */
  projects_Data = [
    /* {
      projectID:1,
      projectGeojson:"",
      projectImage:'https://media.istockphoto.com/photos/man-sowing-the-land-at-a-farm-picture-id668076810?k=20&m=668076810&s=612x612&w=0&h=5q9Iqqa0nYhn-MJL80jETxeikjMLBR6PBQhEDUGD5WI=',
      projectName:'Restoration in Sirena Leone',
      projectLocation:'Tonkolili, Sirena Leone',
      projectareaSize:{length:30,width:60},
      projectCountry_image:'https://m.media-amazon.com/images/I/31hUa4vD7aL._AC_SX466_.jpg',
      projectDescription:'Project Repurpose infertile land with the...',
      projectTags:['Restoration','Research'],
      projectProgress:80,
      projectSpecies:{
        speciesType:'Tree',
        numberOFSpecies:10,
      },
      total_area : 0,
      total_revenue: 0,
      total_revenue_current_month: 0,
      total_tile: 272,
      total_tiles_bought: 0,
      total_tiles_bought_current_month: 0,
      total_sites: 0,
    },
    {
      projectID:2,
      projectGeojson:"",
      projectImage:'https://thumbs.dreamstime.com/b/group-forest-workers-sawing-logs-group-forest-workers-sawing-tree-trunks-wood-harvest-forest-170175413.jpg',
      projectName:'Restoration in Sirena Leone',
      projectLocation:'Tonkolili, Sirena Leone',
      projectareaSize:{length:80,width:60},
      projectCountry_image:'https://m.media-amazon.com/images/I/31hUa4vD7aL._AC_SX466_.jpg',
      projectDescription:'Project Repurpose infertile land with the....................',
      projectTags:['Restoration','Research'],
      projectProgress:50,
      projectSpecies:[{
        speciesType:'Tree',
        numberOFSpecies:10,
      },
      {
        speciesType:'Amphibians',
        numberOFSpecies:20,
      },
      {
        speciesType:'Plants',
        numberOFSpecies:42,
      },
      {
        speciesType:'Birds',
        numberOFSpecies:42,
      },
      {
        speciesType:'Fish',
        numberOFSpecies:42,
      }
    ]
    },
    {
      projectID:3,
      projectGeojson:"",
      projectImage:'https://www.dolphinposted.org/file/2020/06/forrest-worker001-1500x750.jpg',
      projectName:'Restoration in Sirena Leone',
      projectLocation:'Tonkolili, Sirena Leone',
      projectareaSize:{length:10,width:20},
      projectCountry_image:'https://m.media-amazon.com/images/I/31hUa4vD7aL._AC_SX466_.jpg',
      projectDescription:'Project Repurpose infertile land with the.............................',
      projectTags:['Restoration','Research'],
      projectProgress:20,
      projectSpecies:[{
        speciesType:'Tree',
        numberOFSpecies:10,
      },
      {
        speciesType:'Amphibians',
        numberOFSpecies:20,
      },
      {
        speciesType:'Plants',
        numberOFSpecies:42,
      },
      {
        speciesType:'Birds',
        numberOFSpecies:42,
      },
      {
        speciesType:'Fish',
        numberOFSpecies:42,
      }]
    } */
  ]
  /* -------------------------------------------------------------------------- */
  /* -------------------------------------------------------------------------- */
  /*                                sort options                                */
  sort_option_data = ["Name","Type","Location","Country","Progress"];
  projectSpecies: any[];
  /* -------------------------------------------------------------------------- */
  constructor(public router : ActivatedRoute, public _publicService : PublicService, private loader : NgxSpinnerService,
    private completerService: CompleterService) {
    this.dataService = this.completerService.local(this.searchData, 'projectName,projectLocation', 'projectName');
    this.getProjectOptionList();
    router.queryParams.subscribe(params => {
      if(params.name && params.type){
        this.filterData[params.type] = params.name;
      }
    })
  }

  ngOnInit(): void {
    console.log("componet data",this.component_data);
    //this.initialize();
    this._publicService.getFilterOptions().then(response => {
      console.log(response);
      this.filter_option_data = response;
    })
  }

  ngOnChanges() {
    console.log(this.component_data);
    this.filterData = {};
    this.initialize();
  }

  initialize(){
    if(this.component_data.selectedCatagoryType != ''){
      this.filterData[this.component_data.selectedCatagoryType] = this.component_data.selectedCatagoryName;
    }
    this.getProjects(this.filterData);
  }

  resetFilter(){
    this.component_data = undefined;
    this.filterData = {};
    this.getProjects(this.filterData);
  }

  sortProjectBy(sortBy){
    this.filterData["sortBy"] = sortBy; 
    this.getProjects(this.filterData);
  }
  

	ProjectDetails(projectId){
		this.project_selected_event.emit(projectId);
  }

  search(event){
    let searchValue = event.target.value;
    if(searchValue.length>3){
      this.getProjects({"searchValue":searchValue});
    }
  }

  getProjects(filterData=null){
    this.loader.show();
    let searchString = "";
    let location = false;
    if (filterData?.searchValue) {
      searchString += "&search=" + filterData.searchValue;
    }
    if (filterData?.intervention) {
      searchString += "&intervention=" + filterData.intervention;
    }

    if(filterData?.location){
      searchString += "&location="+filterData.location;
      location = true;
    }

    if(filterData?.country){
      searchString += "&country="+filterData.country;
      location = true;
    }

    if(filterData?.biome){
      searchString += "&biome="+filterData.biome;
    }
    if(filterData?.species){
      searchString += "&species="+filterData.species;
    }
    if(filterData?.project){
      searchString += "&type="+filterData.project;
    }
    if(filterData?.sortBy){
      searchString += "&orderBy="+filterData.sortBy;
    }
    
    this._publicService.getPublicProjects(this.currentPage, this.perPage, searchString).subscribe(response => {
      this.totalProjects = response.total;
      this.perPage = response.per_page;
      this.lastPage = response.last_page; 
      this.totalPages = Math.ceil(this.totalProjects / this.perPage);
      this.projects_Data = [];
      this.projectSpecies = [];
      response.projects.forEach(project => {
        this.projects_Data.push({
            projectID:project.uid,
            projectGeojson:project.geojson,
            projectImage: this._publicService.getProjectImage(project),
            projectName:project.name,
            location:project.location,
            projectLocation:this._publicService.getProjectLocation(project),
            projectareaSize:{length:this._publicService.getTotalBought(project),width:project.total_area},
            projectCountry_image: this._publicService.getCountryFlagImage(project.country),
            projectDescription:project.summary.substr(0,80)+"...",
            projectTags: this._publicService.getActivityName(project.activities),//['Restoration','Research'],
            projectProgress:this._publicService.getBoughtPercentage(project),
            projectSpecies: project.allSpecies,
            total_area : project.total_area,
            total_revenue: project.total_revenue,
            total_revenue_current_month: project.total_revenue_current_month,
            total_tile: project.total_tile,
            total_tiles_bought: project.total_tiles_bought,
            total_tiles_bought_current_month: project.total_tiles_bought_current_month,
            total_sites: project.total_sites,

        })
      });
      this.loader.hide();
      const projectsData = this.projects_Data;
      this.loadProjects.emit({ projectsData, location });
    }, error => {
      this.loader.hide();
    })
  }

  goToPage(page: number): void {
    if (page >= 1 && page <= this.lastPage) {
        this.currentPage = page;
        this.getProjects();
    }
  }

  filterOnChange(data){
    let keys = Object.keys(data);
    this.component_data = {
      selectedCatagoryName: data[keys[0]],
      selectedCatagoryType:keys[0],
    }
    this.getProjects(data);
  }
  

  public searchStr: string;
  public dataService: CompleterData;
  public searchData=[];

  onSelectSearch(event){
    if(event){
      this.getProjects({"searchValue":event.originalObject.projectName});
    }else{
      this.getProjects(this.filterData);
    }
  }

  getProjectOptionList(){
    this.loader.show();
    this._publicService.getProjectOptionList().subscribe((response:any) => {
      this.loader.hide();
      response.projects.forEach(project => {
        this.searchData.push({
            projectID:project.uid,
            projectName:project.name,
            projectLocation:this._publicService.getProjectLocation(project)
        })
      });
    })
  }


}
