import { Component, OnInit } from '@angular/core';
import { PublicService } from 'src/app/api/public.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Map, Marker } from 'mapbox-gl';

@Component({
  selector: 'app-embed',
  templateUrl: './embed.component.html',
  styleUrls: ['./embed.component.css']
})
export class EmbedComponent implements OnInit {
  type : String;
  id : String;
  project : any;
  mapbox: Map; marker = new Marker();

  constructor(private _publicApi : PublicService, private router: Router,
     private route: ActivatedRoute) {
      const routeParams = this.route.snapshot.paramMap;
      this.type = routeParams.get('type');
      this.id = routeParams.get('id');
  }

  ngOnInit(): void {
    this.getProjectDetail();
  }

  getProjectDetail(){
    this._publicApi.getProjectDetail(this.id).subscribe(response => {
      this.project = response.project;
      this.updateLocation();
    });
  }

  loadMapbox(map){
    this.mapbox = map;
    this.updateLocation();
  }

  updateLocation(){
    if(this.project && this.mapbox){
      this.mapbox.setCenter(this.project.location.split(","));
      this.marker.setLngLat(this.project.location.split(",")).addTo(this.mapbox);
    }
  }
}
