import { Component, OnInit } from '@angular/core';
import { PublicService } from 'src/app/api/public.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { Router } from '@angular/router';

@Component({
  selector: 'app-blogs',
  templateUrl: './blogs.component.html',
  styleUrls: ['./blogs.component.scss']
})
export class BlogsComponent implements OnInit {

  blogs = [/* {
    blogID: 1,
    blogTitle: 'Suze Mens interviews Nigel Farage about DGB Group',
    blogPublishDate: 'Nov 18, 2021',
    blogImage: 'assets/newimages/blog1.png',
    blogDescription: 'Vestibulum in massa risus. Duis sed eros est. Suspendisse condimentum tellus sed lacinia euismod. Proin eget orci viverra viverra purus aliquam...'
  },{
    blogID: 2,
    blogTitle: 'Frequently Asked Questions (FAQ)',
    blogPublishDate: 'Nov 18, 2021',
    blogImage: 'assets/newimages/blog2.png',
    blogDescription: 'Vestibulum in massa risus. Duis sed eros est. Suspendisse condimentum tellus sed lacinia euismod. Proin eget orci viverra viverra purus aliquam...'
  },{
    blogID: 3,
    blogTitle: 'Is the Jaguar an endangered Species?',
    blogPublishDate: 'Nov 18, 2021',
    blogImage: 'assets/newimages/blog3.png',
    blogDescription: 'Vestibulum in massa risus. Duis sed eros est. Suspendisse condimentum tellus sed lacinia euismod. Proin eget orci viverra viverra purus aliquam...'
  },{
    blogID: 4,
    blogTitle: 'Silverback Gorilla - Endangered Species or Not?',
    blogPublishDate: 'Nov 18, 2021',
    blogImage: 'assets/newimages/blog4.png',
    blogDescription: 'Vestibulum in massa risus. Duis sed eros est. Suspendisse condimentum tellus sed lacinia euismod. Proin eget orci viverra viverra purus aliquam...'
  },{
    blogID: 5,
    blogTitle: 'Why Companies Participate in Voluntary Carbon Markets',
    blogPublishDate: 'Nov 18, 2021',
    blogImage: 'assets/newimages/blog5.png',
    blogDescription: 'Vestibulum in massa risus. Duis sed eros est. Suspendisse condimentum tellus sed lacinia euismod. Proin eget orci viverra viverra purus aliquam...'
  },{
    blogID: 6,
    blogTitle: 'The Dawn of the Carbon Credits Age',
    blogPublishDate: 'Nov 18, 2021',
    blogImage: 'assets/newimages/blog6.png',
    blogDescription: 'Vestibulum in massa risus. Duis sed eros est. Suspendisse condimentum tellus sed lacinia euismod. Proin eget orci viverra viverra purus aliquam...'
  } */]

  constructor(public _publicApi : PublicService, public loader : NgxSpinnerService, public router : Router) { }

  ngOnInit(): void {
    this.getPosts();
  }


  getPosts(){
    this.loader.show();
    this._publicApi.getPosts({"offset":0}).subscribe(response => {
      this.loader.hide();
      if(response.success){
        //this.blogs = response.posts;
        console.log(response);
        let pipe = new DatePipe('en-US');
        response.posts.forEach(post => {
            this.blogs.push({
              blogID: post.uid,
              blogTitle: post.title,
              blogPublishDate: pipe.transform(post.created_at, 'short'),
              blogImage: this._publicApi.getBlogImage(post),//  'assets/newimages/blog6.png',
              blogDescription: post.description,
              created_at : post.created_at
              
            });
        });
      }
    }, error => {
      this.loader.hide();
      console.log(error);
    });
  }

  smart_substr(str, len) {
      if(str){
        var temp = str.substr(0, len);
        if(temp.lastIndexOf('<') > temp.lastIndexOf('>')) {
            temp = str.substr(0, 1 + str.indexOf('>', temp.lastIndexOf('<')));
        }
        return temp;
      }
  }

  viewDetail(blog){
    this.router.navigate(['/blog/'+blog.blogID])
  }
}
