import { Component, OnInit,Input, Output,EventEmitter } from '@angular/core';

type component_data_type = {
	
	
}


@Component({
  selector: 'app-project-investment-goals',
  templateUrl: './project-investment-goals.component.html',
  styleUrls: ['./project-investment-goals.component.scss']
})
export class ProjectInvestmentGoalsComponent implements OnInit {
	@Input() component_data:component_data_type  
	@Output() view_goals_clicked_event = new EventEmitter;
	
	constructor() { }

	ngOnInit(): void {
	
	}
	
	view_goal(){
		this.view_goals_clicked_event.emit()
	}	

}
