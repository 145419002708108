import { Component, OnInit,Input } from '@angular/core';

type badgedatatype = {
	badge_name:string,
	badge_image_path:string,
	badge_target_value:string,
	badge_achived_value:string,
	badge_achived_percenatge:string,
	is_badge_achived:boolean
}

@Component({
  selector: 'app-badges',
  templateUrl: './badges.component.html',
  styleUrls: ['./badges.component.scss']
})
export class BadgesComponent implements OnInit {
@Input() badges_data:badgedatatype[]


number_of_badges_to_display = 5;
is_view_all_clicked = false;


constructor() { }

	ngOnInit(): void {
		
	}
	
	change_view(){
		this.is_view_all_clicked = !this.is_view_all_clicked;
		if (this.is_view_all_clicked == true){
			this.number_of_badges_to_display = 9;
		}
		else {
			this.number_of_badges_to_display = 5;
		}
	
	}
}
