import { Component, OnInit, ViewChild,QueryList,ViewChildren, NgZone } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { PublicService } from 'src/app/api/public.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import * as turf from '@turf/turf';
import { Map, Marker, GeoJSONSource, Popup, LngLatBounds, AnySourceData, GeoJSONSourceRaw } from 'mapbox-gl';

@Component({
  selector: 'app-organizationpage',
  templateUrl: './organizationpage.component.html',
  styleUrls: ['./organizationpage.component.scss']
})
export class OrganizationpageComponent implements OnInit {

	projects:any;
	organizationId:any;
	projectDetailsComponentData = {}
	component_data = {
		data:{
			username:'Papasiddhi',
			userimagepath:'',
			userregisterdate:'12 Dec 2021',  
		},
		custom_component_data:{
			project_button_text:'Browse Project',
			is_filter_needed:false
		}
	}

	pages_status = {

		is_leaderboard_page_active:false,
		is_myprojects_page_active:false,
		is_newproject_page_active:false,
		is_ProjectDetails_page_active:false,

	}
	
  close_btn_clicked = false;
  pages_state_history = [];
  search_data={
    name:'',
    type:'',
  }
  /* ------------------------------- css control ------------------------------ */
  initialSeeAll = "showall_container_x";
  initialProjectPanel = "projectx";
  initialState = "leftSection";
  /* -------------------------------------------------------------------------- */
  
  projectId:any;
  project:any;  
  projectDetail:any;
  togglebutton: false;
	map: any;

 
  // FIXME: Call fetch api, after projectID gets data from changeProjectID()
	constructor(public route :ActivatedRoute, public publicService : PublicService,
		public loader : NgxSpinnerService, public router : Router, private zone : NgZone, private toast : ToastrService) {
		this.organizationId = this.route.snapshot.paramMap.get('organizationId');
		if(this.organizationId){
			localStorage.setItem("organizationId",this.organizationId);
		}
	}

	ngOnInit(): void {
		if(this.organizationId){
			this.loader.show();
			this.publicService.getUserInfo().subscribe(response => {
				if(response.organizations){
					localStorage.setItem('organizations',JSON.stringify(response.organizations));
				}
				let organizations = localStorage.getItem('organizations');
				if(organizations){
					let _organizations = JSON.parse(organizations);
					let o:any;
					let _perm:any;
					_organizations.forEach(organization => {
						if(organization.uid == this.organizationId){
							o = organization.user_id;
							_perm = organization.permission;
						}
					});
					localStorage.setItem('permission',_perm);
					this.publicService.getMyOwnerOrganization({"userId":o}).subscribe(response => {
						let data = { user : localStorage.getItem('user'),
							profile : localStorage.getItem('profile'),
							statistics : localStorage.getItem('statistics')
						};
						localStorage.setItem('existingUser', JSON.stringify(data));
						localStorage.setItem('user',JSON.stringify(response.user));
						localStorage.setItem('statistics',JSON.stringify(response.statistics));
						localStorage.setItem('profile',JSON.stringify(response.profile));
						this.toast.success("You have switched to your organization profile.", 'Great!', { closeButton : true, timeOut: 3000 });
						this.loader.hide();
						this.router.navigate(['organization']);
					}, error => {
						this.loader.hide();
					})
				}else{
					this.loader.hide();
					this.pages_status.is_myprojects_page_active = true;
				}
			})
		}else{
			this.pages_status.is_myprojects_page_active = true;
		}
	}

	receiveProjectDataFromMyProjects(data: string) {
		this.projects = data;
		console.log("myprojects", this.projects);
		this.addProjectsToMap(this.projects);
	}
   
	switch_page(value){
	}

	change_state_fn(value){
  
	}
	loadMapbox(map){
		this.map = map;
		this.map.resize();
		this.map.setStyle("mapbox://styles/mapbox/satellite-streets-v11")
	}
	toggleProjectPanel(){
		this.close_btn_clicked = !this.close_btn_clicked;
		if (this.close_btn_clicked == true){
			this.pages_state_history.push(this.pages_status)
			this.pages_status = {
				is_leaderboard_page_active:false,
				is_myprojects_page_active:false,
				is_newproject_page_active:false,
				is_ProjectDetails_page_active:false,
			}
		}
		if (this.close_btn_clicked == false){
			this.pages_status = this.pages_state_history[0];
			this.pages_state_history = [];
		}
		this.initialState=(this.initialState=='leftSection'?'leftSectionHidden':'leftSection');
		this.initialProjectPanel=(this.initialProjectPanel=='projectx'?'projectx_close':'projectx');
		this.initialSeeAll=(this.initialSeeAll=='showall_container_x'?'showall_container_y':'showall_container_x');
  
	}

	project_card_event_receiver(project){
		this.projectDetailsComponentData = {"projectID":project.uid};
		this.pages_status.is_ProjectDetails_page_active = true;
		this.pages_status.is_myprojects_page_active = false;
		this.projectDetail = project;
	}

	leaderboard_clicked_event_receiver(event){
		this.pages_status.is_myprojects_page_active = false;
		this.pages_status.is_leaderboard_page_active = true;
	}
	
	back_from_leaderboard(){
		this.pages_status.is_myprojects_page_active = true;
		this.pages_status.is_leaderboard_page_active = false;
	}
	back_from_project_details(){
		this.pages_status.is_ProjectDetails_page_active = false;
		this.pages_status.is_myprojects_page_active = true;
	}
	gotoLeaderboard(){
		this.pages_status.is_ProjectDetails_page_active = false;
		this.pages_status.is_leaderboard_page_active = true;
	}

	goToBrowseProjectPage() {
		this.router.navigate(['/individual/browseproject'], { queryParams: { AllProjects: true } });
	}

	clearAllLayers(){
		let self = this;
		// if(self.grid2){
		//   self.map.removeControl(self.grid2);
		// }
		// this.selectedCells = [];
		// this.hoverCells = [];
		let sources = [];
		self.map?.getStyle()?.layers.forEach((layer:any) => {
		  let l = ["site","projects","grid","selected-cells","hover-cells","boundryFillLayer","layer-borders","gridLayer","gridLayerChild","userSelectedGridLayer","userOwnerGridLayer","points","site-borders", "sites"]
		  .some(_layerId => layer.id.includes(_layerId));
		  //  console.log(l, layer.id);
		  if(l){
			console.log(layer.id, layer.source);
			self.map.removeLayer(layer.id);
			if(layer.source){
			  //self.map.removeSource(layer.source);
			  sources.push(layer.source);
			}
		  }
		})
		sources.forEach(source => {
		  if(self.map.getSource(source)){
			self.map.removeSource(source);
		  }
		});
		/* console.log(self.map.getStyle().sources);
		self.map.getStyle().sources.forEach(layer => {
		  let l = ["boundryFillLayer","layer-borders","gridLayer","userSelectedGridLayer","userOwnerGridLayer","points","site-borders"]
		  .some(_layerId => layer.id.includes(_layerId));
		  console.log(l, layer.id);
		  if(l){
			self.map.removeLayer(layer.id);
			self.map.removeSource('route');
		  }
		}) */
	}

	addProjectsToMap(projects) {
		let self = this;
		// self.clearAllLayers();
		let inerval = setInterval(function() {
			if(projects && self.map){
				clearInterval(inerval);
				let projectJson = {
					type: "geojson",
					data: {
						type: "FeatureCollection",
						features: []
					}
				};
				projects.forEach(project => {
					let center:any;
					if (project.geojson) {
						let geojson = JSON.parse(project.geojson);
						center = turf.centerOfMass(geojson);
					}else{
						center = turf.point(project.location.split(","));
					}
					
					let _html = self.getPopupHtml(project);
					center.properties = { title: project.name, description: _html, projectId : project.uid, total_sites: project.total_sites};
					projectJson.data.features.push(center);
				});
				self.drawProjectsOnMap(projectJson);
			}
		}, 2000);
	}

    getPopupHtml(project) {
		let pSpecies = project.projectSpecies ? project.projectSpecies : project.allSpecies;
		let allSpecies = '<span class="bg-white badge badge-pill badge-primary"><img _ngcontent-cfq-c210="" src="../../../../assets/img/brand/leaf.png"> 2 </span>';
		if(pSpecies && pSpecies.length>0){
			allSpecies = '';
			pSpecies.forEach(species => {
				allSpecies += '<span class="bg-white badge badge-pill badge-primary"><img _ngcontent-cfq-c210="" src="../../../../assets/img/brand/leaf.png"> '+species.total+' </span>';
			});
		}
		return (
			'<div class="p-2 text-center rounded-circle">' +
			'<div class="row">' +
			'<div class="col-12"><h3 class="text-white">'+project.total_area+'ha</h3></div>' +
			'<div class="col-12">' + allSpecies +
			"</div>" +
			"</div>"
		);
	}

	drawProjectsOnMap(projectJson) {
		let self = this;
		self.clearAllLayers();
		if (this.map.getSource("projects")) {
		  (<GeoJSONSource>this.map.getSource("projects")).setData(projectJson.data);
		} else {
			
			// projectJson.data.features.forEach((feature, index) => {
			// 	feature.properties.number = index + 1;
			// });
		  
		  this.map.addSource("projects", projectJson);
		  this.map.addLayer({
			id: "projects",
			type: "circle",
			source: "projects",
			layout: {
			  visibility: "visible"
			},
			paint: {
				"circle-color": "#1D2859",
				"circle-radius": 10,
				"circle-stroke-width": 4, // Increased border width
				"circle-stroke-color": "#ffffff",
				"circle-stroke-opacity":0.6
			}
		  });
	  
		  this.map.addLayer({
			id: "points",
			type: "symbol",
			source: "projects",
			layout: {
				"text-allow-overlap": false,
				"text-field": ["get", "title"],
				"text-font": ["Raleway Bold"], 
				"text-size": 12,
				"text-offset": [0, 1.5],
				"text-anchor": "top",
				"text-max-width": 10, 
				"text-line-height": 1.2, 
			},
			paint: {
				"text-color": "#ffffff"
			}
		  });
	  
		  const popup = new Popup({
			closeButton: false,
			closeOnClick: false
		  });
	  
		  this.map.on("mouseenter", "projects", e => {
			// Change the cursor style as a UI indicator.
			self.map.getCanvas().style.cursor = "pointer";
	  
			// Copy coordinates array.
			let geometry: any = e.features[0].geometry;
			const coordinates = geometry.coordinates.slice();
			const description = e.features[0].properties.description;
	  
			// Ensure that if the map is zoomed out such that multiple
			// copies of the feature are visible, the popup appears
			// over the copy being pointed to.
			while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
			  coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
			}
	  
			// Populate the popup and set its coordinates
			// based on the feature found.
			popup
			  .setLngLat(coordinates)
			  .setHTML(description)
			  .addTo(self.map);
		  });
	  
		  self.map.on("mouseleave", "projects", () => {
			self.map.getCanvas().style.cursor = "";
			popup.remove();
		  });
	  
		  self.map.on("click", "projects", e => {
			// self.zone.run(() => {
			//   self.projectSelectedEventListener(e.features[0].properties.projectId);
			// })
		  });
		}

		//zoom in to default level
		self.map.flyTo({
			zoom: 1
		});
	  
		// @ts-ignore
		let bounds = new mapboxgl.LngLatBounds();
		projectJson.data.features.forEach(function (feature) {
		  bounds.extend(feature.geometry.coordinates);
		});
	  
		// Adjust the padding value to leave some space around the bounds
		let padding = { top: 50, bottom: 50, left: 50, right: 50 };
	  
		// Fit the map to the bounds with the specified padding
		self.map.fitBounds(bounds, { padding: padding });
	}	

	updateMapStyle(event) {
		let self = this;
		this.zone.run(()=>{
			self.map.setStyle('mapbox://styles/mapbox/' + event.target.value);
			self.addProjectsToMap(self.projects);
		})
	}
}





