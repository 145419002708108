import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { PublicService } from 'src/app/api/public.service';
import { ToastrService } from 'ngx-toastr';
interface Food {
  value: string;
  viewValue: string;
}
@Component({
  selector: 'app-mambers-page',
  templateUrl: './mambers-page.component.html',
  styleUrls: ['./mambers-page.component.scss']
})
export class MambersPageComponent implements OnInit {
  user:any;
  inviteTo:any;
  invitations:any;
  memberId: number; 

  constructor(private _publicApi : PublicService,private modalService: NgbModal, private loader : NgxSpinnerService,
    private toast : ToastrService) { }
  
  selectmenu: boolean = false;
  status1: boolean = false;
  status2: boolean = false;
  status3: boolean = false;
  status4: boolean = false;
  foods: Food[] = [
    {value: 'steak-0', viewValue: 'Steak'},
    {value: 'pizza-1', viewValue: 'Pizza'},
    {value: 'tacos-2', viewValue: 'Tacos'},
  ];
  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.getInvitedOrJoinedMember();
  }
  member_accesss_list= [{accesscode:1,accessname:"Can View Only"},{accesscode:2,accessname:"Can Manage Account"},{accesscode:3,accessname:"Can Purchase Habitat"},{accesscode:4,accessname:"Full Access"}]
  memberslist = [
    {
      membername:"pogaba@gmail.com",
      accesslist:[{accesscode:1,accessname:"Can View Only"}],
      invitationAccepted:0
    },
    {
      membername:"hello@gmail.com",
      accesslist:[{accesscode:2,accessname:"Can Manage Account"}],
      invitationAccepted:0
    },
    {
      membername:"dtaa@gmail.com",
      accesslist:[{accesscode:3,accessname:"Can Purchase Habitat"}],
      invitationAccepted:0
    },
    {
      membername:"bjcrjra@gmail.com",
      accesslist:[{accesscode:4,accessname:"Full Access"}],
      invitationAccepted:0
    },
  ]
  disabled = true;

  openmodal(removemember, email) {
    this.memberId = email;
    this.modalService.open(removemember, {ariaLabelledBy: 'modal-basic-title', animation : true, centered: true}).result.then((result) => {
    
  });
}
  
  showsellectdropdown(){
    this.selectmenu= true;
  }
  button1(){
    if(this.status1==false){
      this.status1 = true;
    }
    else{
      this.status1 = false;
    }
  }
  button2(){
    if(this.status2==false){
      this.status2 = true;
    }
    else{
      this.status2 = false;
    }
  }
  button3(){
    if(this.status3==false){
      this.status3 = true;
    }
    else{
      this.status3 = false;
    }
  }
  button4(){
    if(this.status4==false){
      this.status4 = true;
    }
    else{
      this.status4 = false;
    }
  }

  sendInvite(){
    if(this.checkValidity()){
      this.loader.show();
      this._publicApi.sendCustomerInvite({"email_id":this.inviteTo}).subscribe(response => {
        this.loader.hide();
        this.toast.success("Invitation sent.", 'Great!', { closeButton : true, timeOut: 3000 });
      }, error => {
        this.loader.hide();
        this.toast.info(error.error, 'Info!', { closeButton : true, timeOut: 3000 });
      });
    }
  }

  revokeInvite(memberId) {
    if(memberId) {
      this.loader.show();
      this._publicApi.revokeCustomerInvite({"email_id":memberId}).subscribe(response => {
        this.loader.hide();
        this.toast.success("Member Removed Successfully.", 'Great!', { closeButton : true, timeOut: 3000 });
    
        this.modalService.dismissAll();
        // Refresh the page after 3500 milliseconds (3.5 seconds)
        setTimeout(() => {
          window.location.reload();
        }, 3500);
      }, error => {
        this.toast.error(error.errors, 'Error!', { closeButton : true, timeOut: 3000 });
      });
    }
  }

  checkValidity(){
    if(this.inviteTo && this.inviteTo.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)){
      let i = this.memberslist.findIndex(m => m.membername === this.inviteTo)
      if(i >= 0){
        this.toast.info("This user is already invited or a member.", 'Info!', { closeButton : true, timeOut: 3000 });
        return false;
      }
     return true;
    }
    this.toast.error("Please provide valid email.", 'Error!', { closeButton : true, timeOut: 3000 });
    return false;
  }

  getInvitedOrJoinedMember(){
    this.loader.show();
    this._publicApi.getInvitedOrJoinedMember({"invitation_for":"customer","invited_by":this.user.id}).subscribe(response => {
      this.loader.hide();
      this.invitations = response.invitations;
      console.log(this.invitations);
      this.memberslist = []
      let self = this;
      response.invitations.forEach(invitation => {
        let permission = [];
        permission = (invitation.permission[0] && invitation.permission[0].permission) ? JSON.parse(invitation.permission[0].permission) : []
        console.log(permission);
        this.memberslist.push({
          membername:invitation.email_id,
          accesslist: this.member_accesss_list.filter(x => { return (x.accesscode == (permission[0] ? permission[0].accesscode : 1)) }),
          invitationAccepted : invitation.status
        })
        console.log(this.memberslist);
      });
    }, error => {
      this.loader.hide();
      console.log(error)
    });
  }

  randomIntFromInterval(min, max) { // min and max included 
    let _r = Math.floor(Math.random() * (max - min + 1) + min);
    console.log(_r);
    return _r;
  }

  changePermission(event, email){
    let permission = this.member_accesss_list.filter( x => { return x.accesscode == event.target.value });
    this._publicApi.changePermissionForJoinedMember({"email_id":email, "permission":permission}).subscribe(response => {
      this.toast.success("Permission changed.", 'Great!', { closeButton : true, timeOut: 3000 });
      const index = this.memberslist.findIndex(m => m.membername === email);
      this.memberslist[index].accesslist = permission;
    }, error => {
      console.log(error);
      this.toast.error(error.error, 'Error!', { closeButton : true, timeOut: 3000 });
    })
  }
}
