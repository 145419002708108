import { Injectable } from '@angular/core';
import * as turf from '@turf/turf';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class MapGridService {
  public static GRID_CLICK_EVENT = 'grid.click';
  public static GRID_UPDATE_EVENT = 'grid.update';
  public static GRID_HOVER_EVENT = 'grid.mousemove';

  id:any;
  config:any;
  updateBound:any;
  onMapClickBound:any;
  onMapMouseMoveBound:any;
  onMapTouchMoveBound:any;
  onMapSelectBound:any;
  onMapHoverBound:any
  map:any;
  selectedCells = [];
  hoverCells = [];
  internalGrid = [];
  filteredGeoJson = [];
  isMultipleSelect = false;
  
  /**
     * @param {GridConfig} config
     */

    constructor(config, public toast? : ToastrService) {
      this.id = `grid-${this.randomString()}`;
      this.getFilteredGeojson(config.sourceGeojson)
      config.sourceGeojson = turf.featureCollection(this.filteredGeoJson);
      
      this.config = config;
      this.updateBound = this.update.bind(this);
      this.onMapClickBound = this.onMapClick.bind(this);
      this.onMapMouseMoveBound = this.onMapMouseMove.bind(this);
      this.onMapTouchMoveBound = this.onMapTouchMove.bind(this);
      this.onMapSelectBound = this.onMapSelect.bind(this);
      this.onMapHoverBound = this.onMapHover.bind(this);
  }

  getFilteredGeojson(geoJson){
    let type = turf.getType(geoJson);
    if(type == ("Polygon" || "Multipolygon" || "LineString" || "MultiLineString")){
      this.filteredGeoJson.push(turf.feature(turf.getGeom(geoJson)));
    }
    if(type == "FeatureCollection"){
      geoJson.features.forEach(element => {
        this.getFilteredGeojson(element)
      });
    }
    if(type == "GeometryCollection"){
      let collect = turf.getGeom(geoJson);
      collect.geometries.forEach(geom => {
        this.getFilteredGeojson(geom)
      });
    }
  }

  randomString() {
    return Math.floor(Math.random() * 10e12).toString(36);
  }

  /**
   * @param {Map} map
   * @returns {HTMLElement}
   */
  onAdd(map) {
      this.map = map;

      this.map.on('load', this.updateBound);
      //this.map.on('move', this.updateBound);
      this.map.on('click', this.onMapClickBound);
      this.map.on('mousemove', this.onMapMouseMoveBound);
      //this.map.on('touchmove', this.onMapTouchMoveBound);
      this.map.on('touchmove', (event) => {
        event.preventDefault();
        this.onMapTouchMoveBound(event);
      });
      this.map.on('grid.click', this.onMapSelectBound);
      this.map.on('grid.mousemove', this.onMapHoverBound);
      this.update();

      return document.createElement('div');
  }

  /**
   * @returns {void}
   */
  onRemove() {
      if (!this.map) {
          return;
      }

      const source = this.map.getSource(this.id);
      if (source) {
          this.map.removeLayer(this.id);
          this.map.removeSource(this.id);
      }

      this.map.off('load', this.updateBound);
      //this.map.off('move', this.updateBound);
      this.map.off('click', this.onMapClickBound);
      this.map.off('mousemove', this.onMapMouseMoveBound);
      this.map.off('touchmove', this.onMapTouchMoveBound);
      this.map.off('grid.click', this.onMapSelectBound);
      this.map.off('grid.mousemove', this.onMapHoverBound);

      this.map = undefined;
  }

  /**
   * @returns {void}
   */
  update() {
      if (!this.map) {
          return;
      }

      /** @type {GeoJSON.Feature<GeoJSON.LineString>[]} */
      let grid = [];
      if (this.active) {
          grid = this.getGrid(this.bbox, this.config.gridWidth, this.config.gridHeight, this.config.units, this);
      }

      const source = /** @type {GeoJSONSource} */ (this.map.getSource(this.id));
      if (!source) {
          this.map.addSource(this.id, {
              type: 'geojson',
              data: { type: 'FeatureCollection', features: grid }
          });
          this.map.addLayer({
              id: this.id,
              source: this.id,
              type: 'line',
              layout: { 'line-cap': 'round', 'visibility':'visible' },
              //paint: this.config.paint
              paint: {
                'line-opacity': 0.5,//['case', ['within', convex], 1, 0],
                'line-color':'#e5e5e5',
              },
          });
      } else {
          source.setData({ type: 'FeatureCollection', features: grid });
      }
  }

  /**
   * @returns {boolean}
   */
  get active() {
      if (!this.map) {
          return false;
      }

      const minZoom = this.config.minZoom ? this.config.minZoom : 0;
      const maxZoom = this.config.maxZoom ? this.config.maxZoom : 22;
      const zoom = this.map.getZoom();
      // console.log(zoom);

      return minZoom <= zoom && zoom < maxZoom;
  }

  /**
   * @returns {GeoJSON.BBox}
   */
  get bbox() {
      if (!this.map) {
          throw new Error('Invalid state');
      }
      return this.config.bbox;
      //const bounds = this.map.getBounds();
      //if (bounds.getEast() - bounds.getWest() >= 360) {
      //  bounds.setNorthEast([bounds.getWest() + 360, bounds.getNorth()]);
      //}

      //const bbox = /** @type {GeoJSON.BBox} */ (bounds.toArray().flat());
      //return bbox;
  }

  /**
   * @param {MapMouseEvent} event
   * @returns {void}
   */
  onMapClick(event) {
      if (!this.map || !this.active) {
          return;
      }
      event.preventDefault();
      if (this.map.queryRenderedFeatures(event.point).filter(feature => feature.layer.id === "userOwnerGridLayer").length > 0) {
        //Noting to do
        alert('This tile is not available for buying');
        //this.toast.error('Already bought by someone else.', 'Error!', { closeButton : true, timeOut: 5000 });
      }
      else {
        const point = event.lngLat.toArray();
        const bbox = this.getGridCell(point, this.config.gridWidth, this.config.gridHeight, this.config.units);

        /** @type {GridClickEvent} */
        const event2 = { bbox };
        //this.map.fire(MapGridService.GRID_CLICK_EVENT, event2);
        this.onMapSelect(event2);
      }
  }

/**
 * Simulate multiple map clicks.
 * @param {number} numberOfClicks - The number of clicks to simulate.
 */
simulateMapClicks(numberOfClicks: number) {
  if (!this.map || !this.active) {
    return;
  }

  const gridWidth = this.config.gridWidth;
  const gridHeight = this.config.gridHeight;
  const units = this.config.units;

  // Calculate the cellBbox once outside the loop
  const cellBbox = this.getGrid(this.bbox, gridWidth, gridHeight, units, this);
  for (let i = 0; i < numberOfClicks; i++) {
    if (i >= cellBbox.length) {
      // No more available cells to click
      break;
    }
  
  const coordinateAarr = cellBbox[i].bbox;

  const lng1 = coordinateAarr[0];
  const lat1 = coordinateAarr[1];
  const lng2 = coordinateAarr[2];
  const lat2 = coordinateAarr[3];
  
  // Calculate the minimum and maximum values for longitude (lng) and latitude (lat)
  const minX = Math.min(lng1, lng2);
  const minY = Math.min(lat1, lat2);
  const maxX = Math.max(lng1, lng2);
  const maxY = Math.max(lat1, lat2);
  
  // Calculate the center point
  const centerX = (minX + maxX) / 2;
  const centerY = (minY + maxY) / 2;

  const lngLat = [centerX, centerY];

  const point = this.map.project([centerX, centerY]);

  //skip already bought tiles i.e with layer id of userOwnerGridLayer
  if (this.map.queryRenderedFeatures(point).filter(feature => feature.layer.id === "userOwnerGridLayer").length > 0) {
    // alert('This tile is not available for buying');
    numberOfClicks += 1;
    continue;
  }

const bbox = this.getGridCell(lngLat, this.config.gridWidth, this.config.gridHeight, this.config.units);
  const event2 = {
      "bbox": 
      bbox    
    };

    this.onMapSelect(event2);
    }
}  

  /**
   * @param {MapMouseEvent} event
   * @returns {void}
   */
  onMapMouseMove(event) {
      if (!this.map || !this.active || this.isMobileDevice()) {
          return;
      }

      if(this.isMultipleSelect) {
          event.preventDefault();
          if (this.map.queryRenderedFeatures(event.point).filter(feature => feature.layer.id === "userOwnerGridLayer").length > 0) {
            //Noting to do
            // alert('This tile is not available for buying');
            //this.toast.error('Already bought by someone else.', 'Error!', { closeButton : true, timeOut: 5000 });
          }
          else {
            const point = event.lngLat.toArray();
            const bbox = this.getGridCell(point, this.config.gridWidth, this.config.gridHeight, this.config.units);
    
            /** @type {GridClickEvent} */
            const event2 = { bbox };
            //this.map.fire(MapGridService.GRID_CLICK_EVENT, event2);
            this.onMapSelect(event2);
          }
      } else {
              const point = event.lngLat.toArray();
              const bbox = this.getGridCell(point, this.config.gridWidth, this.config.gridHeight, this.config.units);

              /** @type {GridClickEvent} */
              const event2 = { bbox };
              //this.map.fire(MapGridService.GRID_HOVER_EVENT, event2);
              this.onMapHover(event2);
      }
  }
  
  /**
   * @param {MapMouseEvent} event
   * @returns {void}
  */
  onMapTouchMove(event) {
      if (!this.map || !this.active || !this.isMobileDevice()) {
          return;
      }

      if(this.isMultipleSelect) {
          //event.preventDefault();
          if (this.map.queryRenderedFeatures(event.point).filter(feature => feature.layer.id === "userOwnerGridLayer").length > 0) {
            //Noting to do
            // alert('This tile is not available for buying');
            //this.toast.error('Already bought by someone else.', 'Error!', { closeButton : true, timeOut: 5000 });
          }
          else {
            const point = event.lngLat.toArray();
            const bbox = this.getGridCell(point, this.config.gridWidth, this.config.gridHeight, this.config.units);
    
            /** @type {GridClickEvent} */
            const event2 = { bbox };
            //this.map.fire(MapGridService.GRID_CLICK_EVENT, event2);
            this.onMapSelect(event2);
          }
      } else {
              const point = event.lngLat.toArray();
              const bbox = this.getGridCell(point, this.config.gridWidth, this.config.gridHeight, this.config.units);

              /** @type {GridClickEvent} */
              const event2 = { bbox };
              //this.map.fire(MapGridService.GRID_HOVER_EVENT, event2);
              this.onMapHover(event2);
      }
  }

  /** @typedef {import('@turf/helpers').Units} Units */

  /**
   * @param {GeoJSON.BBox} bbox
   * @param {number} gridWidth
   * @param {number} gridHeight
   * @param {Units} units
   * @param {any} _this
   * @returns {GeoJSON.Feature<GeoJSON.LineString>[]}
   */
    getGrid(bbox, gridWidth, gridHeight, units, _this) {
    // return rectangleGrid(bbox, gridWidth, gridHeight, { units });

    const earthCircumference = Math.ceil(turf.distance([0, 0], [180, 0], { units }) * 2);
    const maxColumns = Math.floor(earthCircumference / gridWidth);
    /** @type {(from: GeoJSON.Position, to: GeoJSON.Position, options: { units: Units }) => number} */
    const fullDistance = (from, to, options) => {
        const dist = turf.distance(from, to, options);
        if (Math.abs(to[0] - from[0]) >= 180) {
            return earthCircumference - dist;
        }
        return dist;
    };

    /** @type {GeoJSON.Feature<GeoJSON.LineString>[]} */
    let features = [];
    let _features = [];
    const west = bbox[0];
    const south = bbox[1];
    const east = bbox[2];
    const north = bbox[3];

    // calculate grid start point
    const deltaX = (west < 0 ? -1 : 1) * fullDistance([0, 0], [west, 0], { units });
    const deltaY = (south < 0 ? -1 : 1) * fullDistance([0, 0], [0, south], { units });
    const startDeltaX = Math.ceil(deltaX / gridWidth) * gridWidth;
    const startDeltaY = Math.ceil(deltaY / gridHeight) * gridHeight;
    /** @type {GeoJSON.Position} */
    const startPoint = [
      turf.destination([0, 0], startDeltaX, 90, { units }).geometry.coordinates[0],
      turf.destination([0, 0], startDeltaY, 0, { units }).geometry.coordinates[1]
    ];

    // calculate grid columns and rows count
    const width = fullDistance([west, 0], [east, 0], { units });
    const height = fullDistance([0, south], [0, north], { units });
    const columns = Math.min(Math.ceil(width / gridWidth), maxColumns);
    const rows = Math.ceil(height / gridHeight);
    // console.log(startPoint, columns, rows);

    /** @type {GeoJSON.Position} */
    let currentPoint;

    this.config.sourceGeojson = turf.combine(this.config.sourceGeojson);
    // meridians
    currentPoint = startPoint;
    for (let i = 0; i < columns; i++) {
        /** @type {GeoJSON.Position[]} */
        const coordinates = [
            [currentPoint[0], south],
            [currentPoint[0], north]
        ];

        let feature = turf.lineString(coordinates);
        //_features.push(feature);

        let splitResults = turf.lineSplit(feature, this.config.sourceGeojson.features[0]);
        turf.featureEach(splitResults, splitResult => {
          let pof = turf.pointOnFeature(splitResult)
          if (turf.booleanWithin(pof, this.config.sourceGeojson.features[0])) {
            features.push(splitResult);
          }
        });

        currentPoint = [
          turf.destination([currentPoint[0], 0], gridWidth, 90, { units }).geometry.coordinates[0],
            currentPoint[1]
        ];
    }

    // parallels
    currentPoint = startPoint;
    for (let i = 0; i < rows; i++) {
        /** @type {GeoJSON.Position[]} */
        const coordinates = [
            [west, currentPoint[1]],
            [east, currentPoint[1]]
        ];

        let feature = turf.lineString(coordinates);
        //_features.push(feature);
        let splitResults = turf.lineSplit(feature, this.config.sourceGeojson.features[0]);
        turf.featureEach(splitResults, splitResult => {
          let pof = turf.pointOnFeature(splitResult)
          if (turf.booleanWithin(pof, this.config.sourceGeojson.features[0])) {
            features.push(splitResult);
          }
        });

        currentPoint = [
            currentPoint[0],
            turf.destination([0, currentPoint[1]], gridHeight, 0, { units }).geometry.coordinates[1]
        ];
    }

    /* _features.forEach(feature => {
      let splitResults = turf.lineSplit(feature, this.config.sourceGeojson.features[0]);
      turf.featureEach(splitResults, splitResult => {
        let pof = turf.pointOnFeature(splitResult)
        if (turf.booleanWithin(pof, this.config.sourceGeojson.features[0])) {
          features.push(splitResult);
        }
      });
    }); */

    return features;
  }

  /**
  * @param {GeoJSON.Position} point
  * @param {number} gridWidth
  * @param {number} gridHeight
  * @param {Units} units
  * @returns {GeoJSON.BBox}
  */
  getGridCell(point, gridWidth, gridHeight, units) {
    const earthCircumference = Math.ceil(turf.distance([0, 0], [180, 0], { units }) * 2);
    /** @type {(from: GeoJSON.Position, to: GeoJSON.Position, options: { units: Units }) => number} */
    const fullDistance = (from, to, options) => {
        const dist = turf.distance(from, to, options);
        if (Math.abs(to[0] - from[0]) >= 180) {
            return earthCircumference - dist;
        }
        return dist;
    };

    const deltaX = (point[0] < 0 ? -1 : 1) * fullDistance([0, 0], [point[0], 0], { units });
    const deltaY = (point[1] < 0 ? -1 : 1) * fullDistance([0, 0], [0, point[1]], { units });
    const minDeltaX = Math.floor(deltaX / gridWidth) * gridWidth;
    const minDeltaY = Math.floor(deltaY / gridHeight) * gridHeight;
    const maxDeltaX = Math.ceil(deltaX / gridWidth) * gridWidth;
    const maxDeltaY = Math.ceil(deltaY / gridHeight) * gridHeight;
    const bbox = /** @type {GeoJSON.BBox} */ ([
      turf.destination([0, 0], minDeltaX, 90, { units }).geometry.coordinates[0],
      turf.destination([0, 0], minDeltaY, 0, { units }).geometry.coordinates[1],
      turf.destination([0, 0], maxDeltaX, 90, { units }).geometry.coordinates[0],
      turf.destination([0, 0], maxDeltaY, 0, { units }).geometry.coordinates[1]
    ]);

    return bbox;
  }

  onMapSelect(event){
    if (!this.map || !this.active) {
        return;
    }
    let self = this;
    const selectedCellsId = 'selected-cells';
    if(!self.map.getLayer(selectedCellsId)){
        self.map.addSource(selectedCellsId, {	
          type: 'geojson',
          data: { type: 'FeatureCollection', features: self.selectedCells }
        });
        self.map.addLayer({
          id: selectedCellsId,
          source: selectedCellsId,
          type: 'fill',
          paint: {
          'fill-color': '#1D2859',
          'fill-opacity': 1,
          'fill-outline-color': '#fff'
          }
        });
    }
    const bbox = event.bbox;

    const cellIndex = self.selectedCells.findIndex(x => x.geometry.bbox.toString() === bbox.toString());
    if (cellIndex === -1) {
      const coordinates = [
        [
          [bbox[0], bbox[1]],
          [bbox[2], bbox[1]],
          [bbox[2], bbox[3]],
          [bbox[0], bbox[3]],
          [bbox[0], bbox[1]],
        ]
      ];
      
      var polygon = turf.polygon(coordinates);
      let _c = turf.center(polygon);
      
      let ptsWithin = turf.pointsWithinPolygon(_c, self.config.sourceGeojson);
      // if(ptsWithin.features.length>0 && self.selectedCells.length<20){
      if (ptsWithin.features.length>0){
        const cell = { type: 'Feature', geometry: { type: 'Polygon', bbox, coordinates }};
        self.selectedCells.push(cell);
      }


      /* var polygon = turf.polygon(coordinates);
      turf.featureEach<any>(self.config.sourceGeojson, function (currentFeature, featureIndex) {
        let type = turf.getType(currentFeature);
        if(type == "Polygon" || type == "MultiPolygon"){
          //Noting to do
        }else if(type == "LineString" ){
          currentFeature = turf.lineToPolygon(currentFeature);
        }else{
          return;
        }
        if(turf.intersect(polygon, currentFeature)){
          const cell = { type: 'Feature', geometry: { type: 'Polygon', bbox, coordinates }};
          self.selectedCells.push(cell);
          //self.userSelectedGrid = self.userSelectedGrid ? self.userSelectedGrid : turf.featureCollection([]);
          //self.userSelectedGrid.features.push(cell);
        }
      }) */
    } else {
      self.selectedCells.splice(cellIndex, 1);
      //self.userSelectedGrid.features.splice(cellIndex, 1);
    }

    let source:any = self.map.getSource(selectedCellsId);
    source.setData({ type: 'FeatureCollection', features: self.selectedCells });
    //self.updateCalculation();
    /* self.map.on(MapGridService.GRID_CLICK_EVENT, event => {
    }); */
    this.map.fire(MapGridService.GRID_UPDATE_EVENT, self.selectedCells);
  }

  onMapHover(event){
    if (!this.map || !this.active) {
        return;
    }
    let self = this;
    const hoverCellsId = 'hover-cells';
    if(!self.map.getLayer(hoverCellsId)){
        self.map.addSource(hoverCellsId, {	
          type: 'geojson',
          data: { type: 'FeatureCollection', features: self.hoverCells }
        });
        self.map.addLayer({
          id: hoverCellsId,
          source: hoverCellsId,
          type: 'fill',
          paint: {
          'fill-color': '#fff',
          'fill-opacity': 0.5,
          'fill-outline-color': '#fff'
          }
        });			
    }
    const bbox = event.bbox; self.hoverCells = [];
    const coordinates = [
      [
      [bbox[0], bbox[1]],
      [bbox[2], bbox[1]],
      [bbox[2], bbox[3]],
      [bbox[0], bbox[3]],
      [bbox[0], bbox[1]],
      ]
    ];

    /* var polygon = turf.polygon(coordinates);
    turf.featureEach<any>(self.config.sourceGeojson, function (currentFeature, featureIndex) {
      let type = turf.getType(currentFeature);
      if(type == "Polygon" || type == "MultiPolygon"){
        //Noting to do
      }else if(type == "LineString" ){
        currentFeature = turf.lineToPolygon(currentFeature);
      }else{
        return;
      }
      
      if(turf.intersect(polygon, currentFeature)){
        const cell = { type: 'Feature', geometry: { type: 'Polygon', bbox, coordinates }};
        self.hoverCells.push(cell);
      }
    }); */
    
    var polygon = turf.polygon(coordinates);
    let _c = turf.center(polygon);

    let ptsWithin = turf.pointsWithinPolygon(_c, self.config.sourceGeojson);
    if(ptsWithin.features.length>0){
      const cell = { type: 'Feature', geometry: { type: 'Polygon', bbox, coordinates }};
      self.hoverCells.push(cell);
  
      let source:any = self.map.getSource(hoverCellsId);
      source.setData({ type: 'FeatureCollection', features: self.hoverCells });
    }
  }

  //check if user is on mobile
  isMobileDevice() {
    const userAgent = navigator.userAgent;
    // Add user agent checks specific to mobile devices.
    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
  }

}