import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { PublicService } from 'src/app/api/public.service';

declare interface RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    id : string;
}

export const ROUTES = 
  {
    "main" : 
    [
      { path: '/dashboard', title: 'Dashboard',  icon: 'fas fa-tv text-default', class: ''},
      { path: '/projects', title: 'Projects',  icon: 'fas fa-file-alt text-default', class: ''},
      { path: '/organizations', title: 'Organization',  icon: 'fas fa-building text-default', class: '' },
    ],
    "projectManager" : 
    [
      { path: '/projects', title: 'Projects',  icon: 'fas fa-file-alt text-default', class: ''},
      { path: '/invitations', title: 'Invitations',  icon: 'fas fa-file-alt text-default', class: ''},
    ],
    "userDashboard" : 
    [
      { path: '/user-dashboard', title: 'Dashboard',  icon: 'fas fa-tv text-default', class: ''},
      { path: '/transactions', title: 'Transactions',  icon: 'fas fa-file-alt text-default', class: ''},
      { path: '/user-profile', title: 'Profile',  icon: 'fas fa-user-circle text-default', class: '' },
    ],
    "project" :[
      { path: '/dashboard', title: 'Dashboard',  icon: 'fas fa-tv text-default', class: '' },
      { path: '/project-detail', title: 'Project Overview',  icon: 'fas fa-info-circle text-default', class: '' },
      { path: '/posts', title: 'Posts',  icon: 'fas fa-clipboard text-default', class: '' },
      { path: '/sites', title: 'Sites',  icon: 'fas fa-map-marker-alt text-default', class: '' },
      { path: '/project-embed', title: 'Embed Project',  icon: 'fas fa-code text-default', class: '' },
      { path: '/project-edit', title: 'Update Project',  icon: 'fas fa-edit text-default', class: '' },
    ],
    "organization":[
      { path: '/dashboard', title: 'Dashboard',  icon: 'fas fa-tv text-default', class: '' },
      { path: '/organization-detail', title: 'Overview',  icon: 'fas fa-info-circle text-default', class: '' },
      { path: '/projects', title: 'Projects',  icon: 'fas fa-project-diagram text-default', class: '' },
      { path: '/organization-edit', title: 'Update Organization',  icon: 'fas fa-edit text-default', class: '' },
    ]
  };

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {

  public menuItems: any[];
  public isCollapsed = true;

  constructor(private router: Router, private pubsub : NgxPubSubService,
    private _publicApi : PublicService) { }

  ngOnInit() {
    let user = JSON.parse(localStorage.getItem('user'));
    this._publicApi.getUserTypes().subscribe((response:any) => {
      let roles = response.roles.filter(role => {
        return role.id == user.role_id;
      })
      if(roles && roles.length>0){
        if(roles[0].name == PublicService.userRoles.INDIVIDUAL){
          this.menuItems = ROUTES.userDashboard.filter(menuItem => menuItem);
        }
        else if(roles[0].name == PublicService.userRoles.PROJECT_DEVELOPER){
          this.menuItems = ROUTES.main.filter(menuItem => menuItem);
        }
        else if(roles[0].name == PublicService.userRoles.PROJECT_MANAGER){
          this.menuItems = ROUTES.projectManager.filter(menuItem => menuItem);
        }
      }
    });

    //this.menuItems = ROUTES.main.filter(menuItem => menuItem);
    this.router.events.subscribe((event) => {
        this.isCollapsed = true;
    });
    this.pubsub.subscribe("menuChange", (type) => {
      if(typeof type == 'string'){
        this.getMenuItems(type);
      }
      else{
        this.menuItems = ROUTES[type.type].filter(menuItem => {
          if(menuItem.title != 'Dashboard') menuItem.id = type.id;
          return menuItem
        });
      }
    })
  }

  getMenuItems(type){
    if(type=="main"){
      let user = JSON.parse(localStorage.getItem('user'));
      this._publicApi.getUserTypes().subscribe((response:any) => {
        let roles = response.roles.filter(role => {
          return role.id == user.role_id;
        })
        if(roles && roles.length>0){
          if(roles[0].name == PublicService.userRoles.PROJECT_DEVELOPER){
            this.menuItems = ROUTES.main.filter(menuItem => menuItem);
          }
          else if(roles[0].name == PublicService.userRoles.PROJECT_MANAGER){
            this.menuItems = ROUTES.projectManager.filter(menuItem => menuItem);
          }
        }
      });
    }
    else{
      this.menuItems = ROUTES[type].filter(menuItem => menuItem);
    }
  }

  logout(){
    this.pubsub.publishEvent('logout');
  }
}
