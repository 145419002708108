import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ViewChildren, ElementRef, ChangeDetectorRef, NgZone, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { PublicService } from 'src/app/api/public.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SocialShareService } from 'src/app/api/social-share.service';

type specie_tags = {
	species_count:string,
	species_image_path:string,

}

type Species = {
	species_name:string,
	species_image_path:string,
	is_species_selected:boolean
}

type Land_cover = {
	land_cover_name:string,
	land_cover_image_path:string,
	is_land_cover_selected:boolean,
	land_cover_specie_tag:specie_tags[]
}

type Species_catagory = {
	species_catagory_name:string,
	species_catagory_image_path:string,
	species_data:Species[],
	is_species_catagory_selected:boolean
}

type Habitat_area = {
	biodiversity_data:Species_catagory[],
	is_habitat_area_selected:boolean,
	area_land_cover:Land_cover[],
	land_area_name:string,
	land_area_tile_count:number,
	land_area_size:number,
	land_area_per_tile_cost:number,
	land_area_code:string

}

type Component_data_type = {
	habitat_name:string,
	habitat_area:Habitat_area[],
	habitat_price:string,
	habitat_id:string,
	habitat_summary:string,
	country_name:string,
	city_name: string,

}

@Component({
  selector: 'app-buy-habitat',
  templateUrl: './buy-habitat.component.html',
  styleUrls: ['./buy-habitat.component.scss']
})
export class BuyHabitatComponent implements OnInit {
	@Input() component_data:any;
	@Output() cancelCheckoutChildEvent = new EventEmitter<any>();
	@Output() back_btn_event= new EventEmitter;
	@Output() gotoSiteEvent = new EventEmitter;
	@ViewChild('successmodal') successmodal; 
	
	@ViewChild(StripeCardComponent) card: StripeCardComponent;
	cardOptions:any;
	cartId:any;
	elementsOptions:any;
	stripeForm: FormGroup;
	validTo: string;
	openPaymentModel=false;
	selectedSite = 0;
	linkvalue:any;
	showDetails = false;
	showBuyingSidebar = false;
	isBuyNow = false;
	autoRenew: boolean = false;


	constructor(private modalService: NgbModal, private loader : NgxSpinnerService, private toast : ToastrService, private cdRef: ChangeDetectorRef, private ngZone : NgZone, 
		private stripeService: StripeService, private _publicApi : PublicService,private fb: FormBuilder, private socialShare : SocialShareService) { }

	ngOnInit(): void {
		this.initializeStripePayment();
		this.viewSite(this.component_data.habitat_area[0].land_area_code);
		
		this.showBuyingSidebar = this.component_data.isShowBuySidebar;
		this.isBuyNow = this.component_data.isBuyNow;

		if (this.isBuyNow) {	
			  this.openPayment();
		  }
	}

	shareStatus(shareprogress) {
		this.linkvalue = this._publicApi.baseUrl+"projects?projectId="+this.component_data.habitat_id;
		this.modalService.open(shareprogress, {windowClass:'shareborder',ariaLabelledBy: 'modal-basic-title', animation : true, centered: true, size: 'lg' as any}).result.then((result) => {		});
	}

	copyLink(inputElement){
		inputElement.select();
		document.execCommand('copy');
		inputElement.setSelectionRange(0, 0);
	}

	projectSocialShare(provider){
		switch(provider){
			case "facebook":
			this.socialShare.shareTrigger({
				'provider': "facebook",
				'attrs': {
				'socialshareUrl': this.linkvalue
				}
			});
			break;
			case "twitter":
				this.socialShare.shareTrigger({
				'provider': "twitter",
				'attrs': {
					'socialshareUrl': this.linkvalue
				    }
				});
				break;
			case "whatsapp":
				this.socialShare.shareTrigger({
				'provider': "whatsapp",
				'attrs': {
					'socialshareUrl': this.linkvalue
				    }
				});
				break;
			case "email":
				this.socialShare.shareTrigger({
				'provider': "email",
				'attrs': {
					'socialshareTo': '',
					'socialshareBody': this.linkvalue,
					'socialshareSubject':this.component_data.habitat_name,
				    }
				});
				break;

			case "linkedin":
				this.socialShare.shareTrigger({
					'provider': "linkedin",
					'attrs': {
						'socialshareUrl': window.location.href
					}
				});
			break;

			case "telegram":
				this.socialShare.shareTrigger({
					'provider': "telegram",
					'attrs': {
						'socialshareUrl': window.location.href
					}
				});
			break;
		}
	}

	hideCheckoutModalEvent() {
		this.cancelCheckoutChildEvent.emit();
	}
	
	back_button_event(){
		this.back_btn_event.emit();
	}

	viewSite(geojson){
		this.gotoSiteEvent.emit(geojson);
	}

	openSuccessModal(successmodal) {
		this.modalService.open(successmodal, {windowClass:'paymentsuccess',ariaLabelledBy: 'multiple-step-form', backdrop:'static', animation : true, centered: true,  size: 'xl' as any}).result.then((result) => {
			console.log('ok');
			console.log(result);
		});
		
	}

	dismissModal(){
		this.modalService.dismissAll();
		window.location.reload();
	}

	openPayment() {
		//get restoration valid until date (now + 1 year)
		const oneYearLater = new Date();
		oneYearLater.setFullYear(oneYearLater.getFullYear() + 1);
		this.validTo = this.formatDate(oneYearLater);

		let self = this;

		if(self.component_data.habitat_user_selected_area_amount > 0){
		  this.loader.show();
		  let data = {
			"products":self.component_data.habitat_user_selected_grid,
			"site_id":self.component_data.habitat_id
		  }
		  if(localStorage.getItem('organizationId')){
			data['organizationId'] = localStorage.getItem('organizationId');
		  }
		  self._publicApi.saveBlocks(data).subscribe(response => {
			this.cartId = response.cart_id;
			this.loader.hide();
		
			this.ngZone.run(() => {
				self.openPaymentModel = true;
			});
		  }, error => {
			this.loader.hide();
			this.toast.error(error.error.errors, 'Error!', { closeButton : true, timeOut: 3000 });
		  });
		}else{
			this.toast.error('Please select at least a single block to continue', 'Error!', { closeButton : true, timeOut: 3000 });
		}
	  }
	
	  payNow(){
		this.loader.show();
		let data = { 
		  "name" : this.stripeForm.value.name
		};
		this.stripeService.createToken(this.card.element, data).subscribe((result) => {
		  if (result.token) {
			console.log(result.token.id);
			let data = {
			  "token":result.token.id,
			  "amount": this.component_data.habitat_user_selected_area_amount,
			  "cart_id" : this.cartId,
			  "project_name": this.component_data.habitat_name,
			  "auto_renew": this.autoRenew,
			}
			//TODO: API Call to deduct the amount at server side
			this._publicApi.makeCharge(data).then(response => {
			  this.loader.hide();
			  this.toast.success("Successfully ordered.", 'Great!', { closeButton : true, timeOut: 3000 });
			  this.openPaymentModel=false;
			  this.openSuccessModal(this.successmodal);
			}).catch( error => {
			  this.loader.hide();
			  this.toast.error(error.error.errors, 'Error!', { closeButton : true, timeOut: 3000 });
			});
	
			this.initializeStripePayment();
			//this.router.navigate(['/project-view/'+this.component_data.habitat_id]);
		  } else if (result.error) {
			this.loader.hide();
			console.log(result.error.message);
			this.toast.error(result.error.message, 'Error!', { closeButton : true, timeOut: 3000 });
		  }
		});
	  }
	
	  initializeStripePayment(){
		this.cardOptions = {
		  style: {
			base: {
			  iconColor: '#666EE8',
			  color: '#31325F',
			  fontWeight: '300',
			  fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
			  fontSize: '18px',
			  '::placeholder': {
				color: '#CFD7E0'
			  }
			}
		  }
		};
	
		this.elementsOptions = {
		  locale: 'en'
		};
	
		this.stripeForm = this.fb.group({
		  name: ['', [Validators.required]]
		});
	  }	

	showMoreDetails(): void {
	this.showDetails = !this.showDetails;
	}
	
	refreshPage() {
		window.location.reload();
	}

	formatDate(date: Date): string {
		const day = date.getDate();
		const month = date.toLocaleString('en-US', { month: 'short' });
		return `${day}${this.getDaySuffix(day)} ${month} ${date.getFullYear()}`;
	}
	  
	getDaySuffix(day: number): string {
		if (day >= 11 && day <= 13) {
		  return 'th';
		}
		const lastDigit = day % 10;
		return lastDigit === 1 ? 'st' : lastDigit === 2 ? 'nd' : lastDigit === 3 ? 'rd' : 'th';
	}
}
