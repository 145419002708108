
import { Component, OnInit, ChangeDetectorRef, NgZone } from '@angular/core';
import { PublicService } from 'src/app/api/public.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Map, Marker, GeoJSONSource, Popup, LngLatBounds, AnySourceData, GeoJSONSourceRaw } from 'mapbox-gl';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import * as turf from '@turf/turf';
import { SocialShareService } from 'src/app/api/social-share.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MapGridService } from 'src/app/api/map-grid.service';
import { FeatureCollection, Feature } from '@turf/turf';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray } from "@angular/forms";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: 'app-admin-projectview',
  templateUrl: './admin-projectview.component.html',
  styleUrls: ['./admin-projectview.component.scss']
})
export class AdminProjectviewComponent implements OnInit {
  projects:any;
  projectId:any;
  locationMarker = null;
  project:any;
  linkvalue:any;
  gridSize = 1;
	pages_state = {
		isProjectCatagoryVisible:true,
		isBrowseProjectVisible:false,
		isProjectDetailsVisible:false,
		isLeaderBoardVisible:false,
		isBuyingPanalVisible:false,
		isBuyingNewForm1Visible:false,
		isBuyingNewForm2Visible:false,
	}

	browseProjectComponentData = {
		selectedCatagoryName:'',
		selectedCatagoryType:''
	}

	projectDetailsComponentData = {
		projectID:'',
		projectObject:undefined,
	}

	leaderboardComponentData = {}

	buyHabitateComponentData = {
		isShowBuySidebar: false,
		isBuyNow: false,
		habitat_name:'Sierra Leone Reforestion',
		habitat_id: 0,
		habitat_summary: '',
		habitat_price:'',
		habitat_user_selected_grid : Object,
		habitat_user_selected_area : 0,
		habitat_user_selected_tile : 0,
		habitat_user_selected_area_amount : 0,
		country_name: "Sierra Leone",
		country_flag: "https://m.media-amazon.com/images/I/31hUa4vD7aL._AC_SX466_.jpg",
		city_name: "Freetown",
		habitat_area:[{
				is_habitat_area_selected:false,
				land_area_tile_count:256,
				land_area_size:2300,
				land_area_name:'Reforestion Area 1',
				land_area_tile_cost:240,
				land_area_code:'101',
				biodiversity_data:[
					{
						species_catagory_name:'Plant Species',
						is_species_catagory_selected:false,
						species_catagory_image_path:'',
						species_data:[
							{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},

						]
					},{
						species_catagory_name:'Tree Species',
						is_species_catagory_selected:false,
						species_catagory_image_path:'',
						species_data:[
							{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},

						]
					},{
						species_catagory_name:'Amphibian Species',
						is_species_catagory_selected:false,
						species_catagory_image_path:'',
						species_data:[
							{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},

						]
					}
				],
				area_land_cover:[
					{
						land_cover_name:'Trees',
						land_cover_image_path:'fa fa-tree',
						is_land_cover_selected:false,
						land_cover_specie_tag:[
							{
								species_count:5,
								species_image_path:'fa fa-tree'

							},{
								species_count:5,
								species_image_path:'fa fa-plant'
							},{
								species_count:5,
								species_image_path:'fa fa-fish'
							},{
								species_count:5,
								species_image_path:'fa fa-lion'
							},
						]
					},{
						land_cover_name:'Bare Ground',
						land_cover_image_path:'fa fa-land',
						is_land_cover_selected:false,
						land_cover_specie_tag:[
							{
								species_count:5,
								species_image_path:'fa fa-tree'

							},{
								species_count:5,
								species_image_path:'fa fa-plant'
							},{
								species_count:5,
								species_image_path:'fa fa-fish'
							},{
								species_count:5,
								species_image_path:'fa fa-lion'
							},
						]
					},{
						land_cover_name:'Water',
						land_cover_image_path:'fa fa-water',
						is_land_cover_selected:false,
						land_cover_specie_tag:[
							{
								species_count:5,
								species_image_path:'fa fa-tree'

							},{
								species_count:5,
								species_image_path:'fa fa-plant'
							},{
								species_count:5,
								species_image_path:'fa fa-fish'
							},{
								species_count:5,
								species_image_path:'fa fa-lion'
							},
						]
					},
				]
			},{
				is_habitat_area_selected:false,
				land_area_tile_count:356,
				land_area_size:3300,
				land_area_name:'Reforestion Area 2',
				land_area_tile_cost:240,
				land_area_code:'102',
				biodiversity_data:[
					{
						species_catagory_name:'Plant Species',
						is_species_catagory_selected:false,
						species_catagory_image_path:'',
						species_data:[
							{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},

						]
					},{
						species_catagory_name:'Tree Species',
						is_species_catagory_selected:false,
						species_catagory_image_path:'',
						species_data:[
							{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},

						]
					},{
						species_catagory_name:'Amphibian Species',
						is_species_catagory_selected:false,
						species_catagory_image_path:'',
						species_data:[
							{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},{
								species_name:'Fern',
								species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
								is_species_selected:false,
							},

						]
					}
				],
				area_land_cover:[
					{
						land_cover_name:'Trees',
						land_cover_image_path:'fa fa-tree',
						is_land_cover_selected:false,
						land_cover_specie_tag:[
							{
								species_count:5,
								species_image_path:'fa fa-tree'

							},{
								species_count:5,
								species_image_path:'fa fa-plant'
							},{
								species_count:5,
								species_image_path:'fa fa-fish'
							},{
								species_count:5,
								species_image_path:'fa fa-lion'
							},
						]
					},{
						land_cover_name:'Bare Ground',
						land_cover_image_path:'fa fa-land',
						is_land_cover_selected:false,
						land_cover_specie_tag:[
							{
								species_count:5,
								species_image_path:'fa fa-tree'

							},{
								species_count:5,
								species_image_path:'fa fa-plant'
							},{
								species_count:5,
								species_image_path:'fa fa-fish'
							},{
								species_count:5,
								species_image_path:'fa fa-lion'
							},
						]
					},{
						land_cover_name:'Water',
						land_cover_image_path:'fa fa-water',
						is_land_cover_selected:false,
						land_cover_specie_tag:[
							{
								species_count:5,
								species_image_path:'fa fa-tree'

							},{
								species_count:5,
								species_image_path:'fa fa-plant'
							},{
								species_count:5,
								species_image_path:'fa fa-fish'
							},{
								species_count:5,
								species_image_path:'fa fa-lion'
							},
						]
					},
				]
		}]
	}

  close_btn_clicked = false;
  pages_state_history = [];
  search_data={
    name:'',
    type:'',
  }
  /* ------------------------------- css control ------------------------------ */
  initialSeeAll = "showall_container_x";
  initialProjectPanel = "projectx";
  initialState = "leftSection";
  /* -------------------------------------------------------------------------- */

  user:any;
  map: Map; marker = new Marker();
  popup = new Popup({ closeButton: false, closeOnClick: false });
  zoomLevel = 0;
  zoomInterval:any;
  userSelectedGrid:any;
  totalAmount = 0;
  pricePerTile = 20;
  selectedTile=0;
  location:any;
  userOwnedGrid:any;

  hoveredStateId=null;
  layerDataArr = [];
  user_selected_plot=[];
  purchased_clicked='';

  selectedCells = [];
  hoverCells = [];
  grid2:any;
  isRedirectedUser = false;
  autoSelectForm: FormGroup;
  private modalRef: NgbModalRef;

  // FIXME: Call fetch api, after projectID gets data from changeProjectID()
  constructor(public _publicApi : PublicService, private loader : NgxSpinnerService,
    private modalService: NgbModal, private socialShare : SocialShareService, private toast: ToastrService,
    private cdRef: ChangeDetectorRef, public router : Router, private zone : NgZone, private route: ActivatedRoute,
	private _formBuilder: FormBuilder) {
      let state = router.getCurrentNavigation().extras.state;
      if(state && state.projectId && state.userSelectedGrid){
        this.userSelectedGrid = state.userSelectedGrid;
		this.isRedirectedUser = true;
        this.projectSelectedEventListener(state.projectId);
      } else if(state && state.projectId){
		this.projectSelectedEventListener(state.projectId);
	  }
  }

  ngOnInit(): void {
	this.initializeForm();
	const shouldCallAllProjectFunction = this.route.snapshot.queryParams.AllProjects;
    if (shouldCallAllProjectFunction) {
	  this.viewAllProjects();
    }
  }


initializeForm() {
this.autoSelectForm = this._formBuilder.group({
	// numberOfTiles: ['', [Validators.required, Validators.min(1)]] 
	});
}

  projectSocialShare(provider){
    switch(provider){
		case "facebook":
		this.socialShare.shareTrigger({
			'provider': "facebook",
			'attrs': {
			'socialshareUrl': window.location.href
				}
			});
		break;
		case "twitter":
			this.socialShare.shareTrigger({
			'provider': "twitter",
			'attrs': {
				'socialshareUrl': window.location.href
			    }
			});
			break;
		case "whatsapp":
			this.socialShare.shareTrigger({
			'provider': "whatsapp",
			'attrs': {
				'socialshareUrl': window.location.href
			    }
			});
			break;
		case "email":
			this.socialShare.shareTrigger({
			'provider': "email",
			'attrs': {
				'socialshareTo': '',
				'socialshareBody':window.location.href,
				'socialshareSubject':this.project.name,
		    	}
			});
			break;

		case "linkedin":
			this.socialShare.shareTrigger({
				'provider': "linkedin",
				'attrs': {
					'socialshareUrl': window.location.href
				}
			});
		break;

		case "telegram":
			this.socialShare.shareTrigger({
				'provider': "telegram",
				'attrs': {
					'socialshareUrl': window.location.href
				}
			});
		break;
    }
  }

  /* ------------------------- changing project thumbnil images ------------------------ */
  loadMapbox(map){
    this.map = map;
    this.map.resize();
    this.map.setStyle("mapbox://styles/mapbox/satellite-streets-v11")
  }

	toggleProjectPanel(){
		this.close_btn_clicked = !this.close_btn_clicked;
		if (this.close_btn_clicked == true){
			this.pages_state_history.push(this.pages_state)
			this.pages_state = {
				isProjectCatagoryVisible:false,
				isBrowseProjectVisible:false,
				isProjectDetailsVisible:false,
				isLeaderBoardVisible:false,
				isBuyingPanalVisible:false,
				isBuyingNewForm1Visible:false,
				isBuyingNewForm2Visible:false,
			}
		}

		if (this.close_btn_clicked == false){
			this.pages_state = this.pages_state_history[0];
			this.pages_state_history = [];
		}

		this.initialState=(this.initialState=='leftSection'?'leftSectionHidden':'leftSection');
		this.initialProjectPanel=(this.initialProjectPanel=='projectx'?'projectx_close':'projectx');
		this.initialSeeAll=(this.initialSeeAll=='showall_container_x'?'showall_container_y':'showall_container_x');

  }

	browseCatagoryEventListener(catagory_data){
		this.browseProjectComponentData = catagory_data;
		this.pages_state.isProjectCatagoryVisible = false;
		this.pages_state.isBrowseProjectVisible = true;
		//this.clearAllLayers(); =>was throwing error Cannot read properties of undefined (reading 'getStyle') on console(revert and modify if need be)
	}

	projectSelectedEventListener(project_data){
    //this.projectId = project_data;
    this.projectDetailsComponentData = {projectID : project_data, projectObject:null}
    this.pages_state.isProjectCatagoryVisible = false;
		this.pages_state.isBrowseProjectVisible = false;
		this.pages_state.isProjectDetailsVisible = true;
		this.pages_state.isBuyingNewForm1Visible = true;
	}

	gotoLeaderboard(){
		this.pages_state.isProjectDetailsVisible = false;
		this.pages_state.isLeaderBoardVisible = true;
		this.pages_state.isBuyingNewForm1Visible = false;
	}

	gotoProjects(){
		this.pages_state.isProjectDetailsVisible = false;
		this.pages_state.isBrowseProjectVisible = true;
		this.pages_state.isBuyingNewForm1Visible = false;
		this.clearAllLayers();
	}

	leaderboardEventListener(){
		this.pages_state.isLeaderBoardVisible = false;
		this.pages_state.isProjectDetailsVisible = true;
		this.pages_state.isBuyingNewForm1Visible = true;
	}

	gotoBuyHabitat(buyNow?: boolean) {
		this.grid2.isMultipleSelect = false;
		this.zone.run(() => {
			var flags = [], output = [], l = this.grid2.selectedCells.length, i;
			for( i=0; i<l; i++) {
				let center = turf.center(this.grid2.selectedCells[i]);
				let uid = this.getUniqueId(center.geometry.coordinates[0],center.geometry.coordinates[1]);
				if( flags[uid]) continue;
				flags[uid] = true;
				output.push(this.grid2.selectedCells[i]);
			}

			this.userSelectedGrid = turf.featureCollection(output);
			this.updateCalculation();
			this.buyHabitateComponentData.habitat_user_selected_grid = this.userSelectedGrid,
			this.buyHabitateComponentData.habitat_user_selected_area = this.selectedTile,
			this.buyHabitateComponentData.habitat_user_selected_tile = this.userSelectedGrid.features.length,
			this.buyHabitateComponentData.habitat_user_selected_area_amount = this.selectedTile*this.pricePerTile,
			this.buyHabitateComponentData.habitat_name = this.projectDetailsComponentData.projectObject.name,
			this.buyHabitateComponentData.habitat_id = this.projectDetailsComponentData.projectObject.uid
			this.buyHabitateComponentData.habitat_summary = this.projectDetailsComponentData.projectObject.summary
			this.buyHabitateComponentData.country_name = this.projectDetailsComponentData.projectObject['country_name'].name,
			this.buyHabitateComponentData.country_flag = this._publicApi.getCountryFlagImage(this.projectDetailsComponentData.projectObject['country_name']),
			this.buyHabitateComponentData.city_name = this.projectDetailsComponentData.projectObject['city_name'] ? this.projectDetailsComponentData.projectObject['city_name'].name : "",

			this.buyHabitateComponentData.habitat_area = [];
			this.projectDetailsComponentData.projectObject.sites.forEach(site => {
				this.buyHabitateComponentData.habitat_area.push({
					is_habitat_area_selected:false,
					land_area_tile_count:0,
					land_area_size:0,
					land_area_name:site.name,
					land_area_tile_cost:0,
					land_area_code: this.projectDetailsComponentData.projectObject.geojson,
					biodiversity_data : [],
					area_land_cover : []
				});
			});	

			if(buyNow) {
				this.buyHabitateComponentData.isBuyNow = true;
			}

			if(this.buyHabitateComponentData.isShowBuySidebar) {
				this.pages_state.isProjectDetailsVisible = false;
				this.pages_state.isBuyingPanalVisible = true;
			} else {
				this.pages_state.isProjectDetailsVisible = true;
				this.pages_state.isBuyingPanalVisible = true;
			}		
			// this.pages_state.isBuyingNewForm2Visible = false;
			// this.cdRef.detectChanges();
	    });
	}

	gotoBuyHabitat2(){
		this.zone.run(() => {
			var flags = [], output = [], l = this.grid2.selectedCells.length, i;
			for( i=0; i<l; i++) {
				let center = turf.center(this.grid2.selectedCells[i]);
				let uid = this.getUniqueId(center.geometry.coordinates[0],center.geometry.coordinates[1]);
				if( flags[uid]) continue;
				flags[uid] = true;
				output.push(this.grid2.selectedCells[i]);
			}

			this.userSelectedGrid = turf.featureCollection(output);
			this.updateCalculation();
			this.buyHabitateComponentData.habitat_user_selected_grid = this.userSelectedGrid,
			this.buyHabitateComponentData.habitat_user_selected_area = this.selectedTile,
			this.buyHabitateComponentData.habitat_user_selected_tile = this.userSelectedGrid.features.length,
			this.buyHabitateComponentData.habitat_user_selected_area_amount = this.selectedTile*this.pricePerTile,
			this.buyHabitateComponentData.habitat_name = this.projectDetailsComponentData.projectObject.name,
			this.buyHabitateComponentData.habitat_id = this.projectDetailsComponentData.projectObject.uid
			this.buyHabitateComponentData.habitat_summary = this.projectDetailsComponentData.projectObject.summary
			this.buyHabitateComponentData.country_name = this.projectDetailsComponentData.projectObject['country_name'].name,
			this.buyHabitateComponentData.country_flag = this._publicApi.getCountryFlagImage(this.projectDetailsComponentData.projectObject['country_name']),
			this.buyHabitateComponentData.city_name = this.projectDetailsComponentData.projectObject['city_name'] ? this.projectDetailsComponentData.projectObject['city_name'].name : "",

			this.buyHabitateComponentData.habitat_area = [];
			this.projectDetailsComponentData.projectObject.sites.forEach(site => {
				this.buyHabitateComponentData.habitat_area.push({
					is_habitat_area_selected:false,
					land_area_tile_count:0,
					land_area_size:0,
					land_area_name:site.name,
					land_area_tile_cost:0,
					land_area_code: this.projectDetailsComponentData.projectObject.geojson,
					biodiversity_data : [],
					area_land_cover : []
				});
			});

			this.pages_state.isBuyingPanalVisible = false;
			this.pages_state.isBuyingNewForm1Visible = false;
			this.pages_state.isBuyingNewForm2Visible = true;
		});		
	}

	gotoProjectDetail(){
		this.pages_state.isProjectDetailsVisible = true;
		this.pages_state.isBuyingPanalVisible = false;	
		this.pages_state.isBuyingNewForm1Visible = true;
		this.pages_state.isBuyingNewForm2Visible = false;
		this.clearAllLayers();
	}

  shareStatus(shareprogress) {
    this.linkvalue = this._publicApi.baseUrl+"projects?projectId="+this.projectDetailsComponentData.projectID;
    this.modalService.open(shareprogress, {windowClass:'shareborder',ariaLabelledBy: 'modal-basic-title', animation : true, centered: true, size: 'lg' as any}).result.then((result) => {

    });
  }

  copyHeroName(inputElement){
    inputElement.select();
    document.execCommand('copy');
    inputElement.setSelectionRange(0, 0);
   }

	viewAllProjects(){
		this.clearAllLayers()
		this.pages_state.isProjectDetailsVisible = false;
		this.browseCatagoryEventListener({});
	}

	loadGeoJson(project){
		let self = this;
		this.project = project;
		this.projectDetailsComponentData.projectObject = project;
		var refreshIntervalId = setInterval(()=>{
			if(self.map){
				self.clearAllLayers();
				clearInterval(refreshIntervalId);

				let siteJson = {
					type: "geojson",
					data: {
						type: "FeatureCollection",
						features: []
					}
				};
				project.sites.forEach(site => {
					let center:any;
					if (site.geo_json) {
						let geojson = JSON.parse(site.geo_json);
						center = turf.centerOfMass(geojson);
					}else{
						center = turf.point(project.location.split(","));
					}
					center.properties = { title: site.name, projectId : site.uid};
					siteJson.data.features.push(center);
				});

				self.addGeojsonBoundryToMap(project, siteJson);
				self.drawSitesOnMap(project);
			}
		},100);
	}

//     loadGeoJson(project){
// 	this.clearAllLayers();
//     this.projectDetailsComponentData.projectObject = project;
//     let self = this;
//     var refreshIntervalId = setInterval(()=>{
//       if(self.map){
// 			if(self.map.getLayer('selected-cells')){
// 				self.map.removeLayer('selected-cells');
// 				self.map.removeSource('selected-cells');
// 			}
// 			clearInterval(refreshIntervalId);

// 			let siteJson = {
// 				type: "geojson",
// 				data: {
// 					type: "FeatureCollection",
// 					features: []
// 				}
// 			};
			
// 			project.sites.forEach(site => {
// 				let center:any;
// 				if (site.geo_json) {
// 					let geojson = JSON.parse(site.geo_json);
// 					center = turf.centerOfMass(geojson);
// 				}else{
// 					center = turf.point(project.location.split(","));
// 				}
// 				center.properties = { title: site.name, projectId : site.uid};
// 				siteJson.data.features.push(center);
// 			});

// 			self.addGeojsonBoundryToMap(project, siteJson);
// 			self.drawSitesOnMap(project);
// 		}
// 	},100);
//   }

// Function to convert area from sqmt to hectares (ha)
convertAreaToHectares(areaInSqmt) {
    return areaInSqmt / 10000; // 1 hectare = 10,000 square meters
}

  addGeojsonBoundryToMap(project, siteJson){
    let self = this;
    if(self.map && project){
      setTimeout(()=>{
		this.processSiteGeojson(project);

		let projectJson = JSON.parse(project.geojson);
		
		//===== all sites ============/
		let allFeature = JSON.parse(project.geojson).features;
		project.sites.forEach(site => {
			allFeature = allFeature.concat(JSON.parse(site.geo_json).features);
		});

		var fc = turf.featureCollection(allFeature);
		
		fc.features.forEach((feature) => {
			if (feature.properties && feature.properties.area) {
				// Remove the 'area' property from feature.properties
				delete feature.properties.area;
			}
			// // Convert the area property from sqmt to hectares in the feature collection
			// if (feature.properties && feature.properties.area) {
			// 	const areaInSqmt = parseFloat(feature.properties.area.replace(/[^0-9.]/g, '')); // Remove non-numeric characters like "sqmt"
			// 	const areaInHa = this.convertAreaToHectares(areaInSqmt);
			// 	feature.properties.area = `${areaInHa.toFixed(2)} ha`; 
			// }
		});
	
		let siteGeojson = JSON.stringify(fc);
		//===== all sites ============/

        let geoJson = JSON.parse(siteGeojson);
        if(self.map.getSource("sourceGeoJson")){
          (<GeoJSONSource>self.map.getSource("sourceGeoJson")).setData(geoJson);
        }
        else{
          self.map.addSource("sourceGeoJson", {
                type: "geojson",
                data: geoJson
          });
        }

		// Get the center coordinates of the GeoJSON
		var center = turf.centerOfMass(projectJson);
	
		// Extract the latitude and longitude values
		var lng = center.geometry.coordinates[0];
		var lat = center.geometry.coordinates[1];

		// Create a marker at the center with the project name as the title
		self.locationMarker = new Marker({ color: '#1D2859' })
			.setLngLat([lng, lat])
			.addTo(self.map);
	
		let markerAdded = true;
		
		self.map.on('zoom', () => {
			const currentZoom = self.map.getZoom();			
			if (self.locationMarker) {
			  if (currentZoom > 12 && markerAdded) {
				self.locationMarker.remove();
				markerAdded = false;
			  } else if (currentZoom <= 12 && !markerAdded) {
				self.locationMarker.addTo(self.map);
				markerAdded = true;
			  }
			}
		});		  
			
		// // Create a new HTML element for the text
		// var textElement = document.createElement('div');
		// textElement.className = 'marker-text';
		// textElement.textContent = project.name;

		// // Position the text below the marker
		// textElement.style.position = 'absolute';
		// textElement.style.bottom = '-25px';
		// textElement.style.left = '50%';
		// textElement.style.transform = 'translateX(-50%)';
		// textElement.style.color = '#ffffff';
		// textElement.style.padding = '5px 10px';
		// textElement.style.borderRadius = '5px';
		// textElement.style.fontFamily = 'Arial, sans-serif';
		// textElement.style.fontSize = '14px';
		// textElement.style.fontWeight = 'bold';

		// // Append the text element to the marker's element
		// marker.getElement().appendChild(textElement);

        if (!self.map.getLayer('boundryFillLayer')) {
          self.map.addLayer({
              'id': 'boundryFillLayer',
              'type': 'fill',
              'source': 'sourceGeoJson',
              'layout': {},
              'paint': {
				  "fill-color": "#4F5C41",
                  "fill-outline-color":"#f59d24",
                  "fill-opacity": [
                      "case",
                      ["boolean", ["feature-state", "hover"], false],
                      1,
                      0.5,
                  ],
              }
          });

          self.map.addLayer({
              'id': 'layer-borders',
              'type': 'line',
              'source': 'sourceGeoJson',
              'layout': {},
              'paint': {
                  'line-color': '#f59d24',
                  'line-width': 2
              }
		  });
		  
		  	self.map.addLayer({
				'id': 'project-point',
				'type': 'circle',
				'source': 'sourceGeoJson',
				'paint': {
					'circle-radius': 6,
					'circle-color': '#1D2859'
				},
				'filter': ['==', '$type', 'Point']
			});
			self.map.addLayer({
				id: "points",
				type: "symbol",
				source: "sourceGeoJson",	
				layout: {
				  "text-allow-overlap": false,
				  "text-field": ["get", "area"],
				  "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
				  "text-offset": [0, 1],
				  "text-anchor": "top"
				},
				paint: {
				  "text-color": "#ffffff"
				}
			});
        }

        // if(!self.grid2){
          self.grid2 = new MapGridService({
              gridWidth: 0.1,
              gridHeight: 0.1,
              minZoom: 1,
              maxZoom: 20,
              units: 'kilometers',
              paint: {
                'line-opacity': 0.2,
                'line-width':2,
                'line-color':'#e5e5e5'
              },
              bbox : turf.bbox(geoJson),
              sourceGeojson : geoJson
          });

		  siteJson.data.features.forEach((feature, index) => {
			feature.properties.number = index + 1;
		});

		if (this.map.getSource("in-sites")) {
			(<GeoJSONSource>this.map.getSource("in-sites")).setData(siteJson.data);
		  } else {			
			this.map.addSource("in-sites", siteJson);
			this.map.addLayer({
			  id: "in-sites",
			  type: "circle",
			  source: "in-sites",
			  maxzoom: 12,
			  layout: {
				visibility: "visible"
			  },
			  paint: {
				  "circle-color": "#1D2859",
				  "circle-radius": 10,
				  "circle-stroke-width": 4, // Increased border width
				  "circle-stroke-color": "#ffffff",
				  "circle-stroke-opacity":0.6
			  }
			});
		
			this.map.addLayer({
			  id: "in-points",
			  type: "symbol",
			  source: "in-sites",
			  maxzoom: 12,
			  layout: {
				  "text-allow-overlap": false,
				  "text-field": ["to-string", ["get", "number"]],
				  "text-font": ["Open Sans Bold"],
				  "text-size": 12,
				  "text-offset": [0, 0],
				  "text-anchor": "center"
				},
				paint: {
				  "text-color": "#ffffff"
			  }
			});
		}		
      
          self.map.addControl(self.grid2)
        // }else{
        //   self.grid2.selectedCells = [];
		// }
		
		//handle for user who had selected tiles to purchase before logging in
		if (this.isRedirectedUser) {
			this.grid2.selectedCells = this.userSelectedGrid;

			const selectedCellsId = 'selected-cells';
			if(!self.map.getLayer(selectedCellsId)){
				self.map.addSource(selectedCellsId, {	
				  type: 'geojson',
				  data: { type: 'FeatureCollection', features: this.userSelectedGrid }
				});
				self.map.addLayer({
				  id: selectedCellsId,
				  source: selectedCellsId,
				  type: 'fill',
				  paint: {
				  'fill-color': '#1D2859',
				  'fill-opacity': 1,
				  'fill-outline-color': '#fff'
				  }
				});
			}		

			let source:any = self.map.getSource(selectedCellsId);
            source.setData({ type: 'FeatureCollection', features: this.userSelectedGrid });
			this.gotoBuyHabitat2();

			this.isRedirectedUser = false;
			localStorage.removeItem('selectedTiles');
		}
		self.map.on(MapGridService.GRID_UPDATE_EVENT, event => {
			if(this.pages_state.isBuyingPanalVisible){
				this.buyHabitateComponentData.isShowBuySidebar = true;
				this.gotoBuyHabitat();
			} else {				
				this.gotoBuyHabitat2();
			}
		});

        self.createUserOwnedGridLayer(project.uid);

      }, 1500);
    }
  }

  performMapZoom(geoJson, features){
    let self = this;
    let selectedFeature = self.getSelectedFeature(geoJson, features[0].id);
    let featureCollection = turf.featureCollection(selectedFeature);
    self.onMapZoom(featureCollection, geoJson, features[0].id);
  }

  getSelectedFeature(geoJson, featureId){
    let selectedFeature = geoJson.features.filter((feature) => {
      return (feature.id == featureId)
    });
    return selectedFeature;
  }

  processSiteGeojson(project){
	let f:any;
	let geoJson = JSON.parse(project.geojson);
	this.gotoLocation(geoJson);
  }

  gotoLocation(feature){
	let center = turf.centerOfMass(feature);
	let c:any = center.geometry.coordinates;
	this.map.flyTo({
		center: c,
		zoom: 12
	});
    // let center = turf.centerOfMass(feature);
    // let c:any = center.geometry.coordinates;
    // this.map.setCenter(c);
    // this.map.setZoom(12);
  }

  onMapZoom(featureGeoJson, sourceGeoJson, featureId){
    let self = this;
    clearInterval(self.zoomInterval);
    var zoomVal = Math.floor(self.map.getZoom());
    if(zoomVal == this.zoomLevel){
      return;
    }
    this.zoomLevel = zoomVal;
    //console.log(this.zoomLevel);
    if(this.zoomLevel==19){
      //console.log(featureGeoJson);
      self.createGridLayer(featureGeoJson, sourceGeoJson, featureId);
    }
  }

  getUniqueId(lat, lon) {
      //let lat_int = (lat.toFixed(6) * 10000000);
      //let lon_int = (lon.toFixed(6) * 10000000);
      let lat_int = (lat * 10000000);
      let lon_int = (lon * 10000000);
      let latMask = lat_int > 0x7FFF0000 ? 0x1ffff0000 : 0xffff0000;
      let val=Math.abs(lat_int << 16 &  latMask | lon_int & 0x0000ffff);
      val = val % Number.MAX_VALUE;
      return val;
  }

  clearAllLayers(){
    let self = this;
    if(self.grid2){
      self.map.removeControl(self.grid2);
    }
    this.selectedCells = [];
    this.hoverCells = [];
    let sources = [];
    self.map?.getStyle()?.layers.forEach((layer:any) => {
      let l = ["project-point","grid","selected-cells","hover-cells","boundryFillLayer","layer-borders","gridLayer","gridLayerChild","userSelectedGridLayer","userOwnerGridLayer","points","projects","site-borders", "sites"]
      .some(_layerId => layer.id.includes(_layerId));
      //  console.log(l, layer.id);
      if(l){
        console.log(layer.id, layer.source);
        self.map.removeLayer(layer.id);
        if(layer.source){
          //self.map.removeSource(layer.source);
          sources.push(layer.source);
        }
      }
    })
    sources.forEach(source => {
      if(self.map.getSource(source)){
        self.map.removeSource(source);
      }

		if(self.locationMarker) {
			self.locationMarker.remove();
			self.locationMarker = null;
		}
    });
    /* console.log(self.map.getStyle().sources);
    self.map.getStyle().sources.forEach(layer => {
      let l = ["boundryFillLayer","layer-borders","gridLayer","userSelectedGridLayer","userOwnerGridLayer","points","site-borders"]
      .some(_layerId => layer.id.includes(_layerId));
      console.log(l, layer.id);
      if(l){
        self.map.removeLayer(layer.id);
        self.map.removeSource('route');
      }
    }) */
  }

  createGridLayer(geoJson, sourceGeoJson, featureId){
    let self = this;
    if(self.map.getLayer('gridLayer'+featureId)){
      return false;
    }

    let hoveredStateId = null;
	  let buffered = turf.buffer(geoJson, self.gridSize, {units: 'kilometers'});

	  let bbox = turf.bbox(buffered);
	  
	  let options = {units: <turf.Units>'kilometers',mask:sourceGeoJson};
    //let squareGrid = turf.squareGrid(bbox, 0.001, options);
    let squareGrid = turf.squareGrid(bbox, self.gridSize, options);

    turf.featureEach(squareGrid, function (currentFeature, featureIndex) {
      let coordinates = turf.center(currentFeature).geometry.coordinates;
      currentFeature['id'] = self.getUniqueId(coordinates[0],coordinates[1]);
    });

    if(self.map.getSource('gridSource'+featureId)){
      (<GeoJSONSource>self.map.getSource('gridSource'+featureId)).setData(squareGrid);
    }
    else{
      self.map.addSource('gridSource'+featureId, {
        type: "geojson",
        data: squareGrid
      });
    }

    self.map.addLayer({
      id: 'gridLayer'+featureId ,
      type: "fill",
      source: 'gridSource'+featureId,
      "minzoom": 10,
      "maxzoom": 23,
      layout: {},
      paint: {
          "fill-color":[
            "case",
            ["boolean", ["feature-state", "hover"], false],
            "transparent",//"#fff",
			      "transparent",
        ],
        "fill-opacity": [
            "case",
            ["boolean", ["feature-state", "hover"], false],
            0.5,
            0.3,
        ],

      }
    });

    self.map.addLayer({
        id: 'gridLayerBorder'+featureId ,
        type: "line",
        source: 'gridSource'+featureId,
        "minzoom": 10,
        "maxzoom": 23,
        'layout': {
            //"line-join": "round",
            //"line-cap": "round",
          },
          'paint': {
              "line-gap-width":0,
              'line-color': "#f00",//'#3cfcd9',
              //'line-width': 1,
              //'line-opacity': 1,
              'line-offset':0
          }
    });

    self.map.on('mousemove', 'gridLayer'+featureId, function (e) {
        self.createHoverGridLayer(e.features[0].id, squareGrid, sourceGeoJson);
        if (e.features.length > 0) {
            if (hoveredStateId !== null) {
                self.map.setFeatureState(
                    { source: 'gridSource'+featureId, id: hoveredStateId },
                    { hover: false }
                );
            }

            hoveredStateId = e.features[0].id;
            self.map.setFeatureState(
                { source: 'gridSource'+featureId, id: hoveredStateId },
                { hover: true }
            );
            console.log(hoveredStateId);
        }
        //self.map.moveLayer('gridLayer'+featureId);
    });

    /* self.map.on('click', 'gridLayer'+featureId, function (e) {
      let f = self.map.queryRenderedFeatures(e.point, { layers: ['userOwnerGridLayer'] });
      if (f.length==0) {
        if(e.features.length > 0){
          let _index = self.userSelectedGrid.features.findIndex(feature => {
            return feature.id == e.features[0].id;
          });

          if(_index !== -1){
            self.userSelectedGrid.features.splice(_index, 1);
            (<GeoJSONSource>self.map.getSource('userSelectedGridSource')).setData(self.userSelectedGrid);
          }
          else if(self.userSelectedGrid.features.length>=20){
            alert('Limit exceed. You can only buy 20 tiles.');
          }
          else{
            let _f = e.features[0];
            delete(_f.layer);
            delete(_f.source);
            delete(_f.state);
            self.userSelectedGrid.features.push(_f);
            (<GeoJSONSource>self.map.getSource('userSelectedGridSource')).setData(self.userSelectedGrid);
          }
          self.updateCalculation();
        }
      }
    }); */

  }

  createHoverGridLayer(featureId, squareGridGeoJson, sourceGeoJson){
		console.log(featureId);
		let self = this;
		let selectedFeature = squareGridGeoJson.features.filter((feature) => {
			//return (feature.id == featureId)
			if(feature.id == featureId){
				self.gridSize = 0.1;
				//console.log(geoJson, features);
				//let featureCollection = turf.featureCollection(feature);
				console.log(turf.bbox(feature));
				self.createGridLayer2(feature, sourceGeoJson, featureId);
			}
		});
  }
  
  createGridLayer2(geoJson, sourceGeoJson, featureId){
		let self = this;
		if(self.map.getLayer('gridLayerChild'+featureId)){
			return false;
		}
		
		let hoveredStateId = null;

		//let buffered = turf.buffer(geoJson, 0.01, {units: 'kilometers'});
		//let bbox = turf.bbox(buffered);
		let bbox = turf.bbox(geoJson);
    bbox = [Number(bbox[0].toFixed(15))-0.00940565621963474,Number(bbox[1].toFixed(15)),Number(bbox[2].toFixed(15))+0.00940565621963474,Number(bbox[3].toFixed(15))];
		//console.log(geoJson, sourceGeoJson);
		let options = {units: <turf.Units>'kilometers',mask:geoJson};
		
		if(turf.difference(geoJson, sourceGeoJson.features[0])){
			//let buffered = turf.buffer(geoJson, self.gridSize, {units: 'kilometers'});
			//bbox = turf.bbox(buffered);
			//bbox = turf.bbox(geoJson);	
			options = {units: <turf.Units>'kilometers',mask:sourceGeoJson};
		}


		//let bbox = turf.bbox(geoJson);
		//let squareGrid = turf.squareGrid(bbox, 0.001, options);
		let squareGrid = turf.squareGrid(bbox, self.gridSize, options);
	
		turf.featureEach(squareGrid, function (currentFeature, featureIndex) {
		  let coordinates = turf.center(currentFeature).geometry.coordinates;
		  currentFeature['id'] = self.getUniqueId(coordinates[0],coordinates[1]);
		});
	
		if(self.map.getSource('gridSourceChild'+featureId)){
		  (<GeoJSONSource>self.map.getSource('gridSourceChild'+featureId)).setData(squareGrid);
		}
		else{
		  self.map.addSource('gridSourceChild'+featureId, {
			type: "geojson",
			data: squareGrid
		  });
		}
	
		self.map.addLayer({
		  id: 'gridLayerChild'+featureId ,
		  type: "fill",
		  source: 'gridSourceChild'+featureId,
		  "minzoom": 10,
		  "maxzoom": 23,
		  layout: {},
		  paint: {
			  "fill-color":[
				"case",
				["boolean", ["feature-state", "hover"], false],
				"#fff",
				"transparent",
			],
			"fill-opacity": [
				"case",
				["boolean", ["feature-state", "hover"], false],
				0.5,
				0.3,
			],
	
		  }
		});
	
		self.map.addLayer({
			id: 'gridLayerBorderChild'+featureId ,
			type: "line",
			source: 'gridSourceChild'+featureId,
			"minzoom": 10,
			"maxzoom": 23,
			'layout': {
				//"line-join": "round",
				//"line-cap": "round",
			  },
			  'paint': {
				  "line-gap-width":0,
				  'line-color': '#3cfcd9',
				  //'line-width': 1,
				  //'line-opacity': 1,
				  'line-offset':5
			  }
		});
	
		self.map.on('mousemove', 'gridLayerChild'+featureId, function (e) {
			if (e.features.length > 0) {
				if (hoveredStateId !== null) {
					self.map.setFeatureState(
						{ source: 'gridSourceChild'+featureId, id: hoveredStateId },
						{ hover: false }
					);
				}
				
				hoveredStateId = e.features[0].id;
				self.map.setFeatureState(
					{ source: 'gridSourceChild'+featureId, id: hoveredStateId },
					{ hover: true }
				);
				console.log(hoveredStateId);
				//self.createHoverGridLayer(e.features, sourceGeoJson);
			}
      //self.map.moveLayer('gridLayer'+featureId);
      if(self.map.getLayer('userOwnerGridLayer')){
        self.map.moveLayer('userOwnerGridLayer');
      }
		});
	
		self.map.on('click', 'gridLayerChild'+featureId, function (e) {
		  let f = self.map.queryRenderedFeatures(e.point, { layers: ['userOwnerGridLayer'] });
		  if (f.length==0) {
        if(e.features.length > 0){
          let _index = self.userSelectedGrid.features.findIndex(feature => {
          return feature.id == e.features[0].id;
          });
    
          if(_index !== -1){
          self.userSelectedGrid.features.splice(_index, 1);
          (<GeoJSONSource>self.map.getSource('userSelectedGridSource')).setData(self.userSelectedGrid);
          }
          else if(self.userSelectedGrid.features.length>=20){
          alert('Limit exceed. You can only buy 20 tiles.');
          }
          else{
          let _f = e.features[0];
          delete(_f.layer);
          delete(_f.source);
          delete(_f.state);
          self.userSelectedGrid.features.push(_f);
          (<GeoJSONSource>self.map.getSource('userSelectedGridSource')).setData(self.userSelectedGrid);
          }
        }
		  }
		});
	
  }

  updateCalculation(){
    let self = this;
    self.selectedTile = self.userSelectedGrid.features.length;
    self.totalAmount = self.selectedTile*self.pricePerTile;
    if(self.userSelectedGrid.features && self.userSelectedGrid.features.length>0){
      self.location = turf.center(self.userSelectedGrid.features[0]);
      self.location = self.location.geometry.coordinates.join(",");
    }
    // self.cdRef.detectChanges();
  }

  createUserSelectedGridLayer(){
    let self = this;
    this.userSelectedGrid = this.userSelectedGrid ? this.userSelectedGrid : turf.featureCollection([]);
    if(!self.map.getLayer('userSelectedGridLayer')){
      self.map.addSource('userSelectedGridSource', {
        type: "geojson",
        data: this.userSelectedGrid
      });
  
      self.map.addLayer({
        id: 'userSelectedGridLayer',
        type: "fill",
        source: 'userSelectedGridSource',
        "minzoom": 10,
        "maxzoom": 23,
        layout: {},
        paint: {
          "fill-color":"#0f0"
        }
      });
      self.updateCalculation();
    }
    else{
      (<GeoJSONSource>self.map.getSource("userSelectedGridSource")).setData(this.userSelectedGrid);
    }

  }

  createUserOwnedGridLayer(projectId){
    let self = this;
    if(self.map.getLayer('userOwnerGridLayer')){
      return;
    }
    if(self.map.getLayer('userOwnedGridSource')){
      return;
    }
    this.loader.show();
    let featureCollection = [];
    this._publicApi.getUserOwnedProjectBlocks(projectId).subscribe(response => {
      this.loader.hide();
      console.log(response);
      response.transactions.forEach(transaction => {
        let _tiles = JSON.parse(transaction.orders.products);
        _tiles.features.forEach(feature => {
          feature.properties = {
            "ownerName":transaction.owner.email.match(/^([^@]*)@/)[1],
            "ownerImage":transaction.owner.email
          }
        });
        featureCollection = featureCollection.concat(_tiles.features);
        //self.loadAndSetTileImage(transaction.owner);
      });
      self.userOwnedGrid = turf.featureCollection(featureCollection);
      self.map.addSource('userOwnedGridSource', {
        type: "geojson",
        data: self.userOwnedGrid
      });

      self.map.addLayer({
        id: 'userOwnerGridLayer',
        type: "fill",
        source: 'userOwnedGridSource',
        "minzoom": 10,
        "maxzoom": 23,
        paint: {
			"fill-color":"#B7D1B4",
			"fill-outline-color":"#858A77",
		}
      });

	//   self.map.addLayer({
	// 	  id: 'userOwnerGridBorderLayer',
	// 	  type: "line",
	// 	  source: 'userOwnedGridSource',
	// 	  paint: {
	// 		"line-color": "#4F5C41",
	// 		"line-width": 1
	// 	  }
	//   });

      self.map.addLayer({
        id: 'userOwnerGridLayer2',
        type: "symbol",
        source: 'userOwnedGridSource',
        "minzoom": 10,
        "maxzoom": 23,
        layout: {
          'icon-image': ['get','ownerImage'],
          'icon-anchor': 'center',
          "icon-size": ['interpolate', ['linear'],
              ['zoom'], 18, 0.3, 19, 0.5, 20, 1, 21, 1
          ],
          'text-anchor': 'bottom',
          "icon-allow-overlap": true,
          "text-allow-overlap": true,
        }
      });

      /* self.map.on('click', 'userOwnerGridLayer', function (e) {
        e.preventDefault();
        alert('Aleady bought');
      }); */
    });
  }

  loadAndSetTileImage(owner){
    let self = this;
    owner.avatar = "../../../assets/img/brand/placeholder-profileimg.png";
    self.map.loadImage(owner.avatar, (error, image) => {
      if (error) throw error;
      if (!self.map.hasImage(owner.email)) {
        self.map.addImage(owner.email, image);
      }
    });
  }

  copyLink(inputElement){
		inputElement.select();
		document.execCommand('copy');
		inputElement.setSelectionRange(0, 0);
	}


	addProjectsToMap(eventData: { projectsData: any, location: boolean }) {
		let projects = eventData.projectsData;
		const location = eventData.location;
		this.projects = projects;
		let self = this;
		// self.clearAllLayers();
		let inerval = setInterval(function() {
			if(projects && self.map){
				clearInterval(inerval);
				let projectJson = {
					type: "geojson",
					data: {
						type: "FeatureCollection",
						features: []
					}
				};
				projects.forEach(project => {
					let center:any;
					if (project.projectGeojson) {
						let geojson = JSON.parse(project.projectGeojson);
						center = turf.centerOfMass(geojson);
					}else{
						center = turf.point(project.location.split(","));
					}
					
					let _html = self.getPopupHtml(project);
					center.properties = { title: project.projectName, description: _html, projectId : project.projectID, total_sites: project.total_sites};
					projectJson.data.features.push(center);
				});
				self.drawProjectsOnMap(projectJson, location);
			}
		}, 1000);
	}

  getPopupHtml(project) {
		let pSpecies = project.projectSpecies ? project.projectSpecies : project.allSpecies;
		let allSpecies = '<span class="bg-white badge badge-pill badge-primary"><img _ngcontent-cfq-c210="" src="../../../../assets/img/brand/leaf.png"> 2 </span>';
		if(pSpecies && pSpecies.length>0){
			allSpecies = '';
			pSpecies.forEach(species => {
				allSpecies += '<span class="bg-white badge badge-pill badge-primary"><img _ngcontent-cfq-c210="" src="../../../../assets/img/brand/leaf.png"> '+species.total+' </span>';
			});
		}
		return (
			'<div class="p-2 text-center rounded-circle">' +
			'<div class="row">' +
			'<div class="col-12"><h3 class="text-white">'+project.total_area+'ha</h3></div>' +
			'<div class="col-12">' + allSpecies +
			"</div>" +
			"</div>"
		);
	}

	drawProjectsOnMap(projectJson, location) {
		let self = this;
		self.clearAllLayers();
		if (this.map.getSource("projects")) {
		  (<GeoJSONSource>this.map.getSource("projects")).setData(projectJson.data);
		} else {
			
			// projectJson.data.features.forEach((feature, index) => {
			// 	feature.properties.number = index + 1;
			// });
		  
		  this.map.addSource("projects", projectJson);
		  this.map.addLayer({
			id: "projects",
			type: "circle",
			source: "projects",
			layout: {
			  visibility: "visible"
			},
			paint: {
				"circle-color": "#1D2859",
				"circle-radius": 10,
				"circle-stroke-width": 4, // Increased border width
				"circle-stroke-color": "#ffffff",
				"circle-stroke-opacity":0.6
			}
		  });
	  
		  this.map.addLayer({
			id: "points",
			type: "symbol",
			source: "projects",
			layout: {
				"text-allow-overlap": false,
				"text-field": ["to-string", ["get", "total_sites"]],
				"text-font": ["Open Sans Bold"],
				"text-size": 12,
				"text-offset": [0, 0],
				"text-anchor": "center"
			  },
			  paint: {
				"text-color": "#ffffff"
			}
		  });
	  
		  const popup = new Popup({
			closeButton: false,
			closeOnClick: false
		  });
	  
		  this.map.on("mouseenter", "projects", e => {
			// Change the cursor style as a UI indicator.
			self.map.getCanvas().style.cursor = "pointer";
	  
			// Copy coordinates array.
			let geometry: any = e.features[0].geometry;
			const coordinates = geometry.coordinates.slice();
			const description = e.features[0].properties.description;
	  
			// Ensure that if the map is zoomed out such that multiple
			// copies of the feature are visible, the popup appears
			// over the copy being pointed to.
			while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
			  coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
			}
	  
			// Populate the popup and set its coordinates
			// based on the feature found.
			popup
			  .setLngLat(coordinates)
			  .setHTML(description)
			  .addTo(self.map);
		  });
	  
		  self.map.on("mouseleave", "projects", () => {
			self.map.getCanvas().style.cursor = "";
			popup.remove();
		  });
	  
		  self.map.on("click", "projects", e => {
			self.zone.run(() => {
			  self.projectSelectedEventListener(e.features[0].properties.projectId);
			})
		  });
		}

		let bounds = new LngLatBounds();
		projectJson.data.features.forEach(function (feature) {
		  bounds.extend(feature.geometry.coordinates);
		});
	  
		// Adjust the padding value to leave some space around the bounds
		let padding = { top: 50, bottom: 50, left: 50, right: 50 };
		
		if (!bounds.isEmpty()) {
			// Fit the map to the bounds with the specified padding
			self.map.fitBounds(bounds, { padding: padding });

			// Calculate the center coordinates of the bounding box
			const centerCoordinates = bounds.getCenter();

			self.map.flyTo({
				center: location ? centerCoordinates : undefined,
				zoom: location ? 3 : 1
			});		
		} else {
			self.map.flyTo({
				zoom: 1
			});
		}
	}	

	drawSitesOnMap(project) {
		if(this.map.getLayer('sites')){
			this.map.removeLayer('sites');
			this.map.removeLayer('site-line');
			this.map.removeLayer('site-point');
			this.map.removeLayer('site-points');
			//this.map.removeLayer('site-text');
			this.map.removeSource('sites');
		}
		let siteJson = {
			type: "geojson",
			data: {
				type: "FeatureCollection",
				features: []
			}
		};

		//===== all sites ============/
		let allFeature = JSON.parse(project.geojson).features;
		project.sites.forEach(site => {
			allFeature = allFeature.concat(JSON.parse(site.geo_json).features);
		});
		var fc = turf.featureCollection(allFeature);
		let siteGeojson = JSON.stringify(fc);
		//===== all sites ============/
		let geoJson = JSON.parse(siteGeojson);


		/* project.sites.forEach(site => {
			let center:any;
			if (site.geo_json) {
				let geojson = JSON.parse(site.geo_json);
				center = turf.centerOfMass(geojson);
			}
			
			center.properties = { title: site.name};
			siteJson.data.features.push(center);
		}); */

		this.map.addSource("sites", <AnySourceData>(geoJson));
		  /* this.map.addLayer({
  			id: "sites",
  			type: "circle",
  			source: "sites",
  			layout: {
  			  visibility: "visible"
  			},
  			paint: {
  			  "circle-color": "#f00",
  			  "circle-radius": 10,
  			  "circle-stroke-width": 2,
  			  "circle-stroke-color": "#ffffff"
  			}
		  });

		  this.map.addLayer({
  			id: "site-text",
  			type: "symbol",
  			source: "sites",
  			layout: {
  			  "text-allow-overlap": true,
  			  "text-field": ["get", "title"],
  			  "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
  			  "text-offset": [0, 1],
  			  "text-anchor": "top"
  			},
  			paint: {
  			  "text-color": "#ffffff"
  			}
		  });
		  this.map.addLayer({
			'id': 'sites-fill',
			'type': 'fill',
			'source': 'sites',
			'layout': {},
			'paint': {
				"fill-outline-color":"#f56036"
			}
		}); */


		this.map.addLayer({
			'id': 'sites',
			'type': 'fill',
			'source': 'sites',
			'layout': {},
			'paint': {
				"fill-outline-color":"#f59d24",
				"fill-color": "Transparent",
				"fill-opacity": [
					"case",
					["boolean", ["feature-state", "hover"], false],
					1,
					0.5,
				],
			}
		});

		this.map.addLayer({
			'id': 'site-line',
			'type': 'line',
			'source': 'sites',
			'layout': {},
			'paint': {
				'line-color': '#f59d24',
				'line-width': 2
			}
		});

		this.map.addLayer({
			'id': 'site-point',
			'type': 'circle',
			'source': 'sites',
			'paint': {
				'circle-radius': 6,
				'circle-color': '#1D2859'
			},
			'filter': ['==', '$type', 'Point']
		});

		this.map.addLayer({
			id: "site-points",
			type: "symbol",
			source: "sites",	
			layout: {
			  "text-allow-overlap": false,
			  "text-field": ["get", "area"],
			  "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
			  "text-offset": [0, 1],
			  "text-anchor": "top"
			},
			paint: {
			  "text-color": "#ffffff"
			}
		});




	}

	openModal(autoSelectModal: any) {
		this.modalRef = this.modalService.open(autoSelectModal, { centered: true });
	}
	
	submitForm() {
		// const numberOfTilesControl = this.autoSelectForm.get('numberOfTiles');
		// numberOfTilesControl.markAsTouched();
	
		if (this.autoSelectForm.valid) {

		  this.grid2.isMultipleSelect = true;
		  
		  if (this.modalRef) {
			this.modalRef.close('Form submitted');
		  }
	  
		  // Clear the form
		  this.autoSelectForm.reset();
		} else {
		this.toast.error("Please enter number of tiles.", "Error!", {
			closeButton: true,
			timeOut: 3000
			});
		}
	}

	// submitForm() {
	// 	const numberOfTilesControl = this.autoSelectForm.get('numberOfTiles');
	// 	numberOfTilesControl.markAsTouched();
	
	// 	if (this.autoSelectForm.valid) {
	// 	  const numberOfTilesValue = numberOfTilesControl.value;

	// 	  this.grid2.simulateMapClicks(numberOfTilesValue);

	// 	  console.log('Number of Tiles:', numberOfTilesValue);
	// 	  if (this.modalRef) {
	// 		this.modalRef.close('Form submitted');
	// 	  }
	  
	// 	  // Clear the form
	// 	  this.autoSelectForm.reset();
	// 	} else {
	// 	this.toast.error("Please enter number of tiles.", "Error!", {
	// 		closeButton: true,
	// 		timeOut: 3000
	// 		});
	// 	}
	// }

	refreshPage() {
		window.location.reload();
		// this.projectSelectedEventListener(this.projectDetailsComponentData.projectObject.uid);
	}

	clearTileSelection () {
		this.zone.run(() => {
		this.pages_state.isBuyingNewForm2Visible = false;
		this.pages_state.isBuyingNewForm1Visible = true;
		this.loadGeoJson(this.project);	

		});
	}

	showMoreInformation() {
		this.zone.run(() => {
			this.buyHabitateComponentData.isShowBuySidebar = true;
			this.gotoBuyHabitat();
		});
	}

	gotoSite(geojson){
		let json = JSON.parse(geojson);
		this.gotoLocation(json);
	}

	updateMapStyle(event) {
		let self = this;
		this.zone.run(()=>{
			self.map.setStyle('mapbox://styles/mapbox/' + event.target.value);
			if(this.pages_state.isProjectDetailsVisible){
				self.loadGeoJson(self.project);
			}else{
				//self.addProjectsToMap(self.projects);
				self.addProjectsToMap({ projectsData: self.projects, location: false })
			}
		})
	}
}
