import { Component, OnInit, Input } from '@angular/core';

type species = {
	specie_name:string,
	specie_image_path:string
}

type species_dataType = {
	species_catagory_name:string,
	species_catagory_image_path:string,
	available_species_data_lenght:number,
	available_species_data:species[]
}


@Component({
  selector: 'app-biodiversity-species',
  templateUrl: './biodiversity-species.component.html',
  styleUrls: ['./biodiversity-species.component.scss']
})
export class BiodiversitySpeciesComponent implements OnInit {

	@Input() species_data:species_dataType[]
  
	constructor() { }

  
	ngOnInit(): void {
	console.log(this.species_data);
  }

}
