import { Component, OnInit, Input } from "@angular/core";
import { PublicService } from "src/app/api/public.service";

type component_data_type = {
  projectID: string;
  goalLandcover: string;
  totalArea: number;
  polygon: {};
};

@Component({
  selector: "app-project-landcover",
  templateUrl: "./project-landcover.component.html",
  styleUrls: ["./project-landcover.component.scss"],
})
export class ProjectLandcoverComponent implements OnInit {
  @Input() component_data: component_data_type;

  area: number;
  bareland: number;
  vegetation: number;
  percentageWater: number;
  percentageVegetation: number;
  percentageBareland: number;
  activeYear: string;

  constructor(private _publicApi: PublicService) {
    this.activeYear = "2023";
  }

  ngOnInit(): void {
    this.checkProjectExistence(this.activeYear);
    this.area = 0;
    this.bareland = 0;
    this.vegetation = 0;
    this.percentageWater = 0;
    this.percentageBareland = 0;
    this.percentageVegetation = 0;
  }

  // checkProjectExistence(year): void {
  //   const customId = this.component_data.projectID;
  //   this._publicApi.getProjectByCustomId(customId, 2022).subscribe(
  //     (response) => {
  //       const project = response.data;
  //       console.log("check", response.data);
  //       if (project && project[0]?.status === "COMPLETED") {
  //         this.assignProjectData(project);
  //       } else {
  //         this.createProjectLandCover();
  //       }
  //     },
  //     (error) => {
  //       console.error("Error while checking:", error);
  //     }
  //   );
  // }

  checkProjectExistence(year): void {
    const customId = this.component_data.projectID;
    this._publicApi.getProjectByYear(customId, year).subscribe(
      (response) => {
        const project = response.data;
        console.log("check", response.data);
        if (project && project.length > 0) {
          this.assignProjectData(project);
        } else {
          this.createProjectLandCover(year);
        }
      },
      (error) => {
        console.error("Error while checking:", error);
      }
    );
  }

  //   checkProjectExistence(year: number) {
  //     // Example: Fetch data based on the selected year and update the values
  //     if (year === 2021) {
  //         this.vegetation = 40; // Update with your actual data
  //         this.percentageWater = 20; // Update with your actual data
  //         this.bareland = 40; // Update with your actual data
  //     } else if (year === 2022) {
  //         this.vegetation = 45; // Update with your actual data
  //         this.percentageWater = 25; // Update with your actual data
  //         this.bareland = 30; // Update with your actual data
  //     } else if (year === 2026) {
  //         // Handle 2026 data
  //         this.percentageWater = 26;
  //         this.bareland = 260;
  //     }
  // }

  createProjectLandCover(year): void {
    const requestData = {
      custom_project_id: this.component_data.projectID,
      year: year,
      polygon: {
        type: "Polygon",
        coordinates: this.component_data.polygon,
      },
    };
    console.log("create", requestData);
    this._publicApi.createProjectLandCoverDetails(requestData).subscribe(
      (response) => {
        console.log("create", response);
        const project = response.data;
        if (project && project.length > 0) {
          this.assignProjectData(project);
          // } else if (project) {
          //   this.listenProjectCompletion(project._id);
        } else {
          console.log("Downloading satellite data...");
        }
      },
      (error) => {
        console.error("Error while creating:", error);
      }
    );
  }

  // listenProjectCompletion(projectId: string): void {
  //   const intervalId = setInterval(() => {
  //     this._publicApi.getProjectByCustomId(projectId).subscribe(
  //       (response) => {
  //         const project = response.data[0];
  //         if (project && project.status === "COMPLETED") {
  //           this.assignProjectData(project);
  //           clearInterval(intervalId);
  //         }
  //       },
  //       (error) => {
  //         console.error("Error while listening:", error);
  //         clearInterval(intervalId);
  //       }
  //     );
  //   }, 60000); // check every 1 minute
  // }

  assignProjectData(projectList: any[]): void {
    let totalArea = 0;
    let totalBareland = 0;
    let totalVegetation = 0;
    let totalWater = 0;
    projectList.forEach((project) => {
      totalArea += project.area;
      totalBareland += project.bareland;
      totalVegetation += project.vegetation;
      totalWater += project.water;
    });

    const numProjects = projectList.length;

    this.area = totalArea;
    this.percentageBareland = Number((totalBareland / numProjects).toFixed(1));
    this.percentageVegetation = Number(
      (totalVegetation / numProjects).toFixed(1)
    );
    this.percentageWater = Number((totalWater / numProjects).toFixed(1));

    this.bareland = Number(
      ((this.percentageBareland / 100) * this.area * 100).toFixed(1)
    );
    this.vegetation = Number(
      ((this.percentageVegetation / 100) * this.area * 100).toFixed(1)
    );
  }

  // get goal year landcover data
  getLandcoverGoal() {
    // Assuming this.component_data.landcoverData is a string in JSON format
    const landcoverGoalData = JSON.parse(this.component_data.goalLandcover);

    // Extract values from the parsed JSON object
    const { year, water, bareland, tree_cover } = landcoverGoalData;

    this.area = this.component_data.totalArea;

    // Convert values to numbers (assuming they are originally in string format)
    const waterPercentage = parseFloat(water);
    const barelandPercentage = parseFloat(bareland);
    const treeCoverPercentage = parseFloat(tree_cover);

    // Calculate percentages
    this.percentageWater = waterPercentage;
    this.percentageBareland = barelandPercentage;
    this.percentageVegetation = treeCoverPercentage;

    this.bareland = parseFloat(((barelandPercentage / 100) * this.area).toFixed(1));
    this.vegetation = parseFloat(((treeCoverPercentage / 100) * this.area).toFixed(1));
  }
}
