import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { PublicService } from 'src/app/api/public.service';

@Component({
  selector: 'app-adminprojectdetails',
  templateUrl: './adminprojectdetails.component.html',
  styleUrls: ['./adminprojectdetails.component.scss']
})
export class AdminprojectdetailsComponent implements OnInit {

	@Input() projectId_data: number;
  @Input("projectDetail") projectDetail;
	@Input() project:any;
  
	@Output() back_button_event = new EventEmitter();
	@Output() leaderboard_btn_event = new EventEmitter();
	view_goals = false;
	investment_goals_component_data = {};
	myconservation_component_data = {};
	landcover_component_data = {};
	top_habitatowner_component_data = {};
  project_data = {
    activities_ids: ["c070dcec-f5e5-11eb-9bcb-0021ccca0e55","26ff8b2a-f5e6-11eb-8582-0021ccca0e55"],
    city: "110878",
    country: "229",
    location : "Location",
    description: "<p>The project has already planted around 1.3 million indigenous trees over the past four years, of which 75% have grown successfully, across the 25km2 project site. This planting has taken place in the places where it is most urgently needed for the Chimpanzee population.&nbsp;</p>",
    establish_date: "2022-01-29",
    gallery: [],
    flag : "",
    geojson:"",
    header_image: "projects/ec426686-80b6-11ec-8b30-0a492dd11e42/3m9QfToZ6tlcXmaj5QvThSQto6HrCRJmLjvsYaKw.jpg",
    name: "Uganda Reforestation",
    biome : "Forest",
    region: "372",
    status: "Active",
    summary: "The project has already planted around 1.3 million indigenous trees over the past four years, of which 75% have grown successfully, across the 25km2 project site. This planting has taken place in the places where it is most urgently needed for the Chimpanzee population.",
    allSpecies: "",
    allTransactions: "",
    total_tile : 0,
    total_area : 0,
    total_revenue: 0,
    total_revenue_current_month: 0,
    total_tiles_bought: 0,
    total_tiles_bought_current_month: 0,
    total_sites: 0,
  }
  /* -------------------------------------------------------------------------- */
  /* ------------------------ Project thumbnil Control ------------------------ */
  
 project_thumb = this.project_data.gallery[0];
 current_index = 0;
  projects: any;
  allSpecies: any;
  user: any;
  total: any;
 /* -------------------------------------------------------------------------- */

  constructor(public _publicApi : PublicService,
    public loader : NgxSpinnerService) { 

  }

  ngOnInit(): void {
    this.top_habitatowner_component_data = {"projectId" : this.projectDetail.uid};
    this.loader.show();
    this._publicApi.getProject(this.projectDetail.uid).subscribe(response => {
      this.loader.hide();
      this.user = JSON.parse(localStorage.getItem('user'));
      console.log(response.project);
      this.projects = response.project;
      this.allSpecies = response.project.AllSpecies;
      this.project_data = {
        "activities_ids": this._publicApi.getActivityName(this.projects.activities),// "c070dcec-f5e5-11eb-9bcb-0021ccca0e55,26ff8b2a-f5e6-11eb-8582-0021ccca0e55",
        "city": "",
        "location" : this._publicApi.getProjectLocation(this.projects),
        "country": this.projects.country_name ? this.projects.country_name.name : "",
				"flag":  this._publicApi.getCountryFlagImage(this.projects.country_name),
        "description": this.projects.description,
        "establish_date": this.projects.establish_date,
        "gallery": this._publicApi.getProjectGallery(this.projects.gallery),
        "header_image": this._publicApi.getProjectImage(this.projects),
        "name": this.projects.name,
        "region": this.projects.province_name ? this.projects.province_name.name : "",
        "status": "Active",
        "summary": response.project.summary,
        "allSpecies": this.projects.allSpecies,
        "biome" : this.projects.biome,
				"geojson" : this.projects.geojson,
        "allTransactions": this.projects.allTransactions,
        "total_tile" : Number(this.projects.total_tile),
        "total_area" : Number(this.projects.total_area),
        "total_revenue": Number(this.projects.totalRevenue),
        "total_revenue_current_month": Number(this.projects.totalRevenueCurrentMonth),
        "total_tiles_bought":Number(this.projects.totalTilesBought),
        "total_tiles_bought_current_month": Number(this.projects.totalTilesBoughtCurrentMonth),
        "total_sites": Number(this.project.total_sites),
      }
    });
  }
  change_image(index,call_method){

    /* FIXME----------------- automatice thumbnil change not enable yet ---------------- */
    if(call_method == 'interval' ){
      if(index == this.project_data.gallery.length-1){
        this.current_index = 0;
      }
      else(
        this.current_index = index +1
      )
    }
    /* ---------------------------------- on-click thumbnil change enabled ---------------------------------- */
    if(call_method == 'click'){
        this.current_index = index;
      }
      this.project_thumb = this.project_data.gallery[this.current_index];

  }
/* -------------------------------------------------------------------------- */
  changeVisibility(){
    
    this.back_button_event.emit(); 
  }

	view_goal(){
		this.view_goals = !this.view_goals;
	}
	tophabitate_component_event_listener(event){
		this.leaderboard_btn_event.emit();

	}

  getSpeciesKey(){
    return Object.keys(this.project_data.allSpecies);
  }

  getTotalAreaInHa(area){
    area = area/10000;
    return Math.round(area * 100) / 100;
  }

  convertArea(areaInHactare){
    if(areaInHactare){
      areaInHactare = areaInHactare/100;
      return (Math.round(areaInHactare * 100) / 100);
    }
    return 0;
  }
  getTotalBought(projects){
    if(projects.total_tiles_bought){
      return projects.total_tiles_bought;
    }
    return 0;
  }

  getBoughtPercentage(projects){
    if(projects.total_area){
      return Math.round(100*(100*(this.getTotalBought(projects)/projects.total_area)));
    }
    return 0;
  }

  gettotalTileBoughtBy(projects){
    if(projects){
      this.total= 0;
      for (let transactions of projects.allTransactions) {
        if(this.user.id == transactions.user_id ) {
            this.total=transactions.quantity + this.total;
        }
      }
      return this.total;
    }
    return 0;
  }

  getinvestmentPercentage(projects){
    if(projects){
      return Math.round((this.gettotalTileBoughtBy(projects)/projects.totalTilesBought)*100);
    }
    return 0;
  }
  
}
