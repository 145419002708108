import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PublicService } from 'src/app/api/public.service';
import { NgxSpinnerService } from 'ngx-spinner';

type component_data_type = {
	
}

@Component({
  selector: 'app-leaderboard-panal',
  templateUrl: './leaderboard-panal.component.html',
  styleUrls: ['./leaderboard-panal.component.scss']
})
export class LeaderboardPanalComponent implements OnInit {

	@Input() component_data:component_data_type
	@Output() back_button_event = new EventEmitter


	leaderboard_players_data = []

	leaderboardComponentData = {
		leaderBorad_Title:'Leaderboard',
		leftPerson:{
			landSize:'200',
			personImage_Path:'../../../../assets/img/brand/placeholder-profileimg.png',
			leaderboard_place:'4th',
			landCoveringSize:'50'
		},
		centerPerson:{
			landSize:'200',
			personImage_Path:'../../../../assets/img/brand/placeholder-profileimg.png',
			leaderboard_place:'3rd',
			landCoveringSize:'50'
		},
		rightPerson:{
			landSize:'200',
			personImage_Path:'../../../../assets/img/brand/placeholder-profileimg.png',
			leaderboard_place:'5th',
			landCoveringSize:'50'
		}
	}

	constructor(public _publicService : PublicService, public loader : NgxSpinnerService) { }

	ngOnInit(): void {
		this.getLeaders();
	}
  
	back_button_clicked(){
		this.back_button_event.emit()
	}

	search(event){
		let searchValue = event.target.value;
		if(searchValue.length>1){
			this.loader.show();	
			this.getLeaders(searchValue);
		} else {
			this.getLeaders();
		}
	}

	getLeaders(searchValue?){
		let searchString = "&limit=10";
		if(searchValue){		
			searchString += "&search="+searchValue;
		}

		this._publicService.getProjectLeaders(searchString).subscribe((response:any) => {
			this.loader.hide();
			this.leaderboard_players_data = [];
			if (response.success) {
				console.log(response.leaders);
				let result = response.leaders;

				if(!searchValue){
					result = response.leaders.splice(4);
				}

				result.forEach((leader, index) => {
					this.leaderboard_players_data.push({
						organization_logo_image_path: this._publicService.getUserImage(leader.avatar),
						organization_name:leader.firstname,
						organization_place:this.ordinal_suffix_of(index+4),
						organization_total_trees:(leader.totalLand*4).toString(),
						organization_conservation_area: this._publicService.getTotalBought({"total_tiles_bought":leader.totalLand})+"ha",
						organization_total_land_area: this.hectaresToSquareFeet(leader.totalLand),
						is_this_your_organization:false,
					});
				});
				let topLeader = response.leaders.splice(1,3);
				if(topLeader && topLeader.length>0){
					this.leaderboardComponentData.centerPerson = {
						landSize:this._publicService.getTotalBought({"total_tiles_bought":topLeader[0].totalLand})+"ha",
						personImage_Path:this._publicService.getUserImage(topLeader[0].avatar),
						leaderboard_place:'1st',
						landCoveringSize:(topLeader[0].totalLand*4).toString(),
					}
					if(topLeader[1]){
						this.leaderboardComponentData.leftPerson = {
							landSize:this._publicService.getTotalBought({"total_tiles_bought":topLeader[1].totalLand})+"ha",
							personImage_Path:this._publicService.getUserImage(topLeader[1].avatar),
							leaderboard_place:'2nd',
							landCoveringSize:(topLeader[1].totalLand*4).toString(),
						}
					}
					if(topLeader[2]){
						this.leaderboardComponentData.rightPerson = {
							landSize:this._publicService.getTotalBought({"total_tiles_bought":topLeader[2].totalLand})+"ha",
							personImage_Path:this._publicService.getUserImage(topLeader[2].avatar),
							leaderboard_place:'3rd',
							landCoveringSize:(topLeader[2].totalLand*4).toString(),
						}
					}
				}
			}
		})
	}

	ordinal_suffix_of(i) {
		var j = i % 10,
			k = i % 100;
		if (j == 1 && k != 11) {
			return i + "st";
		}
		if (j == 2 && k != 12) {
			return i + "nd";
		}
		if (j == 3 && k != 13) {
			return i + "rd";
		}
		return i + "th";
	}

	hectaresToSquareFeet(hectares) {
		const squareFeetPerHectare = 107639;
		const squareFeet = hectares * squareFeetPerHectare;
		return squareFeet;
	}
}
