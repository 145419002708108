import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor() { }

  static getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
      let config = {
          'required': 'Required',
          'invalidCreditCard': 'Is invalid credit card number',
          'invalidEmailAddress': 'Invalid email address',
          'invalidMobileFormat' : 'Invalid mobile number format. Should be like +1 8087339090, +91 8087339090, +912 8087339090, +1-8087339090, +91-8087339090',
          'invalidFileExtension' : `Invalid file extension. Allowed types are ${validatorValue}`,
          'invalidPostCode':'Postcode must be all uppercase and include a space i.e. CW2 5PR, AB10 1YR.',
          'invalidPassword': 'Invalid password. Password must be at least 8 characters long, and contain a number.',
          'invalidConfirmPassword' : 'Confirm password value does not match to Password',
          'invalidUrl' : 'Invalid URL',
          'minlength': `Minimum length ${validatorValue.requiredLength}`,
          'invalidMaxQty':`Max Qty ${validatorValue} exceeded`,
          'invalidMinQty':`Min Qty ${validatorValue} required`,
          'invalidFixLength' : `Length should be equal to ${validatorValue}`,
          'invalidVariableLength':`Length should be between ${validatorValue[0]} to ${validatorValue[1]} character`,
          'invalidBetweenValue' : `Invalid value. Should be between ${validatorValue[0]} to ${validatorValue[1]}`
      };

      return config[validatorName];
  }

  static creditCardValidator(control) {
      // Visa, MasterCard, American Express, Diners Club, Discover, JCB
      if (control.value.match(/^(?:4[0-9]{12}(?:[0-9]{3})?|5[1-5][0-9]{14}|6(?:011|5[0-9][0-9])[0-9]{12}|3[47][0-9]{13}|3(?:0[0-5]|[68][0-9])[0-9]{11}|(?:2131|1800|35\d{3})\d{11})$/)) {
          return null;
      } else {
          return { 'invalidCreditCard': true };
      }
  }

  static mobileValidator(control) {
    if(control.value){
        if (control.value.match(/^(?!0+$)(\+\d{1,3}[- ]?)?(?!0+$)\d{10}$/)
            || control.value.match(/^(\+\d{1,3}[- ]?)?\d{10}$/)) {
            return null;
        } else {
            return { 'invalidMobileFormat': true };
        }
    }
    return { 'invalidMobileFormat': true };
}

  static emailValidator(control) {
      if(!control.value || control.value == ""){
          return null;
      }
      // RFC 2822 compliant regex
      if (control.value.match(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/)) {
          return null;
      } else {
          return { 'invalidEmailAddress': true };
      }
  }

  static postCodeValidator(control) {
      if(control.value == ""){
          return null;
      }
      // RFC 2822 compliant regex
      if (control.value.match(/([A-PR-UWYZ0-9][A-HK-Y0-9][AEHMNPRTVXY0-9]?[ABEHMNPRVWXY0-9]? {1,2}[0-9][ABD-HJLN-UW-Z]{2}|GIR 0AA)/)) {
          return null;
      } else {
          return { 'invalidPostCode': true };
      }
  }

  static passwordValidator(control) {
      // {6,100}           - Assert password is between 6 and 100 characters
      // (?=.*[0-9])       - Assert a string has at least one number
      if (control.value.match(/^(?=.*[0-9])[a-zA-Z0-9!@#$%^&*]{8,100}$/)) {
          return null;
      } else {
          return { 'invalidPassword': true };
      }
  }


  static urlValidator(control) {
        if (control.value == "" || control.value.match(/^(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})$/)) {
            return null;
        } else {
            return { 'invalidUrl': true };
        }
    }

  static confirmPasswordValidator(otherControlName) {
      return (control: FormControl) => {
          if(typeof control.parent != 'undefined'){
              let otherControl = control.parent.controls[otherControlName] as FormControl;
              return (control.value == otherControl.value) ? null : { 'invalidConfirmPassword': true }
          }
      }
  }

  static dependancyValidator(otherControlName, compareValue) {
      return (control: FormControl) => {
          if(typeof control.parent != 'undefined'){
              let otherControl = control.parent.controls[otherControlName] as FormControl;
              return (compareValue == otherControl.value && control.value.trim() == "") ? { 'required': true } : null;

              /*if(compareValue == otherControl.value){
                if(control.value.trim() == ""){
                    return { 'required': true };
                }
                return null;
              }
              return null;*/
          }
      }
  }

  static fixedLengthValidator(val){
      return (control: FormControl) => {
          if(control.value == "" || control.value == null){
              return null;
          }
          return control.value.length == val ? null : { 'invalidFixLength': val }
      }
  }

  static variableLengthValidator(minLength,maxLength){
      return (control: FormControl) => {
          if(control.value == "" || control.value == null){
              return null;
          }
          return control.value.length >= minLength && control.value.length <= maxLength? null : { 'invalidVariableLength': [minLength,maxLength] }
      }
  }

  static maxQtyValidator(max){
      return (control: FormControl) => {
          return (parseInt(control.value) <= max) ? null : { 'invalidMaxQty': max }
      }
  }

  static minQtyValidator(min){
      return (control: FormControl) => {
          return (parseInt(control.value) >= min) ? null : { 'invalidMinQty': min }
      }
  }

  static betweenValue(min, max){
        return (control: FormControl) => {
            let _val = parseInt(control.value);
            return (_val >= min && _val <= max) ? null : { 'invalidBetweenValue': [min,max] }
        }
    }

  static fileExtension(extensions){
        return (control: FormControl) => {
            if(control.value == ""){
                return null;
            }
            var fileExt = control.value.split('.').pop();
            return (extensions.includes(fileExt)) ? null : { 'invalidFileExtension': extensions.join(",") }
        }
    }
}
