import { Component, OnInit,Input,Output,EventEmitter } from '@angular/core';
import { PublicService } from 'src/app/api/public.service';

@Component({
  selector: 'app-project-tophabitat-owner',
  templateUrl: './project-tophabitat-owner.component.html',
  styleUrls: ['./project-tophabitat-owner.component.scss']
})
export class ProjectTophabitatOwnerComponent implements OnInit {
	@Input() component_data;
  @Output() leaderboard_btn_event = new EventEmitter;
  leaders:any;

  constructor(public _publicService : PublicService) { }

  ngOnInit(): void {
    console.log(this.component_data);
    this.getTopHabitatOwner(this.component_data.projectId);
  }

  leaderboard_button_event(){
	  this.leaderboard_btn_event.emit();
  }

  getTopHabitatOwner(projectId){
    this._publicService.getProjectLeaders("&projectId="+projectId).subscribe((response:any) => {
      if (response.success) {
        this.leaders = response.leaders;
      }
    });
  }

  getAreaBought(totalTiles){
    return (totalTiles);
  }
}
