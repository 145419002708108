import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PublicService } from 'src/app/api/public.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-account-verification',
  templateUrl: './account-verification.component.html',
  styleUrls: ['./account-verification.component.css']
})
export class AccountVerificationComponent implements OnInit {
  verificationCode:any;
  email : any;
  verified : any;

  constructor(private route: ActivatedRoute, private _publicApi : PublicService,
    private router : Router, private loader : NgxSpinnerService) { 
    this.route.queryParams.subscribe(params => {
        this.verificationCode = params.verification_code;
        this.email = params.email;
        this.checkValidity();
      }
    );
  }

  ngOnInit(): void {
  }

  checkValidity(){
    this.loader.show();
    if(this.verificationCode && this.email){
      let data = {"email": this.email, secret_code : this.verificationCode};
      this._publicApi.verifyEmail(data).then((response:any) => {
        console.log(response);
        this.loader.hide();
        this.verified = true;
      }).catch(err =>{
        this.verified = false;
        this.loader.hide();
      })
    }
  }

  login(){
    this.router.navigate(['/login']);
  }
}
