import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PublicService } from 'src/app/api/public.service';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { ValidationService } from 'src/app/api/validation.service';
import { Router } from '@angular/router';
import { SocialAuthService, GoogleLoginProvider } from 'angularx-social-login';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  signupForm: FormGroup;
  user:any;
  userType:any;
  userEmail = "";
  userRoles:any;
  registrationDetails:any;
  showRoles = false;
  show_button: Boolean = false;
  show_eye: Boolean = false;
  show_pass: Boolean = false;
  show_eye_pass: Boolean = false;
  socialResponse:any;
  profile : any;
  stateData:any;
  //_state:any;

  constructor(private _formBuilder: FormBuilder, private _publicApi : PublicService, private toast : ToastrService,
    private loader : NgxSpinnerService, private router : Router, private authService: SocialAuthService) {
      let _state = router.getCurrentNavigation().extras.state;
      if(_state && _state.showRoles){
        this.showRoles = true;
      } 
  }

  ngOnInit() {
    if(localStorage.getItem("invited") == "true"){
      this.userEmail = localStorage.getItem("invitedEmail");
      this.userType = "colleague";
    }
    else if(localStorage.getItem("selectedTiles")){
      console.log(localStorage.getItem("selectedTiles"));
    }
    else if(!this.showRoles){
      localStorage.clear();
    }
    this.initializeForm();
    this.getUserTypes();
  }

  ngOnDestroy() {
  }

  getUserTypes(){
    this._publicApi.getUserTypes().subscribe((response:any) => {
      this.userRoles = response.roles;
      
      // if(this._state && this._state.showRoles){
      //   //this.showRoles = true;
      //   this.userType = 'user';
      //   this.continueProfile();
      // }
    });
  }

  changeUserType(type){
    this.userType = type;
    //this.continueProfile();
  }

  initializeForm() {
    this.signupForm = this._formBuilder.group({
      //userType: [this.userType, [Validators.required]],
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: [this.userEmail, [Validators.required, ValidationService.emailValidator]],
     // cellphone: ['', [Validators.required, ValidationService.mobileValidator]],
      password: ['', [Validators.required, ValidationService.passwordValidator]],
      confirmPassword: ['', [Validators.required, , ValidationService.confirmPasswordValidator('password')]]
    });
    this.signupForm = new FormGroup(this.signupForm.controls, { updateOn: 'blur' });
  }

  showPassword() {
    this.show_button = !this.show_button;
    this.show_eye = !this.show_eye;
  }
  showCPassword() {
    this.show_pass = !this.show_pass;
    this.show_eye_pass = !this.show_eye_pass;
  }
  async signup(){
    this.validate();
    if(this.signupForm.valid){
      this.registrationDetails = {
       "firstname" : this.signupForm.value.firstName,
       //"firstname" : this.signupForm.value.email.split('@')[0],
        "lastname" : this.signupForm.value.lastName,
        "email" : this.signupForm.value.email,
        //"cellphone" : this.signupForm.value.cellphone,
        "password" : this.signupForm.value.password,
        "c_password" : this.signupForm.value.confirmPassword,
        "appname":"habitatmarket"
      };
      //this.showRoles = true;
      this.userType = this.userType ? this.userType : "singup";
      this.continueRegistration();
    }
    else{
      this.toast.error('Please fill all the details.', 'Error!', { closeButton : true, timeOut: 5000 });
    }
  }

  getUserType(userType){
    let role = this.userRoles.filter((role)=>{
      if((userType == "corporate" || userType == "colleague") && role.name == "user"){
        return true; 
      }
      else{
        return role.name == userType;
      }
    });
    if(role && role.length>0){
      return role[0].id;
    }
    return 0;
  }

  validate(){
    for(let prop in this.signupForm.controls){
      this.signupForm.controls[prop].markAsTouched();
    }
  }

  continueRegistration(){
    if(this.userType){
      this.loader.show();
      this.registrationDetails['role'] = this.getUserType(this.userType);
      if(this.userType == 'corporate' || this.userType == 'colleague'){
        this.registrationDetails['user_type'] = this.userType;
      }
      this._publicApi.customRegister(this.registrationDetails).then((response:any) => {
        this.loader.hide();
        if(response.success){
          //this.toast.success("A verification email has been sent to you. Please verify your account", 'Great!', { closeButton : true, timeOut: 5000 }); 
          //this.router.navigate(['/login'])
          this.router.navigate(['/thank-you'],{ state : { "name" : this.registrationDetails.firstname} })
        }
      }).catch( err => {
        console.log(err);
        this.loader.hide();
        this.toast.error(err.error.error, 'Error!', { closeButton : true, timeOut: 5000 });  
      });
    }
    else{
      this.toast.error('Please select a user type', 'Error!', { closeButton : true, timeOut: 5000 });  
    }
  }

  continueProfile(){
    let data = {};
    data['category'] = this.getUserType(this.userType);
    if(this.userType == 'corporate'){
      data['type'] = 'corporate';
    }
    this._publicApi.customRegister2(data).then((response:any) => {
      this.loader.hide();
      if(response.success){
        //this.toast.success("A verification email has been sent to you. Please verify your account", 'Great!', { closeButton : true, timeOut: 5000 }); 
        //this.router.navigate(['/login'])
        this.user = response.user;
        localStorage.setItem('user',JSON.stringify(response.user));
        this.gotoDashboard();
      }
    }).catch( err => {
      console.log(err);
      this.loader.hide();
      this.toast.error(err.error.error, 'Error!', { closeButton : true, timeOut: 5000 });  
    });
  }

  back(){
    this.userType=undefined;
    this.showRoles=false;
  }

  /* gotoDashboard(){
    let roles = this.userRoles.filter(role => {
      return role.id == this.user.role_id;
    });
    if(roles && roles.length > 0){
      let role = roles[0];
      if(role.name == PublicService.userRoles.INDIVIDUAL){
        if(this.user && this.user.user_type == 'corporate'){
          this.router.navigate(['register-corporate']);
        }
        else{
          this.router.navigate(['individual']);
        }
      }
      else if(role.name == PublicService.userRoles.PROJECT_DEVELOPER){
        this.router.navigate(['/register'])
      }
    }
    else{
      this.toast.error('Invalid User.', 'Error!', { closeButton : true, timeOut: 5000 });
      this.router.navigate(['/login'])
    }
  } */

  gotoDashboard(){
    let roles = this.userRoles.filter(role => {
      return role.id == this.user.role_id;
    });
    if(roles && roles.length > 0){
      let role = roles[0];
      if(role.name == PublicService.userRoles.INDIVIDUAL){
        if(this.user && this.user.user_type == 'corporate'){
          this.checkCorporateProfileData();
        }
        else{
          if(localStorage.getItem("selectedTiles")){
            this.stateData = JSON.parse(localStorage.getItem("selectedTiles"));
            if(this.stateData && this.stateData.redirectProject){
              this.router.navigate(['individual/browseproject'], { state : {projectId : this.stateData.redirectProject, userSelectedGrid : this.stateData.userSelectedGrid} });
            }else{
              this.router.navigate(['individual']);
            }
          }else{
            this.router.navigate(['individual']);
          }
        }
      }
      else if(role.name == PublicService.userRoles.COLLEAGUE){
        this.router.navigate(['organization']);
      }
      else if(role.name == PublicService.userRoles.PROJECT_DEVELOPER){
        if(this.profile){
          this.checkProfileData();
        }
        else{
          this.profile = null;
          this.router.navigate(['/register'])
        }
      }
      else if(role.name == PublicService.userRoles.PROJECT_MANAGER){
        this.router.navigate(['/projects'])
      }else{
        this.router.navigate(['/']).then(x => {
          this.router.navigate(['/sign-up-options'], { "state" : { "showRoles" : true } });
        })
      }
    }
    else{
      this.toast.error('Invalid User.', 'Error!', { closeButton : true, timeOut: 5000 });
      this.router.navigate(['/login'])
    }
  }

  signInWithGoogle(): void {
    const googleLoginOptions = {
      scope: 'profile email'
    };
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID, googleLoginOptions).then(response => {
      this.socialResponse = response;
      let data = response;
      data['role'] = this.getUserType("singup");

      this._publicApi.customLogin(data).then((response:any) => {
        localStorage.setItem('token',response.token);
        this.afterLogin(response);
        /* if(response.hasOwnProperty('status') && response.status == false){
          this.showRoles = true;
        }
        else{
        } */
      }).catch( err => {
        console.log(err);
        this.toast.error('Something went wrong. Please try again', 'Error!', { closeButton : true, timeOut: 5000 });  
      });
    }).catch(err => {
      if(err.error == "popup_closed_by_user"){
      }
      else{
        this.toast.info('Please enable cookies from your browser setting and reload the page.', 'Info!', { closeButton : true, timeOut: 5000 });  
      }
    });
  }

  afterLogin(response){
    if(response.token){
      this.loader.show();
      this._publicApi.getUserInfo().subscribe(response => {
        this.loader.hide();
        console.log(response.user)
        if(response.user.email_verified_at){
          response.user.firstname = response.user.email.match(/^([^@]*)@/)[1];

          localStorage.setItem('user',JSON.stringify(response.user));
          localStorage.setItem('profile',JSON.stringify(response.profile));
          localStorage.setItem('statistics',JSON.stringify(response.statistics));
          if(response.organizations){
            localStorage.setItem('organizations',JSON.stringify(response.organizations));
          }
          this.user = response.user;
          this.profile = response.profile;
          this.gotoDashboard();
        }
        else{
          this.toast.error('Please verify your email before logging in.', 'Error!', { closeButton : true, timeOut: 5000 });        
        }
      })
    }
    else{
      localStorage.setItem('user',JSON.stringify(response.data));
      localStorage.setItem('profile',null);
      this.user = response.data;
      this.profile = null;
      this.router.navigate(['/register'])
    }
  }

  checkCorporateProfileData(){
    if(null != this.profile && null != this.profile.businessId
         && null != this.profile.businessType && null != this.profile.financial_year){
      
      if(localStorage.getItem("selectedTiles")){
        this.stateData = JSON.parse(localStorage.getItem("selectedTiles"));
        if(this.stateData && this.stateData.redirectProject){
          this.router.navigate(['organization/browseproject'], { state : {projectId : this.stateData.redirectProject, userSelectedGrid : this.stateData.userSelectedGrid} });
        }else{
          this.router.navigate(['organization']);
        }
      }else{
        this.router.navigate(['organization']);
      }
    }
    else{
      this.router.navigate(['register-corporate']);
    }
  }

  checkProfileData(){
    if(null != this.profile && null != this.profile.name
         && null != this.profile.businessType){
      this.router.navigate(['landowner']);
    }
    else{
      this.router.navigate(['register']);
    }
  }
}
