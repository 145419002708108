import { Injectable, Inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SocialShareService {

  socialshareProviderNames = ['facebook', 'facebook-messenger','sms', 'twitter', 'linkedin', 'google', 'pinterest', 'tumblr', 'reddit', 'stumbleupon', 'buffer', 'digg', 'delicious', 'vk', 'pocket', 'wordpress', 'flipboard', 'xing', 'hackernews', 'evernote', 'whatsapp', 'telegram', 'viber', 'skype', 'email', 'ok', 'weibo'];

  constructor() { }

  shareTrigger(serviceShareConf) {

    switch (serviceShareConf.provider) {
      case 'email': {
        this.manageEmailShare(window, serviceShareConf.attrs);
        break;
      }
      case 'facebook': {
        this.manageFacebookShare(window, serviceShareConf.attrs);
        break;
      }
      case 'twitter': {
        this.manageTwitterShare(window, serviceShareConf.attrs);
        break;
      }
      case 'pinterest': {
        this.managePinterestShare(window, serviceShareConf.attrs);
        break;
      }
      case 'ok': {
        this.manageOkShare(window, serviceShareConf.attrs);
        break;
      }
      case 'vk': {
        this.manageVkShare(window, serviceShareConf.attrs);
        break;
      }
      case 'delicious': {
        this.manageDeliciousShare(window, serviceShareConf.attrs);
        break;
      }
      case 'digg': {
        this.manageDiggShare(window, serviceShareConf.attrs);
        break;
      }
      case 'google': {
        this.manageGooglePlusShare(window, serviceShareConf.attrs);
        break;
      }
      case 'reddit': {
        this.manageRedditShare(window, serviceShareConf.attrs);
        break;
      }
      case 'hackernews': {
        this.manageHackernewsShare(window, serviceShareConf.attrs);
        break;
      }
      case 'skype': {
        this.skypeShare(window, serviceShareConf.attrs);
        break;
      }
      case 'evernote': {
        this.manageEvernoteShare(window, serviceShareConf.attrs);
        break;
      }
      case 'pocket': {
        this.managePocketShare(window, serviceShareConf.attrs);
        break;
      }
      case 'tumblr': {
        this.manageTumblrShare(window, serviceShareConf.attrs);
        break;
      }
      case 'telegram': {
        this.manageTelegramShare(window, serviceShareConf.attrs);
        break;
      }
      case 'whatsapp': {
        this.manageWhatsappShare(window, serviceShareConf.attrs);
        break;
      }
      case 'xing': {
        this.manageXingShare(window, serviceShareConf.attrs);
        break;
      }
      case 'buffer': {
        this.manageBufferShare(window, serviceShareConf.attrs);
        break;
      }
      case 'stumbleupon': {
        this.manageStumbleuponShare(window, serviceShareConf.attrs);
        break;
      }
      case 'linkedin': {
        this.manageLinkedinShare(window, serviceShareConf.attrs);
        break;
      }
      case 'wordpress': {
        this.manageWordpressShare(window, serviceShareConf.attrs);
        break;
      }
      case 'flipboard': {
        this.manageFlipboardShare(window, serviceShareConf.attrs);
        break;
      }
      case 'weibo': {
        this.weiboShare(window, serviceShareConf.attrs);
        break;
      }
      default: {
        return;
      }
    }
  }

  manageFacebookShare($window, attrs) {

    var urlString;

    if (attrs.socialshareType && attrs.socialshareType === 'feed') {
      // if user specifies that they want to use the Facebook feed dialog
      //(https://developers.facebook.com/docs/sharing/reference/feed-dialog/v2.4)
      urlString = 'https://www.facebook.com/dialog/feed?';

      if (attrs.socialshareVia) {
        urlString += '&app_id=' + encodeURIComponent(attrs.socialshareVia);
      }

      if (attrs.socialshareRedirectUri) {
        urlString += '&redirect_uri=' + encodeURIComponent(attrs.socialshareRedirectUri);
      }
      if (attrs.socialshareUrl) {
        urlString += '&link=' + encodeURIComponent(attrs.socialshareUrl);
      }

      if (attrs.socialshareTo) {
        urlString += '&to=' + encodeURIComponent(attrs.socialshareTo);
      }

      if (attrs.socialshareDisplay) {
        urlString += '&display=' + encodeURIComponent(attrs.socialshareDisplay);
      }

      if (attrs.socialshareRef) {
        urlString += '&ref=' + encodeURIComponent(attrs.socialshareRef);
      }

      if (attrs.socialshareFrom) {
        urlString += '&from=' + encodeURIComponent(attrs.socialshareFrom);
      }

      if (attrs.socialshareSource) {
        urlString += '&source=' + encodeURIComponent(attrs.socialshareSource);
      }

      $window.open(
        urlString,
        'Facebook', 'toolbar=0,status=0,resizable=yes,width=' + attrs.socialsharePopupWidth + ',height=' + attrs.socialsharePopupHeight
        + ',top=' + ($window.innerHeight - attrs.socialsharePopupHeight) / 2 + ',left=' + ($window.innerWidth - attrs.socialsharePopupWidth) / 2);

    } else if (attrs.socialshareType && attrs.socialshareType === 'share') {
     // if user specifies that they want to use the Facebook share dialog
     //(https://developers.facebook.com/docs/sharing/reference/share-dialog)
     urlString = 'https://www.facebook.com/dialog/share?';

     if (attrs.socialshareVia) {
       urlString += '&app_id=' + encodeURIComponent(attrs.socialshareVia);
     }

     if (attrs.socialshareRedirectUri) {
       urlString += '&redirect_uri=' + encodeURIComponent(attrs.socialshareRedirectUri);
     }

     if (attrs.socialshareUrl) {
       urlString += '&href=' + encodeURIComponent(attrs.socialshareUrl);
     }

     if (attrs.socialshareQuote) {
       urlString += '&quote=' + encodeURIComponent(attrs.socialshareQuote);
     }

     if (attrs.socialshareDisplay) {
       urlString += '&display=' + encodeURIComponent(attrs.socialshareDisplay);
     }

     if (attrs.socialshareMobileiframe) {
     urlString += '&mobile_iframe=' + encodeURIComponent(attrs.socialshareMobileiframe);
     }

     if (attrs.socialshareHashtags) {
       urlString += '&hashtag=' + encodeURIComponent(attrs.socialshareHashtags);
     }


     $window.open(
       urlString,
       'Facebook', 'toolbar=0,status=0,resizable=yes,width=' + attrs.socialsharePopupWidth + ',height=' + attrs.socialsharePopupHeight
       + ',top=' + ($window.innerHeight - attrs.socialsharePopupHeight) / 2 + ',left=' + ($window.innerWidth - attrs.socialsharePopupWidth) / 2);

    } else if (attrs.socialshareType && attrs.socialshareType === 'send') {
      // if user specifies that they want to use the Facebook send dialog
      //(https://developers.facebook.com/docs/sharing/reference/send-dialog)
      urlString = 'https://www.facebook.com/dialog/send?';

      if (attrs.socialshareVia) {
        urlString += '&app_id=' + encodeURIComponent(attrs.socialshareVia);
      }

      if (attrs.socialshareRedirectUri) {
        urlString += '&redirect_uri=' + encodeURIComponent(attrs.socialshareRedirectUri);
      }

      if (attrs.socialshareUrl) {
        urlString += '&link=' + encodeURIComponent(attrs.socialshareUrl);
      }

      if (attrs.socialshareTo) {
        urlString += '&to=' + encodeURIComponent(attrs.socialshareTo);
      }

      if (attrs.socialshareDisplay) {
        urlString += '&display=' + encodeURIComponent(attrs.socialshareDisplay);
      }

      $window.open(
        urlString,
        'Facebook', 'toolbar=0,status=0,resizable=yes,width=' + attrs.socialsharePopupWidth + ',height=' + attrs.socialsharePopupHeight
        + ',top=' + ($window.innerHeight - attrs.socialsharePopupHeight) / 2 + ',left=' + ($window.innerWidth - attrs.socialsharePopupWidth) / 2);

    } else {
      //otherwise default to using sharer.php
      $window.open(
        'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(attrs.socialshareUrl || $window.location.href)
        , 'Facebook', 'toolbar=0,status=0,resizable=yes,width=' + attrs.socialsharePopupWidth + ',height=' + attrs.socialsharePopupHeight
        + ',top=' + ($window.innerHeight - attrs.socialsharePopupHeight) / 2 + ',left=' + ($window.innerWidth - attrs.socialsharePopupWidth) / 2);
    }
  }

  manageEmailShare($window, attrs) {
    var urlString = 'mailto:';

    if (attrs.socialshareTo) {

      urlString += encodeURIComponent(attrs.socialshareTo);
    }

    urlString += '?';

    if (attrs.socialshareBody) {

      urlString += 'body=' + encodeURIComponent(attrs.socialshareBody);
    }

    if (attrs.socialshareSubject) {

      urlString += '&subject=' + encodeURIComponent(attrs.socialshareSubject);
    }
    if (attrs.socialshareCc) {

      urlString += '&cc=' + encodeURIComponent(attrs.socialshareCc);
    }
    if (attrs.socialshareBcc) {

      urlString += '&bcc=' + encodeURIComponent(attrs.socialshareBcc);
    }
    if ($window.self !== $window.top) {
      $window.open(urlString, '_blank');
    } else {
      $window.open(urlString, '_self');
    }

  }

  facebookMessengerShare($window, attrs, element) {

    var href = 'fb-messenger://share?link=' + encodeURIComponent(attrs.socialshareUrl || $window.location.href);

    element.attr('href', href);
    element.attr('target', '_top');
  }

  manageTwitterShare($window, attrs) {
    var urlString = 'https://www.twitter.com/intent/tweet?';

    if (attrs.socialshareText) {
      urlString += 'text=' + encodeURIComponent(attrs.socialshareText);
    }

    if (attrs.socialshareVia) {
      urlString += '&via=' + encodeURIComponent(attrs.socialshareVia);
    }

    if (attrs.socialshareHashtags) {
      urlString += '&hashtags=' + encodeURIComponent(attrs.socialshareHashtags);
    }

    //default to the current page if a URL isn't specified
    urlString += '&url=' + encodeURIComponent(attrs.socialshareUrl || $window.location.href);

    $window.open(
      urlString,
      'Twitter', 'toolbar=0,status=0,resizable=yes,width=' + attrs.socialsharePopupWidth + ',height=' + attrs.socialsharePopupHeight
      + ',top=' + ($window.innerHeight - attrs.socialsharePopupHeight) / 2 + ',left=' + ($window.innerWidth - attrs.socialsharePopupWidth) / 2);
  }

  manageGooglePlusShare($window, attrs) {

    $window.open(
      'https://plus.google.com/share?url=' + encodeURIComponent(attrs.socialshareUrl || $window.location.href)
      , 'Google+', 'toolbar=0,status=0,resizable=yes,width=' + attrs.socialsharePopupWidth + ',height=' + attrs.socialsharePopupHeight
      + ',top=' + ($window.innerHeight - attrs.socialsharePopupHeight) / 2 + ',left=' + ($window.innerWidth - attrs.socialsharePopupWidth) / 2);
  }

  manageRedditShare($window, attrs) {
    var urlString = 'https://www.reddit.com/';

    if (attrs.socialshareSubreddit) {
      urlString += 'r/' + attrs.socialshareSubreddit + '/submit?url=';
    } else {
      urlString += 'submit?url=';
    }
    /*-
    * Reddit isn't responsive and at default width for our popups (500 x 500), everything is messed up.
    * So, overriding the width if it is less than 900 (played around to settle on this) and height if
    * it is less than 650px.
    */
    if (attrs.socialsharePopupWidth < 900) {
      attrs.socialsharePopupWidth = 900;
    }

    if (attrs.socialsharePopupHeight < 650) {
      attrs.socialsharePopupHeight = 650;
    }

    $window.open(
      urlString + encodeURIComponent(attrs.socialshareUrl || $window.location.href) + '&title=' + encodeURIComponent(attrs.socialshareText)
      , 'Reddit', 'toolbar=0,status=0,resizable=yes,width=' + attrs.socialsharePopupWidth + ',height=' + attrs.socialsharePopupHeight
      + ',top=' + ($window.innerHeight - attrs.socialsharePopupHeight) / 2 + ',left=' + ($window.innerWidth - attrs.socialsharePopupWidth) / 2);
  }

  manageStumbleuponShare($window, attrs) {

    $window.open(
      'https://www.stumbleupon.com/submit?url=' + encodeURIComponent(attrs.socialshareUrl || $window.location.href) + '&title=' + encodeURIComponent(attrs.socialshareText)
      , 'StumbleUpon', 'toolbar=0,status=0,resizable=yes,width=' + attrs.socialsharePopupWidth + ',height=' + attrs.socialsharePopupHeight
      + ',top=' + ($window.innerHeight - attrs.socialsharePopupHeight) / 2 + ',left=' + ($window.innerWidth - attrs.socialsharePopupWidth) / 2);
  }

  manageLinkedinShare($window, attrs) {
    /*
    * Refer: https://developer.linkedin.com/docs/share-on-linkedin
    * Tab: Customized URL
    */
    var urlString = 'https://www.linkedin.com/shareArticle?mini=true';

    urlString += '&url=' + encodeURIComponent(attrs.socialshareUrl || $window.location.href);

    if (attrs.socialshareText) {
      urlString += '&title=' + encodeURIComponent(attrs.socialshareText);
    }

    if (attrs.socialshareDescription) {
      urlString += '&summary=' + encodeURIComponent(attrs.socialshareDescription);
    }

    if (attrs.socialshareSource) {
      urlString += '&source=' + encodeURIComponent(attrs.socialshareSource);
    }

    $window.open(
      urlString,
      'Linkedin', 'toolbar=0,status=0,resizable=yes,width=' + attrs.socialsharePopupWidth + ',height=' + attrs.socialsharePopupHeight
      + ',top=' + ($window.innerHeight - attrs.socialsharePopupHeight) / 2 + ',left=' + ($window.innerWidth - attrs.socialsharePopupWidth) / 2);
  }

  managePinterestShare($window, attrs) {

    $window.open(
      'https://www.pinterest.com/pin/create/button/?url=' + encodeURIComponent(attrs.socialshareUrl || $window.location.href) + '&media=' + encodeURIComponent(attrs.socialshareMedia) + '&description=' + encodeURIComponent(attrs.socialshareText)
      , 'Pinterest', 'toolbar=0,status=0,resizable=yes,width=' + attrs.socialsharePopupWidth + ',height=' + attrs.socialsharePopupHeight
      + ',top=' + ($window.innerHeight - attrs.socialsharePopupHeight) / 2 + ',left=' + ($window.innerWidth - attrs.socialsharePopupWidth) / 2);
  }

  manageDiggShare($window, attrs) {

    $window.open(
      'https://www.digg.com/submit?url=' + encodeURIComponent(attrs.socialshareUrl || $window.location.href) + '&title=' + encodeURIComponent(attrs.socialshareText)
      , 'Digg', 'toolbar=0,status=0,resizable=yes,width=' + attrs.socialsharePopupWidth + ',height=' + attrs.socialsharePopupHeight
      + ',top=' + ($window.innerHeight - attrs.socialsharePopupHeight) / 2 + ',left=' + ($window.innerWidth - attrs.socialsharePopupWidth) / 2);
  }

  manageTumblrShare($window, attrs) {

    if (attrs.socialshareMedia) {
      var urlString = 'https://www.tumblr.com/share/photo?source=' + encodeURIComponent(attrs.socialshareMedia);

      if (attrs.socialshareText) {
        urlString += '&caption=' + encodeURIComponent(attrs.socialshareText);
      }

      $window.open(
        urlString,
        'Tumblr', 'toolbar=0,status=0,resizable=yes,width=' + attrs.socialsharePopupWidth + ',height=' + attrs.socialsharePopupHeight
        + ',top=' + ($window.innerHeight - attrs.socialsharePopupHeight) / 2 + ',left=' + ($window.innerWidth - attrs.socialsharePopupWidth) / 2);
    } else {

      $window.open(
        'https://www.tumblr.com/share/link?url=' + encodeURIComponent(attrs.socialshareUrl || $window.location.href) + '&description=' + encodeURIComponent(attrs.socialshareText)
        , 'Tumblr', 'toolbar=0,status=0,resizable=yes,width=' + attrs.socialsharePopupWidth + ',height=' + attrs.socialsharePopupHeight
        + ',top=' + ($window.innerHeight - attrs.socialsharePopupHeight) / 2 + ',left=' + ($window.innerWidth - attrs.socialsharePopupWidth) / 2);
    }
  }

  manageVkShare($window, attrs) {
    var urlString = 'https://www.vk.com/share.php?url=' + encodeURIComponent(attrs.socialshareUrl || $window.location.href);

    if (attrs.socialshareText) {
      urlString += '&title=' + encodeURIComponent(attrs.socialshareText);
    }

    if (attrs.socialshareMedia) {
      urlString += '&image=' + encodeURIComponent(attrs.socialshareMedia);
    }

    if (attrs.socialshareDescription) {
      urlString += '&description=' + encodeURIComponent(attrs.socialshareDescription);
    }

    $window.open(
     urlString
     , 'Vk', 'toolbar=0,status=0,resizable=yes,width=' + attrs.socialsharePopupWidth + ',height=' + attrs.socialsharePopupHeight
     + ',top=' + ($window.innerHeight - attrs.socialsharePopupHeight) / 2 + ',left=' + ($window.innerWidth - attrs.socialsharePopupWidth) / 2);
  }

  manageOkShare($window, attrs) {
    $window.open(
      'http://www.odnoklassniki.ru/dk?st.cmd=addShare&st.s=1&st._surl=' + encodeURIComponent(attrs.socialshareUrl || $window.location.href) + '&st.comments=' + encodeURIComponent(attrs.socialshareText)
      , 'Ok', 'toolbar=0,status=0,resizable=yes,width=' + attrs.socialsharePopupWidth + ',height=' + attrs.socialsharePopupHeight
      + ',top=' + ($window.innerHeight - attrs.socialsharePopupHeight) / 2 + ',left=' + ($window.innerWidth - attrs.socialsharePopupWidth) / 2);
  }

  manageDeliciousShare($window, attrs) {

   $window.open(
     'https://www.delicious.com/save?v=5&noui&jump=close&url=' + encodeURIComponent(attrs.socialshareUrl || $window.location.href) + '&title=' + encodeURIComponent(attrs.socialshareText)
     , 'Delicious', 'toolbar=0,status=0,resizable=yes,width=' + attrs.socialsharePopupWidth + ',height=' + attrs.socialsharePopupHeight
     + ',top=' + ($window.innerHeight - attrs.socialsharePopupHeight) / 2 + ',left=' + ($window.innerWidth - attrs.socialsharePopupWidth) / 2);
  }

  manageBufferShare($window, attrs) {
    var urlString = 'https://bufferapp.com/add?';

    if (attrs.socialshareText) {
      urlString += 'text=' + encodeURIComponent(attrs.socialshareText);
    }

    if (attrs.socialshareVia) {
      urlString += '&via=' + encodeURIComponent(attrs.socialshareVia);
    }

    if (attrs.socialshareMedia) {
      urlString += '&picture=' + encodeURIComponent(attrs.socialshareMedia);
    }

    //default to the current page if a URL isn't specified
    urlString += '&url=' + encodeURIComponent(attrs.socialshareUrl || $window.location.href);

    $window.open(
      urlString,
      'Buffer', 'toolbar=0,status=0,resizable=yes,width=' + attrs.socialsharePopupWidth + ',height=' + attrs.socialsharePopupHeight
      + ',top=' + ($window.innerHeight - attrs.socialsharePopupHeight) / 2 + ',left=' + ($window.innerWidth - attrs.socialsharePopupWidth) / 2);
  }

  manageHackernewsShare($window, attrs) {
    var urlString = 'https://news.ycombinator.com/submitlink?';

    if (attrs.socialshareText) {
      urlString += 't=' + encodeURIComponent(attrs.socialshareText) + '&';
    }
    //default to the current page if a URL isn't specified
    urlString += 'u=' + encodeURIComponent(attrs.socialshareUrl || $window.location.href);

    $window.open(
     urlString,
     'Hackernews', 'toolbar=0,status=0,resizable=yes,width=' + attrs.socialsharePopupWidth + ',height=' + attrs.socialsharePopupHeight
    + ',top=' + ($window.innerHeight - attrs.socialsharePopupHeight) / 2 + ',left=' + ($window.innerWidth - attrs.socialsharePopupWidth) / 2);
  }

  manageFlipboardShare($window, attrs) {
    var urlString = 'https://share.flipboard.com/bookmarklet/popout?v=2&';

    if (attrs.socialshareText) {
      urlString += 'title=' + encodeURIComponent(attrs.socialshareText) + '&';
    }

    //default to the current page if a URL isn't specified
    urlString += 'url=' + encodeURIComponent(attrs.socialshareUrl || $window.location.href);

    $window.open(
      urlString,
      'Flipboard', 'toolbar=0,status=0,resizable=yes,width=' + attrs.socialsharePopupWidth + ',height=' + attrs.socialsharePopupHeight
      + ',top=' + ($window.innerHeight - attrs.socialsharePopupHeight) / 2 + ',left=' + ($window.innerWidth - attrs.socialsharePopupWidth) / 2);
  }

  managePocketShare($window, attrs) {
    var urlString = 'https://getpocket.com/save?';

    if (attrs.socialshareText) {
      urlString += 'text=' + encodeURIComponent(attrs.socialshareText) + '&';
    }

    //default to the current page if a URL isn't specified
    urlString += 'url=' + encodeURIComponent(attrs.socialshareUrl || $window.location.href);

    $window.open(
      urlString,
      'Pocket', 'toolbar=0,status=0,resizable=yes,width=' + attrs.socialsharePopupWidth + ',height=' + attrs.socialsharePopupHeight
      + ',top=' + ($window.innerHeight - attrs.socialsharePopupHeight) / 2 + ',left=' + ($window.innerWidth - attrs.socialsharePopupWidth) / 2);
  }

  manageWordpressShare($window, attrs) {
    var urlString = 'http://wordpress.com/press-this.php?';

    if (attrs.socialshareText) {
      urlString += 't=' + encodeURIComponent(attrs.socialshareText) + '&';
    }
    if (attrs.socialshareMedia) {
      urlString += 'i=' + encodeURIComponent(attrs.socialshareMedia) + '&';
    }

    //default to the current page if a URL isn't specified
    urlString += 'u=' + encodeURIComponent(attrs.socialshareUrl || $window.location.href);

    $window.open(
      urlString,
      'Wordpress', 'toolbar=0,status=0,resizable=yes,width=' + attrs.socialsharePopupWidth + ',height=' + attrs.socialsharePopupHeight
      + ',top=' + ($window.innerHeight - attrs.socialsharePopupHeight) / 2 + ',left=' + ($window.innerWidth - attrs.socialsharePopupWidth) / 2);
  }

  manageXingShare($window, attrs) {
    var followUrl = '';

    if (attrs.socialshareFollow) {
      followUrl = '&follow_url=' + encodeURIComponent(attrs.socialshareFollow);
    }
    $window.open(
      'https://www.xing.com/spi/shares/new?url=' + encodeURIComponent(attrs.socialshareUrl || $window.location.href) + followUrl
      , 'Xing', 'toolbar=0,status=0,resizable=yes,width=' + attrs.socialsharePopupWidth + ',height=' + attrs.socialsharePopupHeight
      + ',top=' + ($window.innerHeight - attrs.socialsharePopupHeight) / 2 + ',left=' + ($window.innerWidth - attrs.socialsharePopupWidth) / 2);
  }

  manageEvernoteShare($window, attrs) {

    var urlString = 'http://www.evernote.com/clip.action?url=' + encodeURIComponent(attrs.socialshareUrl || $window.location.href);

    if (attrs.socialshareText) {
      urlString += '&title=' + encodeURIComponent(attrs.socialshareText);
    }

    $window.open(
      urlString
      , 'Evernote', 'toolbar=0,status=0,resizable=yes,width=' + attrs.socialsharePopupWidth + ',height=' + attrs.socialsharePopupHeight
      + ',top=' + ($window.innerHeight - attrs.socialsharePopupHeight) / 2 + ',left=' + ($window.innerWidth - attrs.socialsharePopupWidth) / 2);
  }

  manageWhatsappShare($window, attrs) {

    var href = 'whatsapp://send?text=' + encodeURIComponent(attrs.socialshareText) + '%0A' + encodeURIComponent(attrs.socialshareUrl || $window.location.href);

    $window.open(href, 'Whatsapp', 'toolbar=0,status=0,resizable=yes,width=' + attrs.socialsharePopupWidth + ',height=' + attrs.socialsharePopupHeight
    + ',top=' + ($window.innerHeight - attrs.socialsharePopupHeight) / 2 + ',left=' + ($window.innerWidth - attrs.socialsharePopupWidth) / 2);
    //element.attr('href', href);
    //element.attr('target', '_top');

  }

  manageViberShare($window, attrs, element) {

    var href = 'viber://forward?text=' + encodeURIComponent(attrs.socialshareText) + encodeURIComponent(attrs.socialshareUrl || $window.location.href);

    element.attr('href', href);
    element.attr('target', '_top');
  }

  manageTelegramShare($window, attrs) {

    var urlString = 'https://telegram.me/share/url?url=' + encodeURIComponent(attrs.socialshareUrl || $window.location.href);

    if (attrs.socialshareText) {
      urlString += '&text=' + encodeURIComponent(attrs.socialshareText);
    }

    $window.open(
      urlString
      , 'Telegram', 'toolbar=0,status=0,resizable=yes,width=' + attrs.socialsharePopupWidth + ',height=' + attrs.socialsharePopupHeight
      + ',top=' + ($window.innerHeight - attrs.socialsharePopupHeight) / 2 + ',left=' + ($window.innerWidth - attrs.socialsharePopupWidth) / 2);
  }

  skypeShare($window, attrs) {
    var urlString = 'https://web.skype.com/share?source=button&url=' + encodeURIComponent(attrs.socialshareUrl || $window.location.href);

    if (attrs.socialshareText) {
      urlString += '&text=' + encodeURIComponent(attrs.socialshareText);
    }

    $window.open(
      urlString
      , 'Skype', 'toolbar=0,status=0,resizable=yes,width=' + attrs.socialsharePopupWidth + ',height=' + attrs.socialsharePopupHeight
      + ',top=' + ($window.innerHeight - attrs.socialsharePopupHeight) / 2 + ',left=' + ($window.innerWidth - attrs.socialsharePopupWidth) / 2);
  }

  weiboShare($window, attrs) {
    var urlString = 'http://service.weibo.com/share/share.php?url=' + encodeURIComponent(attrs.socialshareUrl || $window.location.href);

    if (attrs.socialshareText) {
      urlString += '&title=' + encodeURIComponent(attrs.socialshareText);
    }

    $window.open(
      urlString
      , 'Weibo', 'toolbar=0,status=0,resizable=yes,width=' + attrs.socialsharePopupWidth + ',height=' + attrs.socialsharePopupHeight
      + ',top=' + ($window.innerHeight - attrs.socialsharePopupHeight) / 2 + ',left=' + ($window.innerWidth - attrs.socialsharePopupWidth) / 2);
  }
}
