import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';

type componenttype={
	data:{
		username:string,
		userimagepath:string,
		userregisterdate:string,
	},
	custom_component_data:{
		project_button_text:string,
		is_filter_nedded:boolean,
		
	}
 
}




@Component({
  selector: 'app-adminnewproject',
  templateUrl: './adminnewproject.component.html',
  styleUrls: ['./adminnewproject.component.scss']
})
export class AdminnewprojectComponent implements OnInit {

  
  is_filter_open:boolean = false;
  @Input() newproject:componenttype
  
  filter_option_data = 
  [
      {
      filter_type:"Intervention Type",
      filter_options:['Conservation','Forest Management','Plantaion Forestry','Agroforestry','Silvopasture','Agriculture','Restoration','Tourism','Education','Research','Sourcing'],
      },
      {
        filter_type:"Country",
        filter_options:['Argentina','Belgium','Canada','Denmark','France','Germany','Italy','Netherlands','Norway','Spain','Sweden','United Kingdom','United States'],
      },
      {
        filter_type:"Biome",
        filter_options:['Aquatic','Grassland','Forest','Desert','Tundra'],
      },
      {
        filter_type:"Species",
        filter_options:['Tree','Amphibians','Plants','Birds','Fish','Mammals','Reptiles']
      }
  ]
  constructor() { }

  ngOnInit(): void {
	  
  }
  
}


