import { Component, OnInit, OnDestroy, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { PublicService } from 'src/app/api/public.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit, OnDestroy {
  test: Date = new Date();
  public isCollapsed = true;
  mobiledropdown=false;
  user:any; 
  profile : any;
  navbar = "navbar";
  navIconClass="fas fa-bars mobile-nav-toggle text-white";
  naviclicked = false;
  nav_status = [
  /* {
    nav_code:1,
    nav_name:'Home',
    is_nav_active:true,
    nav_link:'/landing',
  },{
    nav_code:2,
    nav_name:'About Us',
    is_nav_active:false,
    nav_link:'/about-us',
  },{
    nav_code:3,
    nav_name:'Technologies',
    is_nav_active:false,
    nav_link:'/technologies',
  }, */{
    nav_code:4,
    nav_name:'Projects',
    is_nav_active:false,
    nav_link:'/projects',
  },{
    nav_code:5,
    nav_name:'Blogs',
    is_nav_active:false,
    nav_link:'https://www.green.earth/blog',
  }]
  can_i_show_footer = true
  constructor(private router: Router,private _publicApi : PublicService,
    private loader : NgxSpinnerService, private toast : ToastrService, private renderer: Renderer2) { }

  ngOnInit() {
    var html = document.getElementsByTagName("html")[0];
    html.classList.add("auth-layout");
    var body = document.getElementsByTagName("body")[0];
    body.classList.add("bg-default");
    this.router.events.subscribe((event) => {
      this.isCollapsed = true;
    });
    this.checkLogin();

    if (this.router.url.startsWith('/projects')){
      this.can_i_show_footer = false; 
    }
    this.click(this.router.url)
  }
  ngOnDestroy() {
    var html = document.getElementsByTagName("html")[0];
    html.classList.remove("auth-layout");
    var body = document.getElementsByTagName("body")[0];
    body.classList.remove("bg-default");
  }

  async login(userType) {
    localStorage.setItem("userType",userType);
    this.router.navigate(['/login'])
  }

  checkLogin(){
    console.log("check login");
    if(this._publicApi.isTokenExpired()){
      this.user = JSON.parse(localStorage.getItem('user'));
      this.redirectToDashboard();
    }
  }

  redirectToDashboard(){
    this._publicApi.getUserTypes().subscribe((response:any) => {
      let roles = response.roles.filter(role => {
        return role.id == this.user.role_id;
      });
      if(roles && roles.length > 0){
        let role = roles[0];
        if(role.name == PublicService.userRoles.INDIVIDUAL){
          this.router.navigate(['/individual'])
        }else if(role.name == PublicService.userRoles.PROJECT_DEVELOPER){
          this.router.navigate(['/landowner'])
        }
      }
    })
  }

  logout(){
    this.user = undefined;
    this.profile = undefined;
    localStorage.clear();
    this.router.navigate(['/']);
  }

  /* async login(userType) {
    while(!this.appid.initialized){
      await this.initializeIbmApp();
    }
    localStorage.setItem("userType",userType);
    try {
      console.log(this.appid);
      const tokens = await this.appid.signin();
      this.loader.show();
      this._publicApi.login(tokens).subscribe(response => {
        this.loader.hide();
        console.log(response);
        if(response.success){
          if(response.token){
            localStorage.setItem('token',response.token);
            this._publicApi.getUserInfo().subscribe(response => {
              localStorage.setItem('user',JSON.stringify(response.user));
              localStorage.setItem('profile',JSON.stringify(response.profile));
              if(response.profile){
                this.user = response.user;
                this.profile = response.profile;
                this.checkProfileData();
              }
              else{
                this.profile = null;
                this.user = response.user;
                this.router.navigate(['/register'])
              }
            })
          }
          else{
            localStorage.setItem('user',JSON.stringify(response.data));
            localStorage.setItem('profile',null);
            this.user = response.data;
            this.profile = null;
            this.router.navigate(['/register'])
          }
        }
        else{
          this.toast.error('Invalid username password.', 'Error!', { closeButton : true, timeOut: 5000 });
        }
      });
    } catch (e) {
      this.loader.hide();
      //this.toast.error(e.message, 'Error!', { closeButton : true, timeOut: 5000 });
    }
  } */

  /* checkProfileData(){
    if(null != this.profile && null != this.profile.userGovtId && null != this.profile.name
         && null != this.profile.businessType && null != this.profile.shareholderStructure){
      this.router.navigate(['dashboard']);
    }
    else{
      this.router.navigate(['register']);
    }
  } */

  toggleNav(){
    this.navbar = this.navbar.indexOf("navbar-mobile")>0 ? "navbar" : "navbar navbar-mobile";
    this.navIconClass = this.navIconClass.indexOf("fa-bars")>0 ? "mt-2 text-white fas fa-times mobile-nav-toggle" : "fas fa-bars text-white mobile-nav-toggle";
  }

  click(nav_link:string){
    if (nav_link == this.nav_status[0].nav_link ){
      this.nav_status[0].is_nav_active = true;
      this.nav_status[1].is_nav_active = false;
/*       this.nav_status[2].is_nav_active = false;
      this.nav_status[3].is_nav_active = false;
      this.nav_status[4].is_nav_active = false; */
    }
    else if (nav_link == this.nav_status[1].nav_link ){
      this.nav_status[0].is_nav_active = false;
      this.nav_status[1].is_nav_active = true;
/*       this.nav_status[2].is_nav_active = false;
      this.nav_status[3].is_nav_active = false;
      this.nav_status[4].is_nav_active = false; */
    }
    else if (nav_link == this.nav_status[2].nav_link ){
      this.nav_status[0].is_nav_active = false;
      this.nav_status[1].is_nav_active = false;
      this.nav_status[2].is_nav_active = true;
      this.nav_status[3].is_nav_active = false;
      this.nav_status[4].is_nav_active = false;
    }
    else if (nav_link == this.nav_status[3].nav_link ){
      this.nav_status[0].is_nav_active = false;
      this.nav_status[1].is_nav_active = false;
      this.nav_status[2].is_nav_active = false;
      this.nav_status[3].is_nav_active = true;
      this.nav_status[4].is_nav_active = false;
    }
    else if (nav_link == this.nav_status[4].nav_link ){
      this.nav_status[0].is_nav_active = false;
      this.nav_status[1].is_nav_active = false;
      this.nav_status[2].is_nav_active = false;
      this.nav_status[3].is_nav_active = false;
      this.nav_status[4].is_nav_active = true;
    }
  }

  toggleClass(event: any, className: string) {
    let e = this.renderer.parentNode(event.target);
    const hasClass = e.classList.contains(className);
    if (hasClass) {
        this.renderer.removeClass(e, className);
    } else {
        this.renderer.addClass(e, className);
    }
  }
}
