import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PublicService } from 'src/app/api/public.service';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-join-member',
  templateUrl: './join-member.component.html',
  styleUrls: ['./join-member.component.scss']
})
export class JoinMemberComponent implements OnInit {
  activationId : any;
  userEmail : any;
  projectName : any;
  inviteeName : any;
  projectId : any;
  user : any;
  accepted:any;
  constructor(private route: ActivatedRoute, private _router : Router, private _publicApi : PublicService, 
    private pubsub: NgxPubSubService, private toast : ToastrService, private loader : NgxSpinnerService) { }

  ngOnInit(): void {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.route.queryParams.subscribe(params => {
        this.activationId = params.invite_code;
        this.userEmail = params.email;
        this.projectName = params.project;
        this.projectId = params.project_id;
        this.inviteeName = params.project_owner;
        this.acceptInvitation();
      }
    );
  }

  acceptInvitation(){
    this.loader.show();
    this._publicApi.acceptInvitation(this.activationId).then(response => {
      this.loader.hide();
      console.log(response);
      if(response.hasOwnProperty('status') && response.status == false){
        this.accepted = false;
        this.toast.info('Please sign up with your account.', 'Info!', { closeButton : true, timeOut: 5000 });
        localStorage.setItem('invited',"true");
        localStorage.setItem("invitedEmail", response.email);
        //this._router.navigate(['/sign-up'])
      }
      else if(this.user || (response.hasOwnProperty('status') && response.status)){
        this.accepted = true;
        this.toast.success('Invitation accepted.', 'Great!', { closeButton : true, timeOut: 5000 });  
        //this._router.navigate(['/dashboard']);
      }
    }, error => {
      this.loader.hide();
      console.log(error);
/*       if(error.status == "not_invited"){
        this._snackBar.open(error.message, 'Ok');
      }
      else if(error.status == "not_registered"){
        //this._snackBar.open(error.message, 'Ok');
        localStorage.setItem("inviteProjectId", this.projectId);
        this._snackBar.open("Please create your account to access the project details.", 'Ok');
        this.pubsub.publishEvent("invitedUser")

      } */
      //this._router.navigate(['landing']);
      this.toast.info('Please login with your account to check the organization details.', 'Info!', { closeButton : true, timeOut: 5000 });
      this._router.navigate(['/login']);
      //this.toast.error('You are already a project member with another', 'Error!', { closeButton : true, timeOut: 5000 });
    });
  }


  signup(){
    this._router.navigate(['/sign-up-options']);
  }

  login(){
    this._router.navigate(['/login']);
  }
}
