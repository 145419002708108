import { Component, OnInit } from '@angular/core';
import { FormGroup,FormControl, FormBuilder,  Validators } from '@angular/forms';
import { PublicService } from 'src/app/api/public.service';
@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.scss']
})
export class AboutUSComponent implements OnInit {
  team_members = [ {
  team_member_id:1,
  team_member_image_path:'assets/newimages/adviser.png',
  team_member_name:'Selwyn Duijvestijn',
  team_member_post:'Chief Executive Officer'
},{
  team_member_id:2,
  team_member_image_path:'assets/newimages/adviser.png',
  team_member_name:'Dick den Hartog',
  team_member_post:'Non-Executive Board Member'
},{
  team_member_id:3,
  team_member_image_path:'assets/newimages/adviser.png',
  team_member_name:'Hilda van der Meulen',
  team_member_post:'Non-Executive Board Member'
},{
  team_member_id:4,
  team_member_image_path:'assets/newimages/adviser.png',
  team_member_name:'Nigel Farage',
  team_member_post:'Advisory Board'
},{
  team_member_id:5,
  team_member_image_path:'assets/newimages/adviser.png',
  team_member_name:'Dr. Michael Galante',
  team_member_post:'Technical Investment Board'
},{
  team_member_id:6,
  team_member_image_path:'assets/newimages/adviser.png',
  team_member_name:'Dr. Bernd Hahn-Schilling',
  team_member_post:'Technical Investment Board'
},{
  team_member_id:7,
  team_member_image_path:'assets/newimages/adviser.png',
  team_member_name:'James Eaton',
  team_member_post:'Technical Investment Board'
},{
  team_member_id:8,
  team_member_image_path:'assets/newimages/adviser.png',
  team_member_name:'Rieks Bosch',
  team_member_post:'Technical Investment Board'
  } ]
  advisors = [ {
  advisors_name:'Dick den Hartog',
  advisors_post:'Non-Executive Board Member',
  advisors_profile_path:'assets/newimages/adviser.jpg',
  advisors_mail:'mailto:' + '',
  advisors_phone:'tel:' + '',
  advisors_linkdin_link:'',
  advisors_twitter_link:'',
},{
  advisors_name:'Selwyn Duijvestijn',
  advisors_post:'Chief Executive Officer',
  advisors_profile_path:'assets/newimages/adviser.jpg',
  advisors_mail:'mailto:' + '',
  advisors_phone:'tel:' + '',
  advisors_linkdin_link:'',
  advisors_twitter_link:'',
},{
  advisors_name:'Hilda van der Meulen',
  advisors_post:'Non-Executive Board Member',
  advisors_profile_path:'assets/newimages/adviser.jpg',
  advisors_mail:'mailto:' + '',
  advisors_phone:'tel:' + '',
  advisors_linkdin_link:'',
  advisors_twitter_link:'',
  } ]
  faqs = [ {
    faq_id:1,
    faq_heading:'What are nature-based solutions?',
    faq_description:'By using the carbon markets, entities can neutralize, or offset, their emissions by retiring carbon credits generated by projects that are reducing GHG emissions elsewhere. Of course, it is critical to ensure, or verify, that the emission reductions generated by these projects are actually occurring.Companies and private individuals are interested in contributing to their living environment. In order to translate this willingness into implementation, nature offsets and especially carbon offsets make nature compensation easy and provide an appropriate, flexible system of compensation. ',
    faq_video_path:'assets/video/FAQ1.mp4',
    is_faq_open:true,
  },{
    faq_id:2,
    faq_heading:'What is a carbon offset?',
    faq_description:'By using the carbon markets, entities can neutralize, or offset, their emissions by retiring carbon credits generated by projects that are reducing GHG emissions elsewhere. Of course, it is critical to ensure, or verify, that the emission reductions generated by these projects are actually occurring.Companies and private individuals are interested in contributing to their living environment. In order to translate this willingness into implementation, nature offsets and especially carbon offsets make nature compensation easy and provide an appropriate, flexible system of compensation. ',
    faq_video_path:'assets/newimages/faqvid.png',
    is_faq_open:false,
  } ]
  row_9 = [ 
    "assets/video/Forest.mp4", 
    "assets/video/Tractor.mp4", 
    "assets/video/Plants.mp4"
  ]
  initial_index = 0;


  contactForm: FormGroup;
  sendMessage='';
  errorValidation=false;
  constructor(private fb: FormBuilder,private _publicApi : PublicService) {
      this.contactFromValidator();
  }
  contactFromValidator() {
    this.contactForm = this.fb.group({
        firstName: ['', Validators.required ],
        lastName: ['', Validators.required ],
        email: ['', Validators.required ],
        message: ['', Validators.required ]
    });
  }

  ngOnInit(): void {
    this.change_gif()
  }
  change_gif(){
    let self = this
    setInterval(()=>{
      if (self.initial_index == 0 ){
        self.initial_index = 1;
      }
      else if (self.initial_index == 1 ){
        self.initial_index = 2;
      }
      else if (self.initial_index == 2 ){
        self.initial_index = 0;
      }  
    },12000)
  }
  
  contactFormSubmit(form){
    if(form.valid)
    {
      this._publicApi.contactNow(form.value).then((response:any) => {
        if(response.success)
        {
          this.sendMessage='<div class="text-success">Thanks for send message</div>';
        }else{
          this.sendMessage='<div class="text-danger">Your message not sent. Please try again</div>';
        }
      }).catch( err => {
        this.sendMessage='<div class="text-danger">Your message not sent. Please try again</div>';
      });
    }else{
      this.errorValidation=true;
      return;
    }
  }
}
