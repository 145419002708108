import { Component, OnInit, Input,Output, EventEmitter, SimpleChanges } from '@angular/core';
import { PublicService } from 'src/app/api/public.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { CompleterService, CompleterData } from 'ng2-completer';


@Component({
  selector: 'app-browse-catagory',
  templateUrl: './browse-catagory.component.html',
  styleUrls: ['./browse-catagory.component.scss']
})
export class BrowseCatagoryComponent implements OnInit {
	 
  @Output() catagory_selected_event = new EventEmitter();
  @Output() loadProjects = new EventEmitter<{ projectsData: any, location: boolean }>();
  
	is_filter_open:boolean = false;
  is_card_clicked=false;
  groups:any;
  card_data=[];
  location_card_data=[];
  intervention_card_data=[];
  Biome_card_data=[];
  Species_card_data=[];
  countries:any;


/* 	card_data = [
		{
			card_icon:"fa fa-globe",
			card_image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQFQD-qA6Wwry8_ly3jF5dWTS9kD0ydCXiECQ&usqp=CAU",
			card_sub_title:"View the Top",
      card_title:"Featured Projects",
      card_type : "type"
		},{
			card_icon:"fa fa-globe",
			card_image:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTibX5NQiXbiVL-cNQGs69Z0duNWbB5m3dJpIGv1eD3XPt9wTCeT3KN17MjCY4vSOJe6Ro&usqp=CAU',
			card_sub_title:"View the Top",
      card_title:"Most Popular",
      card_type : "type"
		}
	] 
 
	location_card_data = [
     {
      card_icon:"fa fa-globe",
      card_image:"https://media.cntraveler.com/photos/576afddc558ff1e22af12773/master/w_2048,h_1536,c_limit/asia-Ha-Long-Bay-GettyImages-510960301.jpg",
      card_sub_title:"See projects in",
      card_title:"Asia",
      card_type : "country"
      },{
      card_icon:"fa fa-globe",
      card_image:'https://img.traveltriangle.com/blog/wp-content/uploads/2017/08/africa-safari-Cover1.jpg',
      card_sub_title:"See projects in",
      card_title:"Africa",
      card_type : "country"
      },
      {
      card_icon:"fa fa-globe",
      card_image:"https://media1.popsugar-assets.com/files/thumbor/K_XU614GMLkOj0PHloj3fVGQJdQ/fit-in/728xorig/filters:format_auto-!!-:strip_icc-!!-/2018/07/11/057/n/1922441/99b7548d5b469f51c4da37.80072941_/i/Best-Natural-Wonders-North-America.jpg",
      card_sub_title:"See projects in",
      card_title:"North America",
      card_type : "country"
      },{
      card_icon:"fa fa-globe",
      card_image:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSNoOFqdUxfylWRZS2cV2MmJn4tRPNs1I06DGJZQSKkRDIGb6jX_S9SStMIr3EDsTesn5M&usqp=CAU',
      card_sub_title:"View the Top",
      card_title:"South America",
      card_type : "country"
      },{
      card_icon:"fa fa-globe",
      card_image:'https://media.cntraveler.com/photos/611bafebd713cfe783511f6c/master/w_3375,h_2250,c_limit/Lofoten-Islands-_GettyImages-583610815.jpg',
      card_sub_title:"View the Top",
      card_title:"Europe",
      card_type : "country"
      },{
      card_icon:"fa fa-globe",
      card_image:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSKanEPYz1Kp3fcNeOOyG7Bz0eIeMs8cBR_rsHnxe0oBi23dj3nqSgjWBpMJb7i1VO7S4M&usqp=CAU',
      card_sub_title:"View the Top",
      card_title:"Australia",
      card_type : "country"
      }   
    ]

  intervention_card_data = [
    {
     card_icon:"fa fa-globe",
     card_image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSXbEPVegAECppHpdZAOUwGiv0qYiNtooExQQOcwyklko0Ys6yyT6JvY5YOHYHOhpoAw2A&usqp=CAU",
     card_sub_title:"Explore the",
     card_title:"Conservation",
     card_type : "intervention"
     },{
     card_icon:"fa fa-globe",
     card_image:'https://media.istockphoto.com/photos/asian-engineers-men-and-women-exploring-the-forest-picture-id1166443720?k=20&m=1166443720&s=612x612&w=0&h=dNuqz76tyvnPd9Ewij3Ow6Gw_tQtcUmG3RAK2sCJ8c0=',
     card_sub_title:"Explore the",
     card_title:"Forest Management",
     card_type : "intervention"
     },
     {
     card_icon:"fa fa-globe",
     card_image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ8SivXkABpB2g2BnFUC-cB2J5r-l1VLFc4kp8iJBy9hY-uJnc3JGPdaX2j4s8yka7CE2s&usqp=CAU",
     card_sub_title:"Explore the",
     card_title:"Plantation Forestry",
     card_type : "intervention"
     },{
     card_icon:"fa fa-globe",
     card_image:'https://grocycle.com/wp-content/uploads/2020/01/agroforestry-farm-1024x512.jpg',
     card_sub_title:"Explore the",
     card_title:"Agroforestry",
     card_type : "intervention"
     },{
    card_icon:"fa fa-globe",
    card_image:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQcwFBSDw2LbhS-kqQ1Xyir4_tLUlyJONDX6_3WbfRDsUAh603LATwEaKiKCXxM2cAYRoA&usqp=CAU',
    card_sub_title:"Explore the",
    card_title:"Silvopasture",
    card_type : "intervention"
    },{    
     card_icon:"fa fa-globe",
     card_image:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcThFYcaNuWsUL-pColcgVJbdV-9Llu4-VCpQBtX54o5st8pL4hnpqkTrLZs4syHVpBM9U4&usqp=CAU',
     card_sub_title:"Explore the",
     card_title:"Agriculture",
     card_type : "intervention"
    },{    
      card_icon:"fa fa-globe",
      card_image:'https://thedispatch.blob.core.windows.net/thedispatchimages/2021/06/ecosystem-restoration-The-Dispatch-.jpg',
      card_sub_title:"Explore the",
      card_title:"Restoration",
      card_type : "intervention"
    },{    
      card_icon:"fa fa-globe",
      card_image:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT_yrU_YFOiJWHaj5w3ZkzrBT5aAf339IQmmklHXMbWFlr9eDCH7He1uTKSmOSCbxCXpmM&usqp=CAU',
      card_sub_title:"Explore the",
      card_title:"Tourism",
      card_type : "intervention"
    },{    
      card_icon:"fa fa-globe",
      card_image:'https://static.theprint.in/wp-content/uploads/2021/10/schools-1.jpg?compress=true&quality=80&w=376&dpr=2.6',
      card_sub_title:"Explore the",
      card_title:"Education",
      card_type : "intervention"
    },{    
      card_icon:"fa fa-globe",
      card_image:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQg-tuKdJI6g4Fm51CEcsx7SRn9q0l5qazmwg&usqp=CAU',
      card_sub_title:"Explore the",
      card_title:"Research",
      card_type : "intervention"
    },{    
      card_icon:"fa fa-globe",
      card_image:'https://www.crelate.com/wp-content/uploads/2018/04/attract-passive-candidates.png',
      card_sub_title:"Explore the",
      card_title:"Sourcing",
      card_type : "intervention"
    },      
  ]

  Biome_card_data = [
  {
   card_icon:"fa fa-globe",
   card_image:"https://img.artpal.com/72008/71-16-11-7-10-0-55m.jpg",
   card_sub_title:"Explore the",
   card_title:"Aquatic",
   card_type : "biome"
   },{
   card_icon:"fa fa-globe",
   card_image:'https://files.worldwildlife.org/wwfcmsprod/images/Grasslands_Hero/story_full_width/9n2j8lsjmq_grasslands_hero.jpg',
   card_sub_title:"Explore the",
   card_title:"Grassland",
   card_type : "biome"
   },
   {
   card_icon:"fa fa-globe",
   card_image:"https://media.cntraveler.com/photos/5eb18e42fc043ed5d9779733/16:9/w_2580,c_limit/BlackForest-Germany-GettyImages-147180370.jpg",
   card_sub_title:"Explore the",
   card_title:"Forest",
   card_type : "biome"
   },{
   card_icon:"fa fa-globe",
   card_image:'https://www.stormgeo.com/assets/ArticleImages/desert__FocusFillWzQ1NTksMjcwMCwieSIsMTczXQ.jpg',
   card_sub_title:"Explore the",
   card_title:"Desert",
   card_type : "biome"
   },{
    card_icon:"fa fa-globe",
    card_image:'https://media.nationalgeographic.org/assets/photos/201/399/978429cd-0d04-464b-a3c1-473e163ecc5b.jpg',
    card_sub_title:"Explore the",
    card_title:"Tundra",
    card_type : "biome"
  },  
 ]

  Species_card_data = [
  {
   card_icon:"fa fa-globe",
   card_image:"https://cf.ltkcdn.net/garden/images/std/239340-800x533r1-chestnut-tree.jpg",
   card_sub_title:"Explore the",
   card_title:"Trees",
   card_type : "species"
   },{
   card_icon:"fa fa-globe",
   card_image:'https://animals.sandiegozoo.org/sites/default/files/2016-10/animals_hero_amphibeans.jpg',
   card_sub_title:"Explore the",
   card_title:"Amphibians",
   card_type : "species"
   },
   {
   card_icon:"fa fa-globe",
   card_image:"https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTxlVscSYzyZ6ab3lU8-CnRf2OpXqaFkJKRgQ&usqp=CAU",
   card_sub_title:"Explore the",
   card_title:"Plants",
   card_type : "species"
   },{
   card_icon:"fa fa-globe",
   card_image:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSms0n4L15gz_6lM3qVnf9QHnCwAGtNDtXOAw&usqp=CAU',
   card_sub_title:"Explore the",
   card_title:"Birds",
   card_type : "species"
   },{
    card_icon:"fa fa-globe",
    card_image:'https://images.unsplash.com/photo-1535591273668-578e31182c4f?ixlib=rb-1.2.1&q=80&fm=jpg&crop=entropy&cs=tinysrgb&w=1080&fit=max&ixid=eyJhcHBfaWQiOjM2NTI5fQ',
    card_sub_title:"Explore the",
    card_title:"Fish",
    card_type : "species"
  },{
    card_icon:"fa fa-globe",
    card_image:'https://www.ducksters.com/animals/mammals_ducksters3.JPG',
    card_sub_title:"Explore the",
    card_title:"Mammals",
    card_type : "species"
  },{
    card_icon:"fa fa-globe",
    card_image:'https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRES3c64HeF76npck5gE1pZRtjMSjE5qeJmyA&usqp=CAU',
    card_sub_title:"Explore the",
    card_title:"Reptiles",
    card_type : "species"
  }, 
 ] */

  filter_option_data = 
  [
      {
        filter_type:"Project Type",
        filter_key : "intervention",
        filter_selected_value : "",
        filter_options:[],
      },
      {
        filter_type:"Continent",
        filter_key : "location",
        filter_selected_value : "",
        filter_options:[],
      },
      {
        filter_type:"Biome",
        filter_key : "biome",
        filter_selected_value : "",
        filter_options:[],
      },
      {
        filter_type:"Species",
        filter_key : "species",
        filter_selected_value : "",
        filter_options:[]
      }
  ]


  constructor(private _publicApi : PublicService, private loader : NgxSpinnerService,
    private completerService: CompleterService) { 
      this.dataService = this.completerService.local(this.searchData, 'projectName,projectLocation', 'projectName');
      this.getMasterCategory();
      this.getProjectOptionList();
      this.getCountries();
  }

	ngOnInit(): void {
    this._publicApi.getFilterOptions().then(response => {
      console.log(response);
      this.filter_option_data = response;
    })
	}
  
	card_clicked(data){
		this.is_card_clicked = data.is_card_clicked;
		if(this.is_card_clicked){
			let catagory_data = {
				selectedCatagoryName:data.card_name,
				selectedCatagoryType:data.card_type,
			}
			this.catagory_selected_event.emit(catagory_data);
    }
  }


  getMasterCategory(){
    this.loader.show();
    this._publicApi.getMasterCategory().subscribe(response => {
			this.loader.hide();
			this.groups = response.data;
			if(this.groups.type){
				this.groups.type.forEach(type => {
          this.card_data.push({
            card_icon: type.title == "Featured Projects" ? "fa fa-star" : "fa fa-heart",
            card_image: type.image,
            card_sub_title: "View the Top",
            card_title: type.title,
            card_type : "project"
          });
				});
			}
			if(this.groups.location){
				this.groups.location.forEach(location => {
          this.location_card_data.push({
            card_icon: "fa fa-globe",
            card_image: location.image,
            card_sub_title: "View the Top",
            card_title: location.title,
            card_type : "location"
          });
				});
			}
			if(this.groups.intervention){
				this.groups.intervention.forEach(intervention => {
          this.intervention_card_data.push({
            card_icon: "fa fa-tree",
            card_image: intervention.image,
            card_sub_title: "View the Top",
            card_title: intervention.title,
            card_type : "intervention"
          });
				});
			}
			if(this.groups.biome){
				this.groups.biome.forEach(biome => {
          this.Biome_card_data.push({
            card_icon: "fa fa-tree",
            card_image: biome.image,
            card_sub_title: "View the Top",
            card_title: biome.title,
            card_type : "biome"
          });
				});
			}
			if(this.groups.species){
				this.groups.species.forEach(species => {
          this.Species_card_data.push({
            card_icon: "fa fa-paw",
            card_image: species.image,
            card_sub_title: "View the Top",
            card_title: species.title,
            card_type : "species"
          });
				});
			}
		});
	}
  
  public searchStr: string;
  public dataService: CompleterData;
  public searchData=[];

  search(event){
    let catagory_data = {
      selectedCatagoryName: event.originalObject.projectName,
      selectedCatagoryType:"searchValue",
    }
    this.catagory_selected_event.emit(catagory_data);
  }

  filterOnChange(data){
    console.log(data);
    let keys = Object.keys(data);
    let catagory_data = {
      selectedCatagoryName: data[keys[0]],
      selectedCatagoryType:keys[0],
    }
    this.catagory_selected_event.emit(catagory_data)
  }

  getProjectOptionList(){
    this.loader.show();
    let location = false;
    this._publicApi.getProjectOptionList().subscribe((response:any) => {
      this.loader.hide();
      response.projects.forEach(project => {
        this.searchData.push({
            projectID:project.uid,
            projectName:project.name,
            projectLocation:this._publicApi.getProjectLocation(project),
            projectGeojson : project.geojson,
            total_area : project.total_area,
            location : project.location,
            allSpecies : project.allSpecies,
            total_sites: project.total_sites,
        })
      });
      const projectsData = this.searchData;
      this.loadProjects.emit({ projectsData, location });
    })
  }

  getCountries(){
    this.loader.show();
    this._publicApi.getCountries().subscribe((response:any) => {
      this.loader.hide();
      let _c = [];
      response.countries.forEach(country => {
        _c.push({
            card_icon: "fa fa-globe",
            card_image: this._publicApi.getCountryFlagImage(country),
            card_sub_title: "View the Top",
            card_title: country.name,
            card_type : "country"
        })
      });
      this.countries = _c;
    })
  }

}
