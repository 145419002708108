import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule  } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';

import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';
import { EmbedComponent } from './pages/embed/embed.component';
import { ComingSoonComponent } from './pages/coming-soon/coming-soon.component';
import { LoginComponent } from './pages/login/login.component';
import { SignupComponent } from './pages/signup/signup.component';
import { ForgotPasswordComponent } from './pages/forgot-password/forgot-password.component';
//import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { LandOwnerSignupComponent } from './pages/land-owner-signup/land-owner-signup.component';
import { IndividualsignupComponent } from './pages/individualsignup/individualsignup.component';
import { OrganisationSignupComponent } from './pages/organisation-signup/organisation-signup.component';
import { AccountVerificationComponent } from 'src/app/pages/account-verification/account-verification.component';
import { RegisterComponent } from './pages/register/register.component';
import { RegisterCorporateComponent } from './pages/register-corporate/register-corporate.component';
import { AuthGuard } from './api/auth.guard';
import { ResetPasswordComponent } from './pages/reset-password/reset-password.component';
import { JoinMemberComponent } from './pages/join-member/join-member.component';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';

const routes: Routes =[
  {
    path: '',
    redirectTo: 'projects',
    pathMatch: 'full',
  }, {
    path: '',
    component: AdminLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './layouts/admin-layout/admin-layout.module#AdminLayoutModule'
      }
    ]
  }, {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: './layouts/auth-layout/auth-layout.module#AuthLayoutModule'
      }
    ]
  },
  { path: 'register', component: RegisterComponent,  canActivate: [AuthGuard] },
  { path: 'register-corporate', component: RegisterCorporateComponent,  canActivate: [AuthGuard] },
  { path: 'embed/:type/:id',  component: EmbedComponent },
  //{ path: 'coming-soon',  component: ComingSoonComponent, pathMatch : 'full'},
  { path: 'login',  component: LoginComponent },
  { path: 'sign-up-options',  component: SignupComponent },
  { path: 'thank-you',  component: ThankYouComponent },
  { path: 'individual-sign-up',  component: IndividualsignupComponent },
  { path: 'organisation-sign-up',  component: OrganisationSignupComponent },
  { path: 'owner-sign-up',  component: LandOwnerSignupComponent },
  { path: 'forgot-password', component: ForgotPasswordComponent },
  { path: 'verify-user',  component: AccountVerificationComponent },
  { path: 'become-member',  component: JoinMemberComponent },
  { path: 'reset-password',  component: ResetPasswordComponent },
  {
    path: '**',
    redirectTo: 'projects'
  }
];

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule.forRoot(routes,{
      //useHash: false
      onSameUrlNavigation : "reload"
    })
  ],
  exports: [
  ],
})
export class AppRoutingModule { }
