import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PublicService } from 'src/app/api/public.service';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.css']
})
export class BlogDetailComponent implements OnInit {
  post:any;
  constructor(public router : Router, public activatedRoute : ActivatedRoute,
    public publicApi : PublicService) {
    this.activatedRoute.params.subscribe( param => {
      console.log(param);
      this.publicApi.getPost(param.id).subscribe(data => {
        this.post = data.post;
      })
    })
  }

  ngOnInit(): void {
  }

  getPostImage(){
    return this.publicApi.getBlogImage(this.post);
  }
}
