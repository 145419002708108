import { Component, OnInit, NgZone} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { PublicService } from 'src/app/api/public.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Map, Marker, AnySourceData, GeoJSONSourceRaw, GeoJSONSource, Popup } from 'mapbox-gl';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as turf from '@turf/turf';
import { ToastrService } from 'ngx-toastr';
import { SocialShareService } from 'src/app/api/social-share.service';

@Component({
  selector: 'app-admin-landowner-projectview',
  templateUrl: './admin-landowner-projectview.component.html',
  styleUrls: ['./admin-landowner-projectview.component.scss']
})
export class AdminLandownerProjectviewComponent implements OnInit {
	projectId:any;
	project:any;
	userOwnedGrid:any;
	pages_state =  {
		isProjectCatagoryVisible:true,
		isBrowseProjectVisible:false,
		isProjectDetailsVisible:false,
		isLeaderBoardVisible:false,
		isBuyingPanalVisible:false,
	}
	
	browseProjectComponentData = { selectedCatagoryName:'', selectedCatagoryType:''	}

	projectDetailsComponentData = { projectID:'', projectObject:''}
	
	leaderboardComponentData = {}

	buyHabitateComponentData = {
		habitat_name:'Sierra Leone Reforestion',
		habitat_price:'',
		habitat_area:[{
			is_habitat_area_selected:false,
			land_area_tile_count:256,
			land_area_size:2300,
			land_area_name:'Reforestion Area 1',
			land_area_tile_cost:240,
			land_area_code:'101',
			biodiversity_data:[
				{
					species_catagory_name:'Plant Species',
					is_species_catagory_selected:false,
					species_catagory_image_path:'',
					species_data:[
						{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},

					]
				},{
					species_catagory_name:'Tree Species',
					is_species_catagory_selected:false,
					species_catagory_image_path:'',
					species_data:[
						{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},

					]
				},{
					species_catagory_name:'Amphibian Species',
					is_species_catagory_selected:false,
					species_catagory_image_path:'',
					species_data:[
						{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},

					]
				}
			],
			area_land_cover:[
				{
					land_cover_name:'Trees',
					land_cover_image_path:'fa fa-tree',
					is_land_cover_selected:false,
					land_cover_specie_tag:[
						{
							species_count:5,
							species_image_path:'fa fa-tree'

						},{
							species_count:5,
							species_image_path:'fa fa-plant'
						},{
							species_count:5,
							species_image_path:'fa fa-fish'
						},{
							species_count:5,
							species_image_path:'fa fa-lion'
						},
					]
				},{
					land_cover_name:'Bare Ground',
					land_cover_image_path:'fa fa-land',
					is_land_cover_selected:false,
					land_cover_specie_tag:[
						{
							species_count:5,
							species_image_path:'fa fa-tree'

						},{
							species_count:5,
							species_image_path:'fa fa-plant'
						},{
							species_count:5,
							species_image_path:'fa fa-fish'
						},{
							species_count:5,
							species_image_path:'fa fa-lion'
						},
					]
				},{
					land_cover_name:'Water',
					land_cover_image_path:'fa fa-water',
					is_land_cover_selected:false,
					land_cover_specie_tag:[
						{
							species_count:5,
							species_image_path:'fa fa-tree'

						},{
							species_count:5,
							species_image_path:'fa fa-plant'
						},{
							species_count:5,
							species_image_path:'fa fa-fish'
						},{
							species_count:5,
							species_image_path:'fa fa-lion'
						},
					]
				},
			]
		},{
			is_habitat_area_selected:false,
			land_area_tile_count:356,
			land_area_size:3300,
			land_area_name:'Reforestion Area 2',
			land_area_tile_cost:240,
			land_area_code:'102',
			biodiversity_data:[
				{
					species_catagory_name:'Plant Species',
					is_species_catagory_selected:false,
					species_catagory_image_path:'',
					species_data:[
						{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},

					]
				},{
					species_catagory_name:'Tree Species',
					is_species_catagory_selected:false,
					species_catagory_image_path:'',
					species_data:[
						{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},

					]
				},{
					species_catagory_name:'Amphibian Species',
					is_species_catagory_selected:false,
					species_catagory_image_path:'',
					species_data:[
						{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},{
							species_name:'Fern',
							species_image_path:'https://watchandlearn.scholastic.com/content/dam/classroom-magazines/watchandlearn/videos/animals-and-plants/plants/what-are-plants-/What-Are-Plants.jpg',
							is_species_selected:false,
						},

					]
				}
			],
			area_land_cover:[
				{
					land_cover_name:'Trees',
					land_cover_image_path:'fa fa-tree',
					is_land_cover_selected:false,
					land_cover_specie_tag:[
						{
							species_count:5,
							species_image_path:'fa fa-tree'

						},{
							species_count:5,
							species_image_path:'fa fa-plant'
						},{
							species_count:5,
							species_image_path:'fa fa-fish'
						},{
							species_count:5,
							species_image_path:'fa fa-lion'
						},
					]
				},{
					land_cover_name:'Bare Ground',
					land_cover_image_path:'fa fa-land',
					is_land_cover_selected:false,
					land_cover_specie_tag:[
						{
							species_count:5,
							species_image_path:'fa fa-tree'

						},{
							species_count:5,
							species_image_path:'fa fa-plant'
						},{
							species_count:5,
							species_image_path:'fa fa-fish'
						},{
							species_count:5,
							species_image_path:'fa fa-lion'
						},
					]
				},{
					land_cover_name:'Water',
					land_cover_image_path:'fa fa-water',
					is_land_cover_selected:false,
					land_cover_specie_tag:[
						{
							species_count:5,
							species_image_path:'fa fa-tree'

						},{
							species_count:5,
							species_image_path:'fa fa-plant'
						},{
							species_count:5,
							species_image_path:'fa fa-fish'
						},{
							species_count:5,
							species_image_path:'fa fa-lion'
						},
					]
				},
			]
		},
		]
	}

  close_btn_clicked = false;
  pages_state_history = [];
  search_data={ name:'', type:'' }

  /* ------------------------------- css control ------------------------------ */
  initialSeeAll = "showall_container_x";
  initialProjectPanel = "projectx";
  initialState = "leftSection";
  
  /* -------------------------------------------------------------------------- */
  
  user:any;
  map: Map; marker = new Marker();
  popup = new Popup({ closeButton: false, closeOnClick: false });
  sites:any;
  posts:any;
  siteBackground="";
  tileMap: Map;
 
  constructor(private route: ActivatedRoute, private zone : NgZone, private _publicApi : PublicService, private router : Router,
    private loader : NgxSpinnerService,private modalService: NgbModal,private toast : ToastrService,
    private socialShare : SocialShareService) { }

  ngOnInit(): void {
	const shouldCallAllProjectFunction = this.route.snapshot.queryParams.AllProjects;
    if (shouldCallAllProjectFunction) {
	  this.viewAllProjects();
    }
  }

  /* ------------------------- changing project thumbnil images ------------------------ */

  loadMapbox(map){
    this.map = map;
    this.map.resize();
    this.map.setStyle("mapbox://styles/mapbox/satellite-streets-v11")
  }

 /* --------------------------- close project panel -------------------------- */
	toggleProjectPanel(){
		this.close_btn_clicked = !this.close_btn_clicked;
		if (this.close_btn_clicked == true){
			this.pages_state_history.push(this.pages_state)
			this.pages_state = {
				isProjectCatagoryVisible:false,
				isBrowseProjectVisible:false,
				isProjectDetailsVisible:false,
				isLeaderBoardVisible:false,
				isBuyingPanalVisible:false,
			}
		}
		if (this.close_btn_clicked == false){
			this.pages_state = this.pages_state_history[0];
			this.pages_state_history = [];
		}
		this.initialState=(this.initialState=='leftSection'?'leftSectionHidden':'leftSection');
		this.initialProjectPanel=(this.initialProjectPanel=='projectx'?'projectx_close':'projectx');
		this.initialSeeAll=(this.initialSeeAll=='showall_container_x'?'showall_container_y':'showall_container_x');
	}
	
	browseCatagoryEventListener(catagory_data){
		this.browseProjectComponentData = catagory_data;
		this.pages_state.isProjectCatagoryVisible = false;
		this.pages_state.isBrowseProjectVisible = true;
	}

	projectSelectedEventListener(project_data){
		this.projectDetailsComponentData = {projectID : project_data, projectObject:null};
		this.pages_state.isBrowseProjectVisible = false;
		this.pages_state.isProjectDetailsVisible = true;
	}
	
	gotoLeaderboard(){
		this.pages_state.isProjectDetailsVisible = false;
		this.pages_state.isLeaderBoardVisible = true;
	}

	gotoProjects(){
		this.pages_state.isProjectDetailsVisible = false;
		this.pages_state.isBrowseProjectVisible = true;
	}

	leaderboardEventListener(){
		this.pages_state.isLeaderBoardVisible = false;
		this.pages_state.isProjectDetailsVisible = true;
	}
	
	gotoBuyHabitat(){
		this.pages_state.isProjectDetailsVisible = false;
		this.pages_state.isBuyingPanalVisible = true;
	}
	gotoProjectDetail(){
		this.pages_state.isProjectDetailsVisible = true;
		this.pages_state.isBuyingPanalVisible = false;
	}

	shareStatus(shareprogress) {
		this.modalService.open(shareprogress, {windowClass:'shareborder',ariaLabelledBy: 'modal-basic-title', animation : true, centered: true, size: 'lg' as any}).result.then((result) => {
		
		});
	}
	
	copyHeroName(inputElement){
		inputElement.select();
		document.execCommand('copy');
		inputElement.setSelectionRange(0, 0);
	}

	viewAllProjects(){
		this.pages_state.isProjectDetailsVisible = false;
		this.browseCatagoryEventListener({});
	}

	loadGeoJson(project){
		let self = this;
		this.projectDetailsComponentData.projectObject = project;
		//this.addGeojsonToMap(project);
		var refreshIntervalId = setInterval(()=>{
			if(self.map){
				clearInterval(refreshIntervalId);
				self.addGeojsonBoundryToMap(project);
			}
		},1000);
	}

	addGeojsonBoundryToMap(project){
		let self = this;
		if(self.map && project){
			setTimeout(()=>{
				//let geoJson = JSON.parse(project.geojson);
				self.processSiteGeojson(project);

				//===== all sites ============/
				let allFeature = [];
				project.sites.forEach(site => {
					allFeature = allFeature.concat(JSON.parse(site.geo_json).features);
				});
				var fc = turf.featureCollection(allFeature);
				project.geojson = JSON.stringify(fc);
				//===== all sites ============/
				let geoJson = JSON.parse(project.geojson);
				
				if(self.map.getSource("sourceGeoJson")){
					(<GeoJSONSource>self.map.getSource("sourceGeoJson")).setData(geoJson);
				}
				else{
					self.map.addSource("sourceGeoJson", {
						type: "geojson",
						data: geoJson
					});
				}
		
				if (!self.map.getLayer('boundryFillLayer')) {
					self.map.addLayer({
						'id': 'boundryFillLayer',
						'type': 'fill',
						'source': 'sourceGeoJson',
						'layout': {},
						'paint': {
							"fill-outline-color":"#f56036",
							"fill-color": "Transparent",
							"fill-opacity": [
								"case",
								["boolean", ["feature-state", "hover"], false],
								1,
								0.5,
							],
						}
					});
		
					self.map.addLayer({
						'id': 'layer-borders',
						'type': 'line',
						'source': 'sourceGeoJson',
						'layout': {},
						'paint': {
							'line-color': '#f56036',
							'line-width': 2
						}
					});
				}
				self.createUserOwnedGridLayer(project.uid);
			}, 1000);
		}
	}

	processSiteGeojson(project){
		let f:any;
		let geoJson = JSON.parse(project.geojson);
		this.gotoLocation(geoJson);
	}

	gotoLocation(feature){
		let center = turf.centerOfMass(feature);
		let c:any = center.geometry.coordinates;
		this.map.setCenter(c);
		this.map.setZoom(13);
	}

	createUserOwnedGridLayer(projectId){
		let self = this;
		if(self.map.getLayer('userOwnerGridLayer')){
		  return;
		}
		if(self.map.getLayer('userOwnedGridSource')){
		  return;
		}
		this.loader.show();
		let featureCollection = [];
		this._publicApi.getUserOwnedProjectBlocks(projectId).subscribe(response => {
		  this.loader.hide();
		  console.log(response);
		  response.transactions.forEach(transaction => {
			let _tiles = JSON.parse(transaction.orders.products);
			_tiles.features.forEach(feature => {
			  feature.properties = {
				"ownerName":transaction.owner.email.match(/^([^@]*)@/)[1],
				"ownerImage":transaction.owner.email
			  }
			});
			featureCollection = featureCollection.concat(_tiles.features);
			self.loadAndSetTileImage(transaction.owner);
		  });
		  self.userOwnedGrid = turf.featureCollection(featureCollection);
		  self.map.addSource('userOwnedGridSource', {
			type: "geojson",
			data: self.userOwnedGrid
		  });
	
		  self.map.addLayer({
			id: 'userOwnerGridLayer',
			type: "fill",
			source: 'userOwnedGridSource',
			"minzoom": 10,
			"maxzoom": 23,
			paint: {
			  "fill-color":"#f59d24",
			  "fill-opacity":0.5,
			  "fill-outline-color":"transparent"
			}
		  });
	
		  self.map.addLayer({
			id: 'userOwnerGridLayer2',
			type: "symbol",
			source: 'userOwnedGridSource',
			"minzoom": 10,
			"maxzoom": 23,
			layout: {
			  'icon-image': ['get','ownerImage'],
			  'icon-anchor': 'center',
			  "icon-size": ['interpolate', ['linear'],
				  ['zoom'], 18, 0.3, 19, 0.5, 20, 1, 21, 1
			  ],
			  'text-anchor': 'bottom',
			  "icon-allow-overlap": true,
			  "text-allow-overlap": true,
			}
		  });
		});
	  }

	  loadAndSetTileImage(owner){
		let self = this;
		owner.avatar = "../../../assets/img/brand/placeholder-profileimg.png";
		self.map.loadImage(owner.avatar, (error, image) => {
		  if (error) throw error;
		  if (!self.map.hasImage(owner.email)) {
			self.map.addImage(owner.email, image);
		  }
		});
	  }


	updateMapStyle(event) {
	let self = this;
	this.zone.run(() => {
			self.map.setStyle("mapbox://styles/mapbox/" + event.target.value);
		});
	}

	addProjectsToMap(projects) {
		let self = this;
		let inerval = setInterval(function() {
			if(projects && self.map){
				clearInterval(inerval);
				let projectJson = {
					type: "geojson",
					data: {
						type: "FeatureCollection",
						features: []
					}
				};
				projects.forEach(project => {
					let center:any;
					if (project.projectGeojson) {
						let geojson = JSON.parse(project.projectGeojson);
						center = turf.centerOfMass(geojson);
					}else{
						center = turf.point(project.location.split(","));
					}
					
					let _html = self.getPopupHtml(project);
					center.properties = { title: project.projectName, description: _html };
					projectJson.data.features.push(center);
				});
				self.drawProjectsOnMap(projectJson);
			}
		}, 2000);
	}

	getPopupHtml(project) {
		let pSpecies = project.projectSpecies ? project.projectSpecies : project.allSpecies;
		let allSpecies = '<span class="bg-white badge badge-pill badge-primary"><img _ngcontent-cfq-c210="" src="../../../../assets/img/brand/leaf.png"> 2 </span>';
		if(pSpecies && pSpecies.length>0){
			allSpecies = '';
			pSpecies.forEach(species => {
				allSpecies += '<span class="bg-white badge badge-pill badge-primary"><img _ngcontent-cfq-c210="" src="../../../../assets/img/brand/leaf.png"> '+species.total+' </span>';
			});
		}
		return (
			'<div class="p-2 text-center rounded-circle">' +
			'<div class="row">' +
			'<div class="col-12"><h3 class="text-white">'+project.total_area+'ha</h3></div>' +
			'<div class="col-12">' + allSpecies +
			"</div>" +
			"</div>"
		);
	}
	
	drawProjectsOnMap(projectJson) {
		let self = this;
		if(this.map.getSource("projects")){
		  (<GeoJSONSource>this.map.getSource("projects")).setData(projectJson.data);
		}else{
		  this.map.addSource("projects", projectJson);
		  this.map.addLayer({
  			id: "projects",
  			type: "circle",
  			source: "projects",
  			layout: {
  			  visibility: "visible"
  			},
  			paint: {
  			  "circle-color": "#1D2859",
  			  "circle-radius": 10,
  			  "circle-stroke-width": 2,
  			  "circle-stroke-color": "#ffffff"
  			}
		  });

		  this.map.addLayer({
  			id: "points",
  			type: "symbol",
  			source: "projects",
  			layout: {
  			  "text-allow-overlap": false,
  			  "text-field": ["get", "title"],
  			  "text-font": ["Open Sans Semibold", "Arial Unicode MS Bold"],
  			  "text-offset": [0, 1],
  			  "text-anchor": "top"
  			},
  			paint: {
  			  "text-color": "#ffffff"
  			}
		  });

		  const popup = new Popup({
  			closeButton: false,
  			closeOnClick: false
		  });

		  this.map.on("mouseenter", "projects", e => {
			// Change the cursor style as a UI indicator.
			self.map.getCanvas().style.cursor = "pointer";

			// Copy coordinates array.
			let geometry: any = e.features[0].geometry;
			const coordinates = geometry.coordinates.slice();
			const description = e.features[0].properties.description;

			// Ensure that if the map is zoomed out such that multiple
			// copies of the feature are visible, the popup appears
			// over the copy being pointed to.
			while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
			  coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
			}

			// Populate the popup and set its coordinates
			// based on the feature found.
			popup
			  .setLngLat(coordinates)
			  .setHTML(description)
			  .addTo(self.map);
		  });

		  self.map.on("mouseleave", "projects", () => {
  			self.map.getCanvas().style.cursor = "";
  			popup.remove();
		  });
		}

		//zoom in to default level
		self.map.flyTo({
			zoom: 1
		});
		
		// @ts-ignore
		let bounds = new mapboxgl.LngLatBounds();
		projectJson.data.features.forEach(function (feature) {
		  bounds.extend(feature.geometry.coordinates);
		});
	  
		// Adjust the padding value to leave some space around the bounds
		let padding = { top: 50, bottom: 50, left: 50, right: 50 };
	  
		// Fit the map to the bounds with the specified padding
		self.map.fitBounds(bounds, { padding: padding });
	}
}
