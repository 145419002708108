import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PublicService } from 'src/app/api/public.service';
import { ValidationService } from 'src/app/api/validation.service';
import { count } from 'rxjs/operators';

@Component({
  selector: 'app-register-corporate',
  templateUrl: './register-corporate.component.html',
  styleUrls: ['./register-corporate.component.scss']
})
export class RegisterCorporateComponent implements OnInit {

  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  forthFormGroup: FormGroup;
  currentStep = 1;
  document:any;
  document2:any;
  user:any;
  profile:any;
  //userType:any;
  owners = [];
  financialMonth = this.getFinancialMonth();
  countries:any;
  businessTypes:any;
  states:any;
  cities:any;
  addresses:any;

  constructor(private _formBuilder: FormBuilder, private _router : Router,
    private _publicApi : PublicService) {
      this.getBusinessType();
      this.getCountryList();
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    if(!this.user){
      this._router.navigate(['landing']);
    }
    this.profile = JSON.parse(localStorage.getItem('profile'));
    this.gotoStepNew();

    this.secondFormGroup = this._formBuilder.group({
      country : ['', Validators.required],
      businessName: ['', Validators.required],
      businessId : ['', [Validators.required, ValidationService.fileExtension(["png","jpg","jpeg","pdf","doc","docx"])]],
    });
    this.secondFormGroup = new FormGroup(this.secondFormGroup.controls, { updateOn: 'blur' });

    this.thirdFormGroup = this._formBuilder.group({
      businessType: ['', Validators.required],
      aboutBusiness: ['', Validators.required],
      country: [''],
      state: [''],
      city: [''],
      address: [''],
      addresses : ['', Validators.required]
    });
    this.thirdFormGroup = new FormGroup(this.thirdFormGroup.controls, { updateOn: 'blur' });

    this.forthFormGroup = this._formBuilder.group({
      financialMonth: ['', Validators.required],
    });
    this.forthFormGroup = new FormGroup(this.forthFormGroup.controls, { updateOn: 'blur' });
  }

  getBusinessType(){
    this._publicApi.getBusinessType().subscribe(response => {
      this.businessTypes = response.organizationtypes;
    })
  }

  gotoStepNew(){
    if(null == this.profile){
      this.gotoStep(1);
    }
    else{
      if(null != this.profile.businessId){
        if(null != this.profile.businessType){
          if(null != this.profile.financial_year){
            this._router.navigate(['user-dashboard']);
          }
          else{
            this.gotoStep(3);
          }
        }
        else{
          this.gotoStep(2);
        }
      }
      else{
        this.gotoStep(1);
      }
    }
  }

  toBase64(file){
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    })
  }

  getCountryList(){
    this._publicApi.getCountries().subscribe(response => {
      this.countries = response.countries;
    })
  }

  getStateList(event){
    if(event.target.value == ""){
      this.states = [];
      this.cities = [];  
    }
    else{
      this._publicApi.getStates(event.target.value).subscribe(response => {
        this.states = response.states;
      })
    }
  }

  getCityList(event){
    if(event.target.value == ""){
      this.cities = [];
    }
    else{
      this._publicApi.getCities(event.target.value).subscribe(response => {
        this.cities = response.cities;
      })
    }
  }

  getFinancialMonth(){
    return ["January","February","March","April","May","June","July","August","September","October","November","December"];
  }

  async submitSecondStep(){
    let data = new FormData();
    for(let prop in this.secondFormGroup.controls){
      this.secondFormGroup.controls[prop].markAsTouched();
    }
    if(this.secondFormGroup && this.secondFormGroup.valid){
        data.append("country", this.secondFormGroup.value.country);
        data.append("name", this.secondFormGroup.value.businessName);
        data.append("businessId", this.document);
        this.postData(data);
    }
  }

  addMoreAddress(){
    if(!this.addresses){
      this.addresses = [];
    }
    let country = this.countries.find(c => c.id == this.thirdFormGroup.value.country);
    let state = this.states.find(s => s.id == this.thirdFormGroup.value.state);
    let city = this.cities.find(ct => ct.id == this.thirdFormGroup.value.city);
    
    this.addresses.push({
      "country": this.thirdFormGroup.value.country,
      "state": this.thirdFormGroup.value.state,
      "city": this.thirdFormGroup.value.city,
      "address": this.thirdFormGroup.value.address,
      "fullAddress": this.thirdFormGroup.value.address+", "+city.name+", "+state.name+", "+country.name
    });
  }

  removeAddress(i){
    this.addresses.splice(i,1);
  }

  async submitThirdStep(){
    let data = new FormData();
    for(let prop in this.thirdFormGroup.controls){
      this.thirdFormGroup.controls[prop].markAsTouched();
    }
    if(this.addresses && this.addresses.length>0){
      this.thirdFormGroup.controls['addresses'].setErrors(null);
    }
    else if(this.thirdFormGroup.value.country && this.thirdFormGroup.value.state && this.thirdFormGroup.value.city
      && this.thirdFormGroup.value.address){
        this.addMoreAddress();
        this.thirdFormGroup.controls['addresses'].setErrors(null);
    }

    if(this.thirdFormGroup && this.thirdFormGroup.valid){
      data.append("businessType", this.thirdFormGroup.value.businessType);
      data.append("about_company", this.thirdFormGroup.value.aboutBusiness);
      data.append("addresses", this.addresses);
      this.updatePostData(data);
    }
  }

  async submitForthStep(){
    let data = new FormData();
    for(let prop in this.forthFormGroup.controls){
      this.forthFormGroup.controls[prop].markAsTouched();
    }
    if(this.forthFormGroup && this.forthFormGroup.valid){
        data.append("financial_year", this.forthFormGroup.value.financialMonth);
        this.updatePostData(data);
    }
  }

  postData(data){
    this._publicApi.corporateProfileSubmission(data).subscribe(response => {
      console.log(response)
      if(response.customer){
        this.updateProfileData(data, response.customer);
      }
      this.gotoNextStep();
    });
  }

  updatePostData(data){
    data.append('uid', this.profile.uid);
    this._publicApi.corporateProfileUpdate(this.profile.uid, data).subscribe(response => {
      console.log(response)
      if(response.success){
        this.updateProfileData(data,response.customer);
      }
      this.gotoNextStep();
    });
  }

  updateProfileData(data, organization){
    let profile = JSON.parse(localStorage.getItem('profile'));
    if(null != profile){
      profile = {...profile, ...organization};
      this.profile = profile;
      localStorage.setItem('profile', JSON.stringify(profile));
    }
    else{
      this.profile = organization;
      localStorage.setItem('profile', JSON.stringify(organization));
    }
  }

  gotoStep(step){
    this.currentStep = step;
  }

  gotoNextStep(){
    if(this.currentStep == 1){
      this.currentStep = 2;
    }
    else if(this.currentStep == 2){
      this.currentStep = 3;
    }
    else if(this.currentStep == 3){
      this._router.navigate(['organization']);
    }
  }

  gotoPreviousStep(){
    if(this.currentStep == 3){
      this.currentStep = 2;
    }
    else if(this.currentStep == 2){
      this.currentStep = 1;
    }
  }

  onHeaderFileChange(event) {
    this.document = event.target.files[0];
    console.log(this.document);
    if(this.document.type.indexOf("image/") != 0){
      this.document = undefined;
      return false;
    }
    else if(this.document.size > 10000000){ //10 MB
      this.document = undefined;
      return false;
    }
  }

  onFileChange(event, type) {
    if(type == 1){
      this.document = event.target.files[0];
      console.log(this.document);
      if(this.document.type.indexOf("image/") != 0){
        this.document = undefined;
        return false;
      }
      else if(this.document.size > 10000000){ //10 MB
        this.document = undefined;
        return false;
      }
    }
    else{
      this.document2 = event.target.files[0];
      console.log(this.document2);
      if(this.document2.type.indexOf("image/") != 0){
        this.document2 = undefined;
        return false;
      }
      else if(this.document2.size > 10000000){ //10 MB
        this.document2 = undefined;
        return false;
      }
    }
  }

}
