import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { PublicService } from 'src/app/api/public.service';
import { Router } from '@angular/router';

type species_Data = {
	speciesType:string,
	numberOFSpecies:number
}

type project_cardtype = {

	projectID:number,
	projectImage:string,
	projectName:string,
	projectLocation:string,
	projectareaSize:{length:number,width:number},
	projectCountry_image:string,
	projectProgress:number,
	projectSpecies:species_Data[],
	total_conservation_area:string,
	total_trees:string
}


@Component({
  selector: 'app-organization-project-card',
  templateUrl: './organization-project-card.component.html',
  styleUrls: ['./organization-project-card.component.scss']
})
export class OrganizationProjectCardComponent implements OnInit {
	@Input() project_card_data:project_cardtype
  @Output() project_clicked = new EventEmitter;
  @Output() project_biodiversity = new EventEmitter;
  @Output() send_projects_data_to_my_project = new EventEmitter; 

	is_hovering = false;
	projects: any;

  constructor(public _publicApi : PublicService, public router : Router,
    public loader : NgxSpinnerService) { }

  ngOnInit(): void {
    let user = JSON.parse(localStorage.getItem('user'));
    this.loader.show();
      this._publicApi.getInvestmentProject().subscribe(response => {
        this.loader.hide();
        this.projects = response.project;
        console.log(this.projects);
        this.update_biodiversity();
    });
  }

  update_biodiversity(){
    let allSpecies = [];
    this.projects.forEach(project => {
      let categories = Object.keys(project.allSpecies);
      if(categories && categories.length>0){
        categories.forEach((category, index) => {
          allSpecies[index] = {
            species_catagory_name:category,
            //species_catagory_image_path: project.allSpecies[category][0]['icon'],
            species_catagory_image_path: 'fa fa-leaf',
            available_species_data_length:project.allSpecies[category].length,
            available_species_data:[]
          }
  
          project.allSpecies[category].forEach(item => {
            allSpecies[index].available_species_data.push({
              specie_name:item.typeName,
              specie_image_path: this._publicApi.apiImageUrl+item.typeImage
            })
          });
        });
      }
    });
    this.project_biodiversity.emit(allSpecies);
    this.send_projects_data_to_my_project.emit(this.projects);
  }

  browseProject(){
    this.router.navigate(['/individual/browseproject']);
  }

  gotoProjectDetails(project){
	  this.project_clicked.emit(project);
  }
  getTotalAreaInHa(area){
    area = area/10000;
    return Math.round(area * 100) / 100;
  }

  convertArea(areaInHactare){
    if(areaInHactare){
      let areaInSqkm = areaInHactare/100;
      return (Math.round(areaInSqkm * 100) / 100);
    }
    return 0;
  }
  getTotalBought(project){
    if(project.total_tiles_bought){
      return project.total_tiles_bought;
    }
    return 0;
  }

  getBoughtPercentage(project){
    if(project.total_area){
      return Math.round(100*(this.getTotalBought(project)/project.total_area));
    }
    return 0;
  }

  getProjectSpecies(project){
    let allSpecies = [];
    let categories = Object.keys(project.allSpecies);
    console.log(project.allSpecies, categories);
    categories.forEach(category => {
      allSpecies.push({
        "name" : category,
        "icon" : project.allSpecies[category][0].icon,
        "total" : project.allSpecies[category].length
      });
    });
    return allSpecies;
  }

}
