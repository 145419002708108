import { Component, OnInit, Input,Output,EventEmitter} from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { PublicService } from 'src/app/api/public.service';

@Component({
  selector: 'app-projectcardtemplate',
  templateUrl: './projectcardtemplate.component.html',
  styleUrls: ['./projectcardtemplate.component.scss']
})
export class ProjectcardtemplateComponent implements OnInit {
  @Input() data:any;
  @Output() project_clicked = new EventEmitter();
  is_hovering=false;
  is_species_hover=false;
  species_list = {
    Tree:{icon:'fa fa-tree',hover_visibility:false},
    Amphibians:{icon:'fa fa-frog',hover_visibility:false},
    Plants:{icon:'fa fa-leaf',hover_visibility:false},
    Birds:{icon:'fa fa-crow',hover_visibility:false},
    Fish:{icon:'fa fa-fish',hover_visibility:false}
  }
  constructor(public _publicApi : PublicService, public _publicService : PublicService,
    public loader : NgxSpinnerService) { 

  }

  ngOnInit(): void {
    // console.log(this.data.projectSpecies)
  }
  gotoProjectDetails(projectID){
    this.project_clicked.emit(projectID);
  }

  getProjectImage(project){
    if(project){
      return this._publicService.apiImageUrl+project
    }
    return "https://media.istockphoto.com/photos/man-sowing-the-land-at-a-farm-picture-id668076810?k=20&m=668076810&s=612x612&w=0&h=5q9Iqqa0nYhn-MJL80jETxeikjMLBR6PBQhEDUGD5WI=";
  }
}
