import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-thank-you',
  templateUrl: './thank-you.component.html',
  styleUrls: ['./thank-you.component.css']
})
export class ThankYouComponent implements OnInit {
  name:any;
  constructor(public router : Router) { 
    let _state = this.router.getCurrentNavigation().extras.state;
    if(_state && _state.name){
      this.name = _state.name;
    }
  }

  ngOnInit(): void {
  }

}
