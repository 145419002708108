import { Component, OnInit, QueryList, ViewChildren } from '@angular/core';
import { NgxPubSubService } from '@pscoped/ngx-pub-sub';
import { ActivatedRoute, Router } from '@angular/router';
import { PublicService } from 'src/app/api/public.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgbCarousel, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { SocialShareService } from 'src/app/api/social-share.service';
import { Map, Marker, AnySourceData, GeoJSONSourceRaw, GeoJSONSource, Popup } from 'mapbox-gl';

@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.scss'],
  host: {'class': 'd-flex flex-column h-100'}
})
export class AdminLayoutComponent implements OnInit {
  showdropdown: boolean = false;
  notification: boolean = false;
  organizationrole: boolean =false;
  naviclicked = false;
  memberOrganizations:any;
  organizationId:any;

  user:any;
  button_state = {
  isToggleButtonVisible: true,
  }
  profile:any;
  userRoles:any;
  /* user = {
    firstname : "",
    username : "Johans Farms",
    profileimage : "../../../assets/img/brand/placeholder-profileimg.png",
    role: "landowner",
    role_id:1,
    can_create_new_project:false,
    profile_dropdown_options:[
      { is_member_option:true, member_option_link:'/organization/members' },
      { is_transaction_option:true, transaction_option_link:'/organization/transactions' }
    ]
  } */
  nav_status = [
    { nav_code:1, nav_name:'Browse Project', is_nav_active:false, nav_link:'/organization/browseproject'},
    { nav_code:2, nav_name:'My Projects', is_nav_active:true, nav_link:'/organization' },
    // { nav_code:3, nav_name:'About us', is_nav_active:false, nav_link:'/about' }
    { nav_code:3, nav_name:'About us', is_nav_active:false, nav_link:'https://www.green.earth/dgb-group' }
    
  ]
  visibility_state_history = [];
  initialSeeAll = "showall_container_x";
  initialProjectPanel = "projectx";
  initialState = "leftSection"; 
 
  projectId:any;
  project:any;
  togglebutton: false;
  loginState = {
    isMainPageVisible: true,
    isToggleButtonVisible: true,
    isStartProjectVisible: false,
  }
 
  map: Map; marker = new Marker();
  popup = new Popup({ closeButton: false, closeOnClick: false });
  sites:any;
  posts:any;
  siteBackground="";
  tileMap: Map;
  siteMap: Map;
  close_btn_clicked: boolean;
  can_i_show_footer=false;

  constructor(private router: Router, private pubsub : NgxPubSubService,
    private _publicApi : PublicService ,private route: ActivatedRoute, 
    private loader : NgxSpinnerService,private modalService: NgbModal,private toast : ToastrService,
    private socialShare : SocialShareService) { 
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    this.profile = JSON.parse(localStorage.getItem('profile'));
    if(localStorage.getItem('organizations')){
      this.memberOrganizations = JSON.parse(localStorage.getItem('organizations'));
      this.organizationId = localStorage.getItem('organizationId');
    }
    this.setMenu();
    this.user.image = this._publicApi.getUserImage(this.user.image);
    this.can_i_show_footer = this.router.url.startsWith('/about');

    this.pubsub.subscribe('logout', data => {
      this.signout();
    })
    this.getNotifications();
  }

  activateURL(url){
    this.nav_status.forEach(navLink => {
      if(navLink.nav_link == url){
        navLink.is_nav_active = true;
      }else{
        navLink.is_nav_active = false;
      }
    });
  }

  startProject() {
    if (this.profile == null) {
      //navigate to the registration page if landowner has not completed profile setup
      this.router.navigate(["/register"]);
    } else {
      // navigate to create project page
      this.router.navigate(["/"]).then((response) => {
        this.can_i_show_footer = false;
        this.router.navigate(["/create-project"], {
          state: { "new-project": "1" },
        });
      });
    }
  }

  setMenu(){
    if(!this.user){
      this.signout();
    }
    if(this.user.role == PublicService.userRoles.INDIVIDUAL){
      if(this.user.user_type == "corporate"){
        this.nav_status = [
          { nav_code:1, nav_name:'Browse Project', is_nav_active:false, nav_link:'/organization/browseproject'},
          { nav_code:2, nav_name:'Our Projects', is_nav_active:true, nav_link:'/organization' },
          // { nav_code:3, nav_name:'About us', is_nav_active:false, nav_link:'/about' }
          { nav_code:3, nav_name:'About us', is_nav_active:false, nav_link:'https://www.green.earth/dgb-group'}
        ];
        this.user['can_create_new_project'] = false;
        this.user['profile_dropdown_options'] = [
          { is_member_option:true, member_option_link:'/organization/members' },
          { is_transaction_option:true, transaction_option_link:'/organization/transactions' }
        ];
      }else{
        this.nav_status = [
          { nav_code:1, nav_name:'Browse Project', is_nav_active:false, nav_link:'/individual/browseproject'},
          { nav_code:2, nav_name:'My Projects', is_nav_active:true, nav_link:'/individual' },
          // { nav_code:3, nav_name:'About us', is_nav_active:false, nav_link:'/about' }
          { nav_code:3, nav_name:'About us', is_nav_active:false, nav_link:'https://www.green.earth/dgb-group'}

        ];
        this.user['can_create_new_project'] = false;
        this.user['profile_dropdown_options'] = [
          { is_member_option:false, member_option_link:'' },
          { is_transaction_option:true, transaction_option_link:'/individual/transactions' }
        ];
      }
    }else if(this.user.role == PublicService.userRoles.PROJECT_DEVELOPER){
      this.nav_status = [
        { nav_code:1, nav_name:'Browse Project', is_nav_active:false, nav_link:'/landowner/browseproject'},
        { nav_code:2, nav_name:'My Projects', is_nav_active:true, nav_link:'/landowner' },
        // { nav_code:3, nav_name:'About us', is_nav_active:false, nav_link:'/about' }
        { nav_code:3, nav_name:'About us', is_nav_active:false, nav_link:'https://www.green.earth/dgb-group' }
      ];
      this.user['can_create_new_project'] = true;
      this.user['profile_dropdown_options'] = [
        { is_member_option:false, member_option_link:'' },
        { is_transaction_option:false, transaction_option_link:'' }
      ];
    }
    this.activateURL(this.router.url);
  }

  signout(){
    localStorage.clear();
    this.router.navigate(['/projects']);
  }

  /* getUserTypes(){
    this._publicApi.getUserTypes().subscribe((response:any) => {
      this.userRoles = response.roles;
      this.gotoDashboard();
    });
  }

  gotoDashboard(){
    let roles = this.userRoles.filter(role => {
      return role.id == this.user.role_id;
    });
    if(roles && roles.length > 0){
      let role = roles[0];
      if(role.name == PublicService.userRoles.INDIVIDUAL){
        this.router.navigate(['user-dashboard']);
      }
      else if(role.name == PublicService.userRoles.PROJECT_DEVELOPER){
        this.checkProfileData();
      }
      else if(role.name == PublicService.userRoles.PROJECT_MANAGER){
        this.router.navigate(['invitations']);
      }
    }
    else{
      this.router.navigate(['/login'])
    }
  } */

  /* checkProfileData(){
    if(null == this.profile){
      this.router.navigate(['register']);
    }
    else{
      if(null != this.profile.userGovtId){
        if(null != this.profile.name){
          if(null != this.profile.businessType){
            if(null != this.profile.shareholderStructure){
              //this.router.navigate(['create-project']);
            }
            else{
              this.router.navigate(['register']);
            }
          }
          else{
            this.router.navigate(['register']);
          }
        }
        else{
          this.router.navigate(['register']);
        }
      }
      else{
        this.router.navigate(['register']);
      }
    }
  } */

  /* ------------------------- changing project thumbnil images ------------------------ */
 
  /* updateMasonryLayout(){
    let self = this;
    setTimeout(()=>{
      //self.masonry.layout();
    },400)
  } */

  /* getProjectSites(){
    this._publicApi.getProjectSites(this.project.uid, 0).subscribe(response => {
      this.loader.hide();
      if(response.success){
        this.sites = response.sites;
        this.addMarkerToMap(this.siteMap);
        this.addGeojsonToMap();
      }
    });
  } */

  /* openGrid(){
    this.router.navigate(['/grid/'+this.project.uid]);
  } */

  /* getProjectPosts(){
    this._publicApi.getProjectPosts(this.project.uid, 0).subscribe(response => {
      this.loader.hide();
      if(response.success){
        this.posts = response.posts;
      }
    });
  } */

  /* loadMapbox(map){
    this.map = map;
    this.map.resize();
    //this.map.setZoom(5);
    this.map.setStyle("mapbox://styles/mapbox/satellite-streets-v11")
    //this.map.addControl(new NavigationControl());
    // console.log(this.project.location.split(","));
    // this.marker.setLngLat(this.project.location.split(",")).addTo(this.map);
    //this.flyToLocation(this.project.location.split(","));
    // this.addMarkerToMap(this.map);
    // this.addGeojsonToMap();
  } */

  /* flyToLocation(location){
    this.map.flyTo({
        center: location,
        zoom: 15,
        bearing: 0,
        speed: 1,
        curve: 1,
        easing: function (t) {
          return t;
        },
        essential: true
    });
  } */

  /* addMarkerToMap(map:Map){

    let self = this;
    if(map && self.sites && self.sites.length>0){
      map.setCenter(this.sites[0].location.split(","));
      let featureCollection = {
        type: 'FeatureCollection',
        features: []
      };
      this.sites.forEach(site => {
        featureCollection.features.push({
          'type': 'Feature',
          'geometry': {
            'type': 'Point',
            'coordinates': site.location.split(",")
          },
          'properties': {
            'title': site.name
          }
        });
      });

      map.loadImage('../../assets/img/icons/common/placeholder.png', (err, image) => {
        map.addImage('custom-marker', image);
        map.addSource('points', <AnySourceData>{
          type: 'geojson',
          data : featureCollection
        })

        map.addLayer({
          'id': 'points',
          'type': 'symbol',
          'source': 'points',
          'layout': {
              'icon-image': 'custom-marker',
              "icon-allow-overlap": true,
              'text-allow-overlap': true,
              // get the title name from the source's "title" property
              'text-field': ['get', 'title'],
              'text-font': [
                'Open Sans Semibold',
                'Arial Unicode MS Bold'
              ],
              'text-offset': [0, 1.25],
              'text-anchor': 'top'
            },'paint': {
                'text-color': '#ffffff'
            }
          });
      });


      map.on('mouseenter', 'points', function (e) {
        map.getCanvas().style.cursor = 'pointer';
        let geometry:any = e.features[0].geometry;
        var coordinates = geometry.coordinates.slice();
        var description = e.features[0].properties.title;

        // Ensure that if the map is zoomed out such that multiple
        // copies of the feature are visible, the popup appears
        // over the copy being pointed to.
        while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
        }

        // Populate the popup and set its coordinates
        // based on the feature found.
        self.popup.setLngLat(coordinates).setHTML(description).addTo(map);
      });

      map.on('mouseleave', 'points', function () {
        map.getCanvas().style.cursor = '';
        self.popup.remove();
      });

    }
  } */

  /* addGeojsonToMap(){
    let self = this;
    if(self.map && self.sites && self.sites.length>0){
      setTimeout(()=>{
        for(let i=0;i<self.sites.length;i++){
          if (!self.map.getLayer('site-borders'+i)) {
            self.map.addSource('geojson'+i, <GeoJSONSourceRaw>{
              type: 'geojson',
              data : JSON.parse(self.sites[i].geo_json)
            })

            self.map.addLayer({
                'id': 'site-borders'+i,
                'type': 'line',
                'source': 'geojson'+i,
                'layout': {},
                'paint': {
                    'line-color': '#fff',
                    'line-width': 5,
                }
            });
          }else{
            (<GeoJSONSource>self.map.getSource('geojson'+i)).setData(JSON.parse(self.sites[i].geo_json));
          }
        }
      }, 1000);
    }
  } */

  /* flyToGeoLocation(geoJson){
    let self = this;
    geoJson = JSON.parse(geoJson);
    this.tileMap.flyTo({
      center: self.getSiteLocation(geoJson)  ,
      zoom: 15
    });
  } */

  /* getSiteLocation(geoJson){
    let location:[number,number];
    for(let feature of geoJson.features){
      if(feature.geometry && feature.geometry.type=="Polygon"){
        location = feature.geometry.coordinates[0][0].slice(0, 2);
        break;
      }
      else if(feature.geometry && feature.geometry.type=="MultiPolygon"){
        location = feature.geometry.coordinates[0][0][0].slice(0, 2);
        break;
      }
    }
    return location;
  } */

  notificationbutton(){
    if(this.notification== false){
      this.showdropdown= false;
      this.notification= true;
    }
    else{
      this.showdropdown= false;
      this.notification= false;
    }
  }

  getFormattedTime(time){
    return this._publicApi.timeAgo(time);
  }

  showdropdownbutton(){
    if(this.showdropdown== false){
      this.showdropdown= true;
      this.notification= false;
      }
      else{
        this.showdropdown= false;
        this.notification= false;
        }
  }

  getNotifications(){
    this._publicApi.getUserNotification().subscribe(response => {
      this.user.notifications = response.data;
    })
  }

  goToNotificationDetails(n){
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=> {
      switch(n.data.type){
        case "successful_registration":
            this.router.navigate(['/edit-profile']);
            break;
        case "project_creation":
            this.router.navigate(['/landowner']);
            break;
        case "project_publish":
            this.router.navigate(['/landowner']);
            break;
        case "tile_buying_owner":
            this.router.navigate(['/landowner']);
            break;
        case "tile_buying_user":
            if(this.user.user_type){
              this.router.navigate(['/organization/browseproject'], {queryParams : { t : (new Date()).getTime() },  replaceUrl : true, state : { "projectId" : n.data.project_id } });
            }else{
              this.router.navigate(['/individual/browseproject'], {queryParams : { t : (new Date()).getTime() },  replaceUrl : true, state : { "projectId" : n.data.project_id } });
            }
            break;
        case "post_creation":
            this.router.navigate(['/landowner/browseproject']);
            break;
        case "post_publish":
            this.router.navigate(['/landowner/browseproject']);
            break;
      }
    });
  }

  exitOrganization(){
    let existingUser = JSON.parse(localStorage.getItem('existingUser'));
    
    localStorage.setItem('user',existingUser.user);
    localStorage.setItem('statistics',existingUser.statistics);
    localStorage.setItem('profile',existingUser.profile);
    localStorage.removeItem('existingUser');
    localStorage.removeItem('organizationId');
    localStorage.removeItem('permission');

    this.toast.success("You have been switched back to your organization profile.", 'Great!', { closeButton : true, timeOut: 3000 });
    //this.router.navigate(['individual']);
    window.location.href = "/individual"
  }
}
