import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PublicService } from 'src/app/api/public.service';
import { ValidationService } from 'src/app/api/validation.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  firstFormGroup: FormGroup;
  secondFormGroup: FormGroup;
  thirdFormGroup: FormGroup;
  forthFormGroup: FormGroup;
  currentStep = 1;
  document:any;
  document2:any;
  user:any;
  profile:any;
  //userType:any;
  fullName:any;
  owners = [];
  directorStructureDocumetList = this.getDirectorStructureDocumetList();
  shareholderStructureDocumetList = this.getShareholderStructureDocumetList();
  countries = this.getCountryList();
  businessTypes = this.getBusinessTypes();

  constructor(private _formBuilder: FormBuilder, private _router : Router,
    private _publicApi : PublicService, private loader : NgxSpinnerService) {
      
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem('user'));
    if(!this.user){
      this._router.navigate(['landing']);
    }
    this.profile = JSON.parse(localStorage.getItem('profile'));
    this.gotoStepNew();
    //this.userType = localStorage.getItem('userType');
    this.fullName = this.user.firstname+" "+this.user.lastname;
    this.owners.push(this.user.firstname+" "+this.user.lastname);
    let mobile = this.user.cellphone ? this.user.cellphone : '';
    this.firstFormGroup = this._formBuilder.group({
      mobile: [mobile, [Validators.required, ValidationService.mobileValidator]],
      userIdCard : ['', [Validators.required, ValidationService.fileExtension(["png","jpg","jpeg","doc","docx"])]]
    });
    this.firstFormGroup = new FormGroup(this.firstFormGroup.controls, { updateOn: 'blur' });

    /* if(this.userType == "owner"){
    } */
        this.secondFormGroup = this._formBuilder.group({
          country: ['', Validators.required],
          businessName: ['', Validators.required],
          businessId : ['', [Validators.required, ValidationService.fileExtension(["png","jpg","jpeg","pdf","doc","docx"])]],
        });
        this.secondFormGroup = new FormGroup(this.secondFormGroup.controls, { updateOn: 'blur' });
  
        this.thirdFormGroup = this._formBuilder.group({
          businessType: ['', Validators.required],
          aboutBusiness: ['', Validators.required],
          ownerName: ['']
        });
        this.thirdFormGroup = new FormGroup(this.thirdFormGroup.controls, { updateOn: 'blur' });
  
        this.forthFormGroup = this._formBuilder.group({
          directorStructure: ['', Validators.required],
          directorStructureProof: ['', Validators.required],
          shareholderStructure: ['', Validators.required],
          shareholderStructureProof: ['', Validators.required],
        });
        this.forthFormGroup = new FormGroup(this.forthFormGroup.controls, { updateOn: 'blur' });
  }

  gotoStepNew(){
    if(null == this.profile){
      this.gotoStep(1);
    }
    else{
      if(null != this.profile.userGovtId){
        if(null != this.profile.businessId){
          if(null != this.profile.businessType){
            if(null != this.profile.shareholderStructure){
              this._router.navigate(['landowner']);
            }
            else{
              this.gotoStep(4);
            }
          }
          else{
            this.gotoStep(3);
          }
        }
        else{
          this.gotoStep(2);
        }
      }
      else{
        this.gotoStep(1);
      }
    }
  }

  toBase64(file){
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    })
  }

  addOwner(){
    if(null != this.thirdFormGroup.value.ownerName && this.thirdFormGroup.value.ownerName != ""){
      this.owners.push(this.thirdFormGroup.value.ownerName);
      this.thirdFormGroup.controls['ownerName'].setValue("");
    }
  }

  remove(o){
    this.owners.splice(this.owners.indexOf(o),1);
  }

  getCountryList(){
    return this._publicApi.getCountryList();
  }

  getBusinessTypes(){
    this._publicApi.getBusinessTypes().subscribe(response => {
      console.log(response);
      this.businessTypes = response.organizationtypes;
      console.log(this.businessTypes);
    });
  }

  getDirectorStructureDocumetList(){
    return [{"label":"Recommended","values":["Registry Extract","Incorporation Document"]},
    {"label":"Other","values":["Articles of Association","Memorandum of Association","Confirmation Statement(Annual Return)","Confirmation of Change of Director","Audited Annual Accounts"]}]
  }

  getShareholderStructureDocumetList(){
    return [{"label":"Recommended","values":["Registry Extract","Incorporation Document"]},
    {"label":"Other","values":["Articles of Association","Memorandum of Association","Confirmation Statement(Annual Return)","Audited Annual Accounts"]}]
  }

  async submitFirstStep(){
    for(let prop in this.firstFormGroup.controls){
      this.firstFormGroup.controls[prop].markAsTouched();
    }
    if(this.firstFormGroup.valid){
      let data = new FormData();
      data.append('mobile', this.firstFormGroup.value.mobile);
      data.append("userGovtId", this.document);
      this.postData(data);
    }
  }

  async submitSecondStep(){
    let data = {};
    for(let prop in this.secondFormGroup.controls){
      this.secondFormGroup.controls[prop].markAsTouched();
    }
    if(this.secondFormGroup && this.secondFormGroup.valid){
        let data = new FormData();
        data.append("country", this.secondFormGroup.value.country);
        data.append("name", this.secondFormGroup.value.businessName);
        data.append("businessId", this.document);
        this.updatePostData(data);
    }
  }

  async submitThirdStep(){
    for(let prop in this.thirdFormGroup.controls){
      this.thirdFormGroup.controls[prop].markAsTouched();
    }
    if(this.thirdFormGroup && this.thirdFormGroup.valid){
      let data = new FormData();
      data.append("businessType",this.thirdFormGroup.value.businessType);
      data.append("about",this.thirdFormGroup.value.aboutBusiness);
      data.append("businessOwners", this.owners.join(","));
      this.updatePostData(data);
    }
  }

  async submitForthStep(){
    for(let prop in this.forthFormGroup.controls){
      this.forthFormGroup.controls[prop].markAsTouched();
    }
    if(this.forthFormGroup && this.forthFormGroup.valid){
        let data = new FormData();
        data.append("directorStructure", this.forthFormGroup.value.directorStructure);
        data.append("directorStructureProof", this.document);
        data.append("shareholderStructure", this.forthFormGroup.value.shareholderStructure);
        data.append("shareholderStructureProof", this.document2)
        this.updatePostData(data);
    }
  }

  postData(data){
    this.loader.show();
    //if(this.userType == "owner"){
      this._publicApi.profileSubmission(data).subscribe(response => {
        console.log(response);
        this.loader.hide();
        if(response.organization){
          this.updateProfileData(data, response.organization);
        }
        this.gotoNextStep();
      });
    /* }
    else{
      data["cellphone"] = data['mobile'];
      this._publicApi.memberProfileSubmission(this.user.id, data).subscribe(response => {
        console.log(response)
        this._router.navigate(['home']);
      });
    } */
  }

  updatePostData(data){
    this.loader.show();
    this._publicApi.profileUpdate(this.profile.uid, data).subscribe(response => {
      this.loader.hide();
      console.log(response)
      if(response.success){
        this.updateProfileData(data,null);
      }
      this.gotoNextStep();
    });
  }

  updateProfileData(data, organization){
    let profile = JSON.parse(localStorage.getItem('profile'));
    if(null != profile){
      profile = {...profile, ...data};
      this.profile = profile;
      localStorage.setItem('profile', JSON.stringify(profile));
    }
    else{
      this.profile = organization;
      localStorage.setItem('profile', JSON.stringify(organization));
    }
  }

  gotoStep(step){
    this.currentStep = step;
  }

  gotoNextStep(){
    if(this.currentStep == 1){
      this.currentStep = 2;
    }
    else if(this.currentStep == 2){
      this.currentStep = 3;
    }
    else if(this.currentStep == 3){
      this.currentStep = 4;
    }
    else if(this.currentStep == 4){
      this._router.navigate(['landowner']);
    }
  }

  gotoPreviousStep(){
    if(this.currentStep == 4){
      this.currentStep = 3;
    }
    else if(this.currentStep == 3){
      this.currentStep = 2;
    }
    else if(this.currentStep == 2){
      this.currentStep = 1;
    }
  }

  getUserValue(){
    return {"id":7,"name":null,"role_id":2,"firstname":"deepak","lastname":"soni","email":"milode7271@sc2hub.com","avatar":"users/default.png","cellphone":null,"image":null,"gender":null,"born_at":null,"email_verified_at":null,"google_id":null,"fb_token":null,"ibm_id":"5ec2ee6a-b064-4c7f-a16b-3d8845f6de7a","verified":0,"settings":null,"logged_in_at":null,"created_at":"2021-05-29T14:10:32.000000Z","updated_at":"2021-06-08T08:13:54.000000Z","password_updated_at":null,"deleted_by":null,"deleted_at":null};
  }

  getProfileValue(){
    return {"uid":"c7d16bce-c08d-11eb-a291-066226ece468","user_id":7,"name":"Forestation World","about":"lorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsumlorem ipsum","acronym":"Treeforest","header_image":"","shareholderStructure":"Articles of Association","shareholderStructureProo":null,"organization_type_id":null,"city":null,"state":null,"country":"12/20\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000\u0000","address":null,"status":null,"created_at":"2021-05-29T14:54:34.000000Z","updated_at":"2021-07-01T04:59:31.000000Z","deleted_at":null,"deleted_by":null};
  }

  getUserType(){
    return "owner";
  }

  onHeaderFileChange(event) {
    this.document = event.target.files[0];
    console.log(this.document);
    if(this.document.type.indexOf("image/") != 0){
      this.document = undefined;
      return false;
    }
    else if(this.document.size > 10000000){ //10 MB
      this.document = undefined;
      return false;
    }
  }

  onFileChange(event, type) {
    if(type == 1){
      this.document = event.target.files[0];
      console.log(this.document);
      if(this.document.type.indexOf("image/") != 0){
        this.document = undefined;
        return false;
      }
      else if(this.document.size > 10000000){ //10 MB
        this.document = undefined;
        return false;
      }
    }
    else{
      this.document2 = event.target.files[0];
      console.log(this.document2);
      if(this.document2.type.indexOf("image/") != 0){
        this.document2 = undefined;
        return false;
      }
      else if(this.document2.size > 10000000){ //10 MB
        this.document2 = undefined;
        return false;
      }
    }
  }

  skip(){
    this._router.navigate(['landowner']);
  }

}
