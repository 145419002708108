import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-filter-options',
  templateUrl: './filter-options.component.html',
  styleUrls: ['./filter-options.component.scss']
})
export class FilterOptionsComponent implements OnInit {
  @Input() data;
  @Output() onChange = new EventEmitter();
  filterSelectedValue:any;

  constructor() { }

  ngOnInit(): void {
    this.filterSelectedValue = this.data.filter_options[0];
  }

  setFilterData(selectedOption){
    this.filterSelectedValue = selectedOption;
    let filterData = {};
    filterData[this.data.filter_key] = selectedOption;
    this.onChange.emit(filterData);
  }

}
