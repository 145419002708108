import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { PublicService } from 'src/app/api/public.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ValidationService } from 'src/app/api/validation.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.css']
})
export class ForgotPasswordComponent implements OnInit {
  forgotPasswordForm : FormGroup;

  constructor(private _formBuilder: FormBuilder, private _publicApi : PublicService, private toast : ToastrService,
    private loader : NgxSpinnerService, private router : Router) {}


  ngOnInit(): void {
    this.initializeForm();
  }

  initializeForm() {
    this.forgotPasswordForm = this._formBuilder.group({
      email: ['', [Validators.required, ValidationService.emailValidator]]      
    });
    this.forgotPasswordForm = new FormGroup(this.forgotPasswordForm.controls, { updateOn: 'blur' });
  }

  async submitForm(){
    this.validate();
    if(this.forgotPasswordForm.valid){
      let data = {
        "email" : this.forgotPasswordForm.value.email,
        "app_name" : 'habitatmarket'
      };
      this._publicApi.forgotPassword(data).then((response:any) => {
        this.toast.success("A reset password email has been sent to you. Please reset with provided link", 'Great!', { closeButton : true, timeOut: 5000 }); 
        this.router.navigate(['/login'])
      }).catch( err => {
        this.toast.error('Invalid Email.', 'Error!', { closeButton : true, timeOut: 5000 });  
      });
    }
    else{
      this.toast.error('Invalid Email.', 'Error!', { closeButton : true, timeOut: 5000 });
    }
  }

  validate(){
    for(let prop in this.forgotPasswordForm.controls){
      this.forgotPasswordForm.controls[prop].markAsTouched();
    }
  }

}
