import { Component, OnInit,Input,Output, EventEmitter } from '@angular/core';
import { LoginComponent } from 'src/app/pages/login/login.component';

@Component({
  selector: 'app-projectcard',
  templateUrl: './projectcard.component.html',
  styleUrls: ['./projectcard.component.scss']
})
export class ProjectcardComponent implements OnInit {
  @Input() data;
  action_data={
    is_card_clicked:false,
    card_name:'',
    card_type:'',
  };
  @Output() action_call = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
  }
  change_data(name,type,is_clicked){
    let change_visibility = this.action_data;
    change_visibility.is_card_clicked = is_clicked;
    change_visibility.card_name = name;
    change_visibility.card_type = type;
    this.action_call.emit(change_visibility) 
  }
}

