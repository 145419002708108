import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { AppComponent } from './app.component';
import { AdminLayoutComponent } from './layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './layouts/auth-layout/auth-layout.component';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { AppRoutingModule } from './app.routing';
import { ComponentsModule } from './components/components.module';
import { NgxPubSubModule } from '@pscoped/ngx-pub-sub';
import { ToastNoAnimationModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";
import { EmbedComponent } from './pages/embed/embed.component';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { CommonModule, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { AboutUSComponent } from './pages/about-us/about-us.component';
import { TechnologiesComponent } from './pages/technologies/technologies.component';
import { BlogsComponent } from './pages/blogs/blogs.component';
import { FacebookLoginProvider, GoogleLoginProvider, SocialAuthServiceConfig, SocialLoginModule } from 'angularx-social-login';
import { LandOwnerSignupComponent } from './pages/land-owner-signup/land-owner-signup.component';
import { IndividualsignupComponent } from './pages/individualsignup/individualsignup.component';
import { OrganisationSignupComponent } from './pages/organisation-signup/organisation-signup.component';
import { OrganizationpageComponent } from './pages/organizationpage/organizationpage.component';
import { Ng2CompleterModule } from "ng2-completer";
import { BlogDetailComponent } from './pages/blog-detail/blog-detail.component';
import { ThankYouComponent } from './pages/thank-you/thank-you.component';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    FormsModule,ReactiveFormsModule,
    HttpClientModule,SocialLoginModule,
    ComponentsModule,CommonModule,
    NgbModule,
    RouterModule,NgxPubSubModule,
    AppRoutingModule,NgxSpinnerModule,
    ToastNoAnimationModule.forRoot(),
    NgxMapboxGLModule.withConfig({
      accessToken: 'pk.eyJ1IjoiZGV2c3RhdGl4IiwiYSI6ImNrb3kwajV2ejBkaDIyb21zdm1lZzZvc2gifQ.mX7_k8dkysYZVLiU5GmYwQ', // Optional, can also be set per map (accessToken input of mgl-map)
      //geocoderAccessToken: 'TOKEN' // Optional, specify if different from the map access token, can also be set per mgl-geocoder (accessToken input of mgl-geocoder)
    }),
    Ng2CompleterModule,
  ],
  declarations: [
    AppComponent,EmbedComponent,
    AdminLayoutComponent,
    AuthLayoutComponent,
    AboutUSComponent,
    TechnologiesComponent,
    BlogsComponent,
    LandOwnerSignupComponent,
    IndividualsignupComponent,
    OrganisationSignupComponent,
    OrganizationpageComponent,
    BlogDetailComponent,
    ThankYouComponent,
  ],
  providers: [ { provide: 'Window',  useValue: window },
  {provide: LocationStrategy, useClass: PathLocationStrategy},
  {
    provide: 'SocialAuthServiceConfig',
    useValue: {
      autoLogin: true,
      providers: [
        {
          id: GoogleLoginProvider.PROVIDER_ID,
          provider: new GoogleLoginProvider(
            '504194849441-br8592h93de23cut8muo4id7k4ri4kjh.apps.googleusercontent.com'
          )
        },
        {
          id: FacebookLoginProvider.PROVIDER_ID,
          provider: new FacebookLoginProvider('492756525119477')
        }
      ]
    } as SocialAuthServiceConfig,
  } ],
  bootstrap: [AppComponent]
})
export class AppModule { }
